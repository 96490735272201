import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { msg } from '../../../constants/messages';

const DescriptionSection = ({ classes, item }) => {
  return (
    <>
      <ListSubheader color="primary" className={classes.listSubheader}>
        <Typography variant="subtitle2">{msg.sideCard.description}</Typography>
      </ListSubheader>
      <ListItem>
        <ListItemText primary={item.description} />
      </ListItem>
    </>
  );
};

export default DescriptionSection;
