import html2pdf from 'html2pdf.js';
import { useDispatch } from 'react-redux';
import useRoute from '../../../hooks/useRoute';
import { setSettings } from '../../../store/settingsSlice';
import downloadNotifications from '../../../utils/downloadNotifications';
import AddGroupModal from '../../AddGroupModal';
import DeleteGroupModal from '../../DeleteGroupModal';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import ChangeAccessModal from '../../modals/ChangeAccessModal/ChangeAccessModal';
import CreateGroupCopy from '../../modals/CreateGroupCopy';
import DateModal from '../../modals/DateModal';
import useHandleCopy from './handlers/useHandleCopy';
import useMenu from './useMenu';

export default function useGroupMenu(object) {
  const dispatch = useDispatch();
  const items = useMenu();
  const handleCopy = useHandleCopy();
  const navigate = useCustomNavigate();
  const { getParamsByRoute } = useRoute();

  return [
    items.genMenuEditItem({
      handleAction: () => {
        AddGroupModal({
          object,
        })
          .then()
          .catch(() => {});
      },
    }),
    items.genMenuAccess({
      handleAction: () => {
        ChangeAccessModal({ object }).then();
      },
    }),
    items.genMenuCreateCopy({
      disabled: false,
      handleAction: () => {
        CreateGroupCopy({
          group: object,
        })
          .then()
          .catch();
      },
    }),
    items.genMenuShowHistory({
      handleAction: () => {
        const { dashboardId, reportId } = getParamsByRoute();

        dispatch(setSettings({ isShowHistory: true }));

        if (dashboardId) {
          navigate(`/boards/${dashboardId}/${object.id}`);
        }

        if (reportId) {
          navigate(`/reports/${reportId}/${object.id}`);
        }
      },
    }),
    items.genMenuDownloadNotifications({
      handleAction: () => {
        DateModal({
          downloadHistory: downloadNotifications,
          downloadIds: [object.id],
        })
          .then()
          .catch(() => {});
      },
    }),
    items.getGenPdf({
      handleAction: () => {
        let node = document.querySelector(`[data-id=pdf-${object.id}]`);
        let width = node.offsetWidth;
        let height = node.offsetHeight;

        node.style.width = `${width}px`;
        node.style.height = `${height}px`;

        let opt = {
          margin: 1,
          filename: `${object.name}`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: {
            useCORS: true,
            width: width,
            backgroundColor: null,
          },
          jsPDF: {
            format: 'a4',
            orientation: 'portrait',
          },
        };

        html2pdf().from(node).set(opt).save();
      },
    }),
    items.genCopyUUID({
      handleAction: () => handleCopy({ object }),
    }),
    items.genMenuDelete({
      handleAction: () => {
        DeleteGroupModal({
          groupIds: [object.id],
          name: object.name,
        })
          .then()
          .catch(() => {});
      },
    }),
  ];
}
