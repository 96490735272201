import { SettingsSizeOptions, SettingsStyleOptions } from '../../../utils/constants/selectOptions';
import { FormatEnum, OrientationFormat, TDataboxState } from './DataboxTypes';

export const FORM_DEFAULT_VALUES: TDataboxState = {
  settingsSize: SettingsSizeOptions.small,
  settingsStyle: SettingsStyleOptions.lightondark,
  settingsFormat: FormatEnum.icon_value,
  settingsOrientation: OrientationFormat.vertical,
  settingsIcon: '0ba1d188-a287-4a03-bf2f-f9be86c2e1b8',
  settingsShowTitle: false,
};

export const FORMAT_OPTIONS = [
  { title: 'Icon & Value', value: FormatEnum.icon_value },
  { title: 'Icon', value: FormatEnum.icon },
  { title: 'Value', value: FormatEnum.value },
];

export const ORIENTATION_OPTIONS = [
  { title: 'Vertical', value: OrientationFormat.vertical },
  { title: 'Horizontal', value: OrientationFormat.horizontal },
];

export const SETTINGS_SIZE_OPTIONS = [
  {
    value: SettingsSizeOptions.small,
    title: 'Small',
  },
  {
    value: SettingsSizeOptions.medium,
    title: 'Medium',
  },
];

export const SETTINGS_SIZE_OPTIONS_MINIMAP = [
  {
    value: SettingsSizeOptions.tiny,
    title: 'Tiny',
  },
  {
    value: SettingsSizeOptions.small,
    title: 'Small',
  },
  {
    value: SettingsSizeOptions.medium,
    title: 'Medium',
  },
];
