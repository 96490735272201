import { LinkOff } from '@mui/icons-material';

const EmptyTableWithoutColumns = ({ fgColor }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          color: fgColor,
          flexDirection: 'column',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <LinkOff fontSize={'large'} />
        <span>No data</span>
      </div>
    </div>
  );
};

export default EmptyTableWithoutColumns;
