// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import RefreshIcon from "@mui/icons-material/Refresh";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
// import { formatRelative, parseISO } from "date-fns";
import React, { useState } from 'react';
import RpcProcessModal from '../../modals/RpcProcessModal';
const RpcSubscribeWrapper = (props) => {
  const [loading, setLoading] = useState(false);

  // const control =
  //   props?.object?.controlExecutions.filter(
  //     (item) => item.name === props.rpcName
  //   )?.[0] || null;

  const rpcCall = () => {
    setLoading(true);
    RpcProcessModal({
      rpcName: props.rpcName,
      id: null,
      callRpc: props.handler,
      objectId: props.objectId,
      successCb: props.successCb,
    }).finally(() => {
      setLoading(false);
    });
  };

  // const tooltipComponent = (controlLocal) => {
  //   if (!controlLocal) {
  //     return null;
  //   }
  //
  //   const text = formatRelative(parseISO(control?.createdAt), Date.now());
  //
  //   return (
  //     <>
  //       {controlLocal.ack === false && `Failed ${text}`}
  //
  //       {controlLocal.ack === true &&
  //         controlLocal.done === false &&
  //         `Started ${text}`}
  //
  //       {controlLocal.ack === true &&
  //         controlLocal.done === true &&
  //         controlLocal.error === null &&
  //         `Done ${text}`}
  //
  //       {controlLocal.ack === true &&
  //         controlLocal.done === true &&
  //         controlLocal.error === true &&
  //         `Finished with error ${text}`}
  //     </>
  //   );
  // };

  // const latestCallIcon = (controlLocal) => {
  //   if (!controlLocal) {
  //     return null;
  //   }
  //
  //   return (
  //     <>
  //       {controlLocal.ack === false && <ErrorOutlineIcon />}
  //
  //       {controlLocal.ack === true && controlLocal.done === false && (
  //         <RefreshIcon />
  //       )}
  //
  //       {controlLocal.ack === true &&
  //         controlLocal.done === true &&
  //         controlLocal.error === null && <CheckCircleOutlineIcon />}
  //
  //       {controlLocal.ack === true &&
  //         controlLocal.done === true &&
  //         controlLocal.error === true && <ErrorOutlineIcon />}
  //
  //       {controlLocal.ack === undefined &&
  //         controlLocal.done === undefined &&
  //         controlLocal.error === undefined && <ErrorOutlineIcon />}
  //     </>
  //   );
  // };

  return (
    <>
      {React.cloneElement(props.children, {
        onClick: rpcCall,
        disabled: Boolean(props.disabled),
        children: (
          <>
            {/*<Tooltip title={tooltipComponent(control)}>*/}
            {/*  <IconButton*/}
            {/*    disabled={props.disabled}*/}
            {/*    onClick={(e) => e.stopPropagation()}*/}
            {/*    size={"small"}*/}
            {/*    style={{ position: "absolute", left: 0 }}*/}
            {/*  >*/}
            {/*    {latestCallIcon(control)}*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}
            {props.title}
          </>
        ),
        loading: loading ? '1' : undefined,
      })}
    </>
  );
};

export default RpcSubscribeWrapper;
