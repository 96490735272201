import { useRef, useState } from 'react';
import { mediaServer } from '../constants/constants';

const useFileUpload = () => {
  const uploadInputRef = useRef(null);

  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [imageId, setImageId] = useState(0);

  const handleFileUpload = async (e, file_uid, accept, maxSize, handleUploaded, handleError) => {
    const [file] = e.target.files;

    if (file.size > maxSize) {
      handleError(`File too large (max ${maxSize} bytes)`);

      return false;
    }

    if (!accept.includes(file.type)) {
      handleError('Wrong file format');
      return false;
    }

    setFileIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('uploaded_file', file, file.name);

      const uploadUID = file_uid ? file_uid : '0';

      const response = await fetch(`${mediaServer}/upload/${uploadUID}/${localStorage.getItem('authToken')}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: formData,
      });

      const result_uid = await response.text();

      setImageId(result_uid);
      setFileName(fileName);
      setFileIsUploading(false);

      handleUploaded(result_uid, file.name);
    } catch (e) {
      setFileIsUploading(false);
      if (e.response) {
        if (e.response.status === 403) {
          handleError('file upload error 403');
        }
      } else {
        handleError('file upload error');
      }
    }
  }; //handleFileUpload

  const FileUpload = (props) => {
    const { file_uid, accept, maxSize, handleUploaded, handleError } = props;

    return (
      <input
        ref={uploadInputRef}
        type="file"
        accept={accept.join(',')}
        hidden
        multiple={false}
        onChange={(e) => {
          handleFileUpload(e, file_uid, accept, maxSize, handleUploaded, handleError).then(() => {});
        }}
      />
    );
  };

  return {
    FileUpload,
    uploadInputRef,
    fileIsUploading,
    fileName,
    imageId,
  };
};

export default useFileUpload;
