import { getWidgetType } from '../../../utils/widgetType';
import { WIDGETS_ENUM } from '../../../utils/widgetTypes';
import EditWidgetActionModal from '../../EditWidgetActionModal';
import EditWidgetSourceModal from '../../EditWidgetSourceModal';
import useMenu from '../menu/useMenu';

export default function useValueActionMenu({ property, object }) {
  const items = useMenu();

  return [
    items.genMenuEditItem({
      title: 'Edit source',
      handleAction: () => {
        // логика для виджета кнопки
        if (getWidgetType(object.schemaTags) === WIDGETS_ENUM.COMMAND_BUTTON) {
          EditWidgetActionModal({
            widgetId: object.id,
            propId: property.id,
            propKey: property.key,
            schemaTags: object.schemaTags,
          })
            .then()
            .catch(() => {});
        } else {
          EditWidgetSourceModal({
            widgetId: object.id,
            propId: property.id,
            propKey: property.key,
            schemaTags: object.schemaTags,
          })
            .then()
            .catch(() => {});
        }
      },
    }),
    items.getMenuAlarms({
      disabled: true,
      handleAction: () => {},
    }),
  ];
}
