import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  [key: string]: unknown;
} = null;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<{ [key: string]: unknown }>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
