import { useLazyQuery } from '@apollo/client';
import { Fade } from '@mui/material';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import { loader } from 'graphql.macro';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import scrollIntoView from 'scroll-into-view-if-needed';
import { msg } from '../../constants/messages';
import { GET_DATA_SUBSCRIPTION } from '../../graphql/queries';
import useRoute from '../../hooks/useRoute';
import isAnyPartOfElementInViewport from '../../utils/isAnyPartOfElementInViewport';
import {
  isAdvancedButton,
  isCalculator,
  isChart,
  isColorChart,
  isDatabox,
  isGeotagsTable,
  isHistoryTable,
  isMonitorStatusTable,
  isMonitorTable,
  isPropertyHistoryTable,
  isStaticTable,
  isTimer,
  isTrackingBox,
  isTrackingTable,
} from '../../utils/objectType';
import TopButton from '../TopButton';
import AdvancedButtonGeneralTab from './advanced-button/AdvancedButtonGeneralTab';
import TabGeneralCalculatorStatistic from './calculator-statistic/TabGeneralCalculatorStatistic';
import CardImage from './CardImage';
import CardToolbar from './CardToolbar';
import ChartGeneralTab from './chart/ChartGeneralTab';
import ColorChartGeneralTab from './chart/ColorChartGeneralTab';
import TabGeneralCounterStatistic from './counter-statistic/TabGeneralCounterStatistic';
import TabGeneralSource from './counter-statistic/TabGeneralSource';
import DataboxGeneralTab from './databox/DataboxGeneralTab';
import TabGeneralTrackingBox from './geo-timer/TabGeneralTrackingBox';
import GeotagsTableGeneral from './geotags-table/GeotagsTableGeneral';
import NormalHeader from './header/NormalHeader';
import HistoryControlsProperties from './history-controls/HistoryControlsProperties';
import HistoryTableGeneral from './history-table/HistoryTableGeneral';
import HistoryProperties from './history/HistoryProperties';
import useUniversalMenu from './menu/useUniversalMenu';
import MonitorStatusTableGeneral from './monitor-status-table/MonitorStatusTableGeneral';
import MonitorTableGeneral from './monitor-table/MonitorTableGeneral';
import PropertyHistoryTableGeneral from './property-history-table/PropertyHistoryTableGeneral';
import StaticTableGeneral from './static-table/StaticTableGeneral';
import TabContentControls from './TabContentControls';
import TabContentDashboards from './TabContentDashboards';
import TabContentGeneral from './TabContentGeneral';
import TabContentNotifications from './TabContentNotifications';
import TabContentObjects from './TabContentObjects';
import TabContentProperties from './TabContentProperties';
import TabPanel from './TabPanel';
import TimerGeneralTab from './timer/TimerGeneralTab';
import TabGeneralTimeseriesStatistic from './timeseries-statistic/TabGeneralTimeseriesStatistic';
import TabSourceTimeseries from './timeseries-statistic/TabSourceTimeseries';
import TrackingTableGeneral from './tracking-table/TrackingTableGeneral';

const GET_OBJECT = loader('../../graphql/GetObjectQuery.graphql');

const useStyles = makeStyles((theme) => ({
  tabButton: {
    minWidth: 'auto',
    flexBasis: 'auto',
    paddingInline: '16px',
  },
  tabs: {
    padding: 0,
    position: 'sticky',
    background: theme.palette.white,
    top: '68px',
    zIndex: 2,
    borderBottom: '1px solid rgba(0,0,0,.12)',
  },
  tabsHidden: {
    minHeight: '0px',
    transition: '0.5s',
  },

  tabPanel: {
    marginTop: '0px',
  },
}));

const tabs = {
  collection: ['general', 'objects', 'notifications'],
  group: ['general', 'widgets', 'notifications'],
  object: ['general', 'properties', 'controls', 'notifications'],
  statistics: ['general', 'source', 'objects', 'notifications'],
  timeseries: ['general', 'source', 'objects', 'notifications'],
  widget: ['general', 'objects', 'notifications'],
};

const SideCard = (props) => {
  const { objectId, widgetId, groupId, collectionId } = useParams();
  const { getTypeByRoute } = useRoute();
  const { menuBasedOnType } = useUniversalMenu();

  const isShowHistoryProperty = useSelector((state) => state.settings.isShowHistoryProperty);
  const id = objectId || widgetId || groupId || collectionId;
  const type = getTypeByRoute();

  const [loadObject, { data, loading, refetch, subscribeToMore }] = useLazyQuery(GET_OBJECT, {
    variables: { objectId: id },
    fetchPolicy: 'cache-and-network',
  });
  const isShowHistory = useSelector((state) => state.settings.isShowHistory);
  const isShowControlsHistory = useSelector((state) => state.settings.isShowControlsHistory);

  const classes = useStyles();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState('general');
  const topRef = useRef(null);
  const sideCard = useRef(null);
  const [sFixed, setSFixed] = useState(false); // if true (onscroll), fix header
  const [sFixedFade] = useState(true); // if false (onscroll), fade in fix header
  const [sShowBack] = useState(false); // if true (onscroll) , show go top button

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: GET_DATA_SUBSCRIPTION,
      variables: {
        objId: id,
      },
    });

    return () => unsubscribe();
  }, [id]);

  useEffect(() => {
    loadObject().then(() => {});
  }, [objectId, groupId, widgetId]);

  useEffect(() => {
    if (type === 'group') {
      let node = document.querySelector(`[data-id=t-${id}]`);

      if (node && !isAnyPartOfElementInViewport(node)) {
        scrollIntoView(node, {
          behavior: 'smooth',
          scrollMode: 'if-needed',
          block: 'center',
          inline: 'center',
        });
      }
    } else {
      sideCard?.current?.scroll({ left: 0, top: 0 });
      setSFixed(false);
    }
  }, [objectId, groupId, widgetId]);

  if (!data || loading) return <LinearProgress style={{ width: '100%' }} />;
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const isCounterStatistic = (tags) => {
    return tags.includes('statistics') && tags.includes('counter');
  };

  const isTimeseriesStatistic = (tags) => {
    return tags.includes('statistics') && tags.includes('timeseries');
  };

  const getType = () => {
    if (data.object.schemaTags.includes('statistics') && data.object.schemaTags.includes('counter')) {
      return 'statistics';
    }

    if (data.object.schemaTags.includes('statistics') && data.object.schemaTags.includes('timeseries')) {
      return 'timeseries';
    }

    if (data.object.schemaTags.includes('collection')) {
      return 'collection';
    }

    return type;
  };

  const getTargetObjectId = () => {
    return data.object.id;
  };

  if (!data?.object?.schema) {
    return null;
  }

  return (
    <>
      {isShowHistory && <HistoryProperties id={data.object.id} object={data.object} />}
      {isShowControlsHistory && <HistoryControlsProperties id={data.object.id} object={data.object} />}
      <div
        style={{
          width: props.sideBarWidth,
          height: '100%',
          overflowY: 'auto',
        }}
        ref={sideCard}
        onScroll={(e) => {
          if (e.target.scrollTop > 200) {
            setSFixed(true);
          } else {
            setSFixed(false);
          }
        }}
      >
        <div ref={topRef} />
        {!isShowHistoryProperty && !isShowHistory && !isShowControlsHistory && (
          <CardToolbar item={data.object} isFixed={sFixed} menuItems={() => menuBasedOnType(data.object)} {...props} />
        )}
        <TopButton in={sShowBack} topRef={topRef} />
        <Fade in={sFixedFade}>
          <div>
            <CardImage type={type} object={data.object} />
          </div>
        </Fade>
        <NormalHeader item={data.object} menuItems={() => menuBasedOnType(data.object)} {...props} />
        <Tabs
          onChange={handleChangeTab}
          value={tabs[getType(type)].find((tab) => tab === tabValue) ? tabValue : setTabValue('general')}
          aria-label="tabs"
          TabIndicatorProps={{ style: { backgroundColor: theme.palette.blue } }}
          className={classes.tabs}
        >
          {tabs[getType(type)].map((tab) => (
            <Tab
              data-test-tab={tab}
              value={tab}
              label={msg.sideCard[tab]}
              className={classes.tabButton}
              aria-label={tab}
              style={{}}
              key={tab}
            />
          ))}
        </Tabs>
        <Divider style={{ position: 'relative', top: '-1px' }} />
        {isCounterStatistic(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <TabGeneralCounterStatistic item={data.object} {...props} />
          </TabPanel>
        )}
        {isCalculator(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <TabGeneralCalculatorStatistic item={data.object} {...props} />
          </TabPanel>
        )}
        {isTimeseriesStatistic(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <TabGeneralTimeseriesStatistic item={data.object} {...props} />
          </TabPanel>
        )}
        {isCounterStatistic(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="source" className={classes.tabPanel}>
            <TabGeneralSource item={data.object} {...props} />
          </TabPanel>
        )}
        {isTimeseriesStatistic(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="source" className={classes.tabPanel}>
            <TabSourceTimeseries item={data.object} {...props} />
          </TabPanel>
        )}
        {isTrackingBox(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <TabGeneralTrackingBox item={data.object} {...props} />
          </TabPanel>
        )}
        {isHistoryTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <HistoryTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isMonitorTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <MonitorTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isMonitorStatusTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <MonitorStatusTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isStaticTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <StaticTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isChart(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <ChartGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {isColorChart(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <ColorChartGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {isTimer(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <TimerGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {isAdvancedButton(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <AdvancedButtonGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {isTrackingTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <TrackingTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isGeotagsTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <GeotagsTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isPropertyHistoryTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <PropertyHistoryTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isDatabox(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
            <DataboxGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {!isChart(data.object.schemaTags) &&
          !isMonitorStatusTable(data.object.schemaTags) &&
          !isStaticTable(data.object.schemaTags) &&
          !isTimer(data.object.schemaTags) &&
          !isHistoryTable(data.object.schemaTags) &&
          !isMonitorTable(data.object.schemaTags) &&
          !isAdvancedButton(data.object.schemaTags) &&
          !isPropertyHistoryTable(data.object.schemaTags) &&
          !isCounterStatistic(data.object.schemaTags) &&
          !isCalculator(data.object.schemaTags) &&
          !isDatabox(data.object.schemaTags) &&
          !isTrackingBox(data.object.schemaTags) &&
          !isTimeseriesStatistic(data.object.schemaTags) &&
          !isTrackingTable(data.object.schemaTags) &&
          !isGeotagsTable(data.object.schemaTags) &&
          !isColorChart(data.object.schemaTags) && (
            <TabPanel value={tabValue} index="general" className={classes.tabPanel}>
              <TabContentGeneral item={data.object} {...props} />
            </TabPanel>
          )}
        <TabPanel value={tabValue} index="properties" className={classes.tabPanel}>
          <TabContentProperties item={data.object} topRef={topRef} {...props} />
        </TabPanel>
        <TabPanel value={tabValue} index="controls" className={classes.tabPanel}>
          <TabContentControls item={data.object} topRef={topRef} {...props} />
        </TabPanel>
        {type === 'group' && (
          <TabPanel value={tabValue} index="widgets" className={classes.tabPanel}>
            <TabContentObjects item={data.object} type={type} refetch={refetch} {...props} />
          </TabPanel>
        )}
        {(type === 'widget' || type === 'object') && (
          <TabPanel value={tabValue} index="objects" className={classes.tabPanel}>
            <TabContentObjects item={data.object} refetch={refetch} type={type} {...props} />
          </TabPanel>
        )}
        {type === 'collection' && (
          <TabPanel value={tabValue} index="objects" className={classes.tabPanel}>
            <TabContentDashboards item={data.object} refetch={refetch} type={type} {...props} />
          </TabPanel>
        )}
        <TabPanel value={tabValue} index="notifications" className={classes.tabPanel}>
          <TabContentNotifications id={getTargetObjectId()} item={data.object} {...props} type={type} />
        </TabPanel>
      </div>
    </>
  );
};

export default SideCard;
