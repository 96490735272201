// groups to show in add/edit widgets
const groupNames = {
  'advanced button': ['Settings'],
  'geo timer': [],
  'command button': ['Settings', 'Value'],
  'monitor table': ['Settings', 'Columns', 'Source'],
  'monitor status': ['Settings', 'Columns'],
  counter: ['Settings'],
  databox: ['Settings'],
  timer: ['Settings'],
  'databox advanced': ['Settings'],
  datachart: ['Settings'],
  'color datachart': ['Settings'],
  diagram: ['Settings'],
  'history table': ['Settings', 'Columns'],
  'static table': ['Settings', 'Columns'],
  'property history table': ['Settings', 'Columns'],
  datetime: ['Settings'],
  'group table': ['Settings'],
  imagebox: ['Settings'],
  indicator: ['Settings'],
  'object table': ['Settings'],
  slider: ['Settings'],
  switcher: ['Settings'],
  title: ['Settings', 'Value'],
  vibration: ['Settings'],
  weatherbox: ['Settings'],
};

export default groupNames;
