/* tslint:disable */
import { useLazyQuery, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { loader } from 'graphql.macro';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useBus from 'use-bus';
import DashboardControls from '../../../components/common/DashboardControls';
import SelectWidget from '../../../components/create-widget/SelectWidget';
import GroupControls from '../../../components/dashboard/GroupControls';
import useCustomNavigate from '../../../components/hooks/useCustomNavigate';
import Spinner from '../../../components/Spinner';
import { msg } from '../../../constants/messages';
import useRoute from '../../../hooks/useRoute';
import { setContext } from '../../../store/contextSlice';
import { setSettings } from '../../../store/settingsSlice';
import useMedia from '../../../utils/useMedia';
import { CHECK_PERMISSION } from '../api/CheckPermission';
import DragHandleGroup from './DragHandleGroup';
import GridHighlight from './GridHighlight';
import WidgetWrap from './WidgetWrap';
const DASHBOARD_QUERY = loader('../../../graphql/DashboardQuery.graphql');
const UPDATE_PROPERTIES_BY_IDS = loader('../../../graphql/UpdateObjectPropertiesByIdMutation.graphql');

const ResponsiveGridLayout = WidthProvider(Responsive);

const Wrapper = styled('div')(() => ({
  [`&`]: {
    position: 'relative',
    paddingRight: '3px',
    paddingLeft: '3px',

    backgroundSize: 'cover',
    backgroundRepeat: 'repeat-y',
    backgroundClip: 'border-box',
    backgroundAttachment: 'scroll',
    overflow: 'auto',
    minHeight: '100%',
  },
  [`& .groupWrapper`]: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat-y',
    backgroundClip: 'border-box',
    backgroundAttachment: 'scroll',
    overflow: 'auto',
    minHeight: '100%',
  },
}));

const Report = React.memo(() => {
  const { getImageById } = useMedia();
  const theme = useTheme();
  const { reportId } = useParams();
  const dispatch = useDispatch();
  const history = useCustomNavigate();

  const isEditMode = useSelector((state) => state.settings.isEditMode);
  const isViewMode = useSelector((state) => state.settings.isViewMode);
  const isFullScreen = useSelector((state) => state.settings.isFullScreen);
  const isShowGrid = useSelector((state) => state.settings.isShowGrid);
  const prevBoard = useSelector((state) => state.settings.prevBoard);
  const objectHoveredId = useSelector((state) => state.context.objectHoveredId);

  const [loadDashboard, { data: dashboardData }] = useLazyQuery(DASHBOARD_QUERY, {
    variables: {
      dashboardId: reportId,
    },
    fetchPolicy: 'network-only',
  });

  const [widgetsPositionsWithinGroups, setWidgetsPositionsWithinGroups] = useState({});
  const [containerPositionWithinDashboard, setContainerPositionWithinDashboard] = useState([]);

  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);

  const dashboardTitle = dashboardData?.dashboard?.objectProperties.find((prop) => prop.key === 'generalTitle').value;
  const dashboardSubTitle = dashboardData?.dashboard?.objectProperties.find(
    (prop) => prop.key === 'generalSubtitle'
  ).value;

  const dashboardTitleStyle = dashboardData?.dashboard?.objectProperties.find(
    (prop) => prop.key === 'generalTitleStyle'
  ).value;

  const dashboardBackgroundImageUID = dashboardData?.dashboard?.objectProperties.find(
    (prop) => prop.key === 'generalBackgroundImageUid'
  ).value;

  const dashboardBackgroundColor = dashboardData?.dashboard?.objectProperties?.find(
    (prop) => prop.key === 'generalBgColor'
  )?.value;

  const [checkPermissions] = useMutation(CHECK_PERMISSION);

  useEffect(() => {
    if (
      !isEqual(prevBoard, {
        widgetsPositionsWithinGroups,
        containerPositionWithinDashboard,
      })
    ) {
      dispatch(setSettings({ isBoardSaved: false }));
    }
  }, [containerPositionWithinDashboard, widgetsPositionsWithinGroups]);

  useEffect(() => {
    checkPermissions({
      variables: {
        input: {
          objectId: reportId,
        },
      },
    }).then(({ data }) => {
      dispatch(
        setSettings({
          isMayEdit: data.resolveObjectPermissions.accessRight.edit,
        })
      );
      return loadDashboard();
    });
  }, [reportId]);

  const visibleGroups = (dashboardData) => {
    const groups = dashboardData.dashboard.groups;
    const layouts = dashboardData.dashboard.layouts[0].value.lg;

    return groups.filter(({ group }) => Boolean(layouts.find((layoutCell) => layoutCell.i === group.id)));
  };

  const getGroupLayouts = (group) => {
    const localPositionWithinGroup = widgetsPositionsWithinGroups[group.layouts[0].id];
    const fromServerPositionWithinGroup = group.layouts[0].value;

    if (localPositionWithinGroup) {
      return {
        lg: localPositionWithinGroup,
        md: localPositionWithinGroup,
        sm: localPositionWithinGroup,
        xs: localPositionWithinGroup,
        xxs: localPositionWithinGroup,
      };
    } else {
      return {
        lg: fromServerPositionWithinGroup,
        md: fromServerPositionWithinGroup,
        sm: fromServerPositionWithinGroup,
        xs: fromServerPositionWithinGroup,
        xxs: fromServerPositionWithinGroup,
      };
    }
  };

  const isMinimap = (group) => group.type[0]?.value === 'minimap';

  const getGroupCols = (group) => {
    const isMinimap = group.type[0]?.value === 'minimap';

    const localCols = isMinimap
      ? containerPositionWithinDashboard.find((t) => t.i === group.id)?.w * 4
      : containerPositionWithinDashboard.find((t) => t.i === group.id)?.w * 2;
    const fromServerCols = isMinimap ? group.columnsCount[0].value * 4 : group.columnsCount[0].value * 2;

    if (localCols) {
      return {
        lg: localCols,
        md: localCols,
        sm: localCols,
        xs: localCols,
        xxs: localCols,
      };
    } else {
      return {
        lg: fromServerCols,
        md: fromServerCols,
        sm: fromServerCols,
        xs: fromServerCols,
        xxs: fromServerCols,
      };
    }
  };

  const getGroupRowHeight = (group) => {
    const type = group.type[0].value;

    if (type === 'group' || !type) {
      return 50;
    } else {
      return 25;
    }
  };

  const visibleWidgets = (group) => {
    const widgets = group.widgets;
    const layouts = group.layouts?.[0]?.value || [];

    return widgets.filter(({ widget }) => Boolean(layouts.find((layoutCell) => layoutCell.i === widget.id)));
  };

  const saveBoard = (e, isSilent) => {
    if (!dashboardData?.dashboard.groups.length) {
      return;
    }

    const propertiesArray = [];

    for (const prop in widgetsPositionsWithinGroups) {
      propertiesArray.push({
        propertyId: prop,
        value: widgetsPositionsWithinGroups[prop],
      });
    }

    containerPositionWithinDashboard.forEach((layout) => {
      const assosiatedGroup = dashboardData.dashboard.groups.find((item) => {
        return item.group.id === layout.i;
      })?.group.objectProperties;

      const generalColumnsCount = assosiatedGroup.find((property) => property.key === 'generalColumnsCount');
      const generalRowsCount = assosiatedGroup.find((property) => property.key === 'generalRowsCount');

      propertiesArray.push({
        propertyId: generalColumnsCount.id,
        value: layout.w,
      });
      propertiesArray.push({
        propertyId: generalRowsCount.id,
        value: layout.h,
      });
    });

    if (containerPositionWithinDashboard.length) {
      propertiesArray.push({
        propertyId: dashboardData.dashboard.layouts[0].id,
        value: {
          lg: containerPositionWithinDashboard,
          md: containerPositionWithinDashboard,
          sm: containerPositionWithinDashboard,
          xs: containerPositionWithinDashboard,
          xxs: containerPositionWithinDashboard,
        },
      });
    }

    if (!propertiesArray.length) return;

    if (!isSilent) {
      toast
        .promise(updateProperties({ variables: { input: { propertiesArray } } }), {
          loading: 'Autosaving positions...',
          success: () => `All positions successfully saved`,
          error: (err) => `${err.toString()}`,
        })
        .then(() => {
          dispatch(setSettings({ isBoardSaved: true }));
          dispatch(
            setSettings({
              prevBoard: {
                widgetsPositionsWithinGroups,
                containerPositionWithinDashboard,
              },
            })
          );
          window.dispatchEvent(new Event('resize'));
        });
    } else {
      updateProperties({ variables: { input: { propertiesArray } } }).then(() => {
        dispatch(setSettings({ isBoardSaved: true }));
        window.dispatchEvent(new Event('resize'));
      });
    }
  };

  const getGridGroups = (group) => {
    return dashboardData.dashboard.layouts[0].value.lg.find((item) => item.i === group.id);
  };

  const getGridWidgets = (group, widgetId) => {
    return group.layouts[0].value.find((item) => item.i === widgetId);
  };

  const getBackgroundColorOfContainer = (group) => {
    if (isEditMode && group.bg[0].value === 'transparent') {
      return 'rgba(39, 128, 227, 0.5)';
    } else {
      return group.bg[0].value;
    }
  };

  const getBackgroundImageOfContainer = (group) => {
    if (group.bgImage[0].value) {
      return `url(${getImageById(group.bgImage[0].value)})`;
    } else {
      return '';
    }
  };

  useBus(
    '@@board/SAVE_BOARD',
    () => {
      saveBoard('', true);
    },
    [widgetsPositionsWithinGroups]
  );

  useBus(
    '@@board/RESET_DASHBOARD_LOCAL_STATE',
    () => {
      setWidgetsPositionsWithinGroups({});
    },
    []
  );
  const { getParamsByRoute } = useRoute();
  const { widgetId } = getParamsByRoute();

  if (!dashboardData?.dashboard) return <Spinner />;

  return (
    <>
      {!isViewMode && <DashboardControls saveBoardCb={saveBoard} reportId={reportId} />}
      <Wrapper
        data-test-report-bg={dashboardBackgroundColor}
        style={{
          backgroundImage: dashboardBackgroundImageUID ? `url(${getImageById(dashboardBackgroundImageUID)})` : 'none',
          backgroundColor: dashboardBackgroundColor,
        }}
      >
        <Grid container justifyContent="center" alignItems="center" style={{ height: '64px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              data-report-title={dashboardTitle}
              variant="h6"
              style={{
                display: 'block',
                height: '32px',
                paddingBottom: '4px',
                textAlign: 'center',

                color: dashboardTitleStyle === 'light' ? theme.palette.white : theme.palette.blue,
              }}
            >
              {dashboardTitle}
            </Typography>
            <Typography
              data-report-title={dashboardTitle}
              variant="body2"
              style={{
                textAlign: 'center',
                textTransform: 'uppercase',
                color: dashboardTitleStyle === 'light' ? theme.palette.white : theme.palette.blue,
              }}
            >
              {dashboardSubTitle}
            </Typography>
          </div>
        </Grid>

        {!isEditMode && dashboardData.dashboard.layouts[0].value.xs.length === 0 && (
          <Typography
            variant="h6"
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              margin: '0 auto',
              top: '50%',
              transform: 'translateY(-50%)',
              textAlign: 'center',
              color: theme.palette.blue,
            }}
          >
            {msg.dashboard.noContainers}
          </Typography>
        )}

        <div className="dashboard-container" style={{ width: '2500px', paddingBottom: '200px' }}>
          <ResponsiveGridLayout
            className="layout"
            layouts={dashboardData.dashboard.layouts[0].value}
            cols={{ lg: 25, md: 25, sm: 25, xs: 25, xxs: 25 }}
            rowHeight={50}
            margin={[0, 0]}
            containerPadding={[0, 0]}
            compactType={null}
            isDraggable={isEditMode}
            isResizable={isEditMode}
            resizeHandle={
              <div data-group-resize-handle className="react-resizable-handle react-resizable-handle-se"></div>
            }
            isBounded={false}
            allowOverlap={false}
            preventCollision={true}
            draggableHandle={isEditMode ? '.handleGroup' : null}
            measureBeforeMount={true}
            onDragStop={(layout) => {
              setContainerPositionWithinDashboard(layout);
              window.dispatchEvent(new Event('resize'));
            }}
            onResizeStop={(layout) => {
              setContainerPositionWithinDashboard(layout);
              window.dispatchEvent(new Event('resize'));
            }}
            onLayoutChange={(layout) => {
              setContainerPositionWithinDashboard(layout);
              window.dispatchEvent(new Event('resize'));
            }}
          >
            {visibleGroups(dashboardData).map(({ group }) => {
              return (
                <div
                  style={{ height: '100%' }}
                  data-grid={{ ...getGridGroups(group) }}
                  key={group.id}
                  data-id={`t-${group.id}`}
                >
                  <div
                    onClick={(e) => {
                      dispatch(
                        setSettings({
                          isShowHistory: false,
                          isShowControlsHistory: false,
                          isShowHistoryProperty: false,
                        })
                      );
                      if (!isFullScreen && (e.target.dataset.targetEmptyGroup || e.target.dataset.targetGroup)) {
                        history(`/reports/${reportId}/${group.id}`);
                      }
                    }}
                    data-target-group={true}
                    data-id={`pdf-${group.id}`}
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      overflow: 'hidden',
                      cursor: !isFullScreen ? 'pointer' : 'default',
                    }}
                  >
                    {isEditMode && (
                      <>
                        <DragHandleGroup
                          onClick={() => {
                            dispatch(
                              setSettings({
                                isShowHistory: false,
                                isShowControlsHistory: false,
                                isShowHistoryProperty: false,
                              })
                            );
                            history(`/reports/${reportId}/${group.id}`);
                          }}
                        ></DragHandleGroup>

                        {isShowGrid && isEditMode && <GridHighlight isMinimap={isMinimap(group)} />}
                        {Boolean(group.widgets.length) && (
                          <div
                            style={{
                              cursor: 'pointer',
                              width: '24px',
                              height: '24px',
                              backgroundColor: 'rgba(255, 255, 255, 0.44)',
                              position: 'absolute',
                              left: '0',
                              zIndex: '999',
                            }}
                          >
                            <Tooltip title={msg.dashboard.addWidget}>
                              <IconButton
                                size={'small'}
                                style={{ padding: 0 }}
                                onClick={() =>
                                  SelectWidget({
                                    group: group,
                                    groupId: group.id,
                                  })
                                    .then()
                                    .catch(() => {})
                                }
                              >
                                <AddIcon style={{ color: '#686868' }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                      </>
                    )}
                    {group.widgets.length === 0 && (
                      <GroupControls
                        group={group}
                        backgroundImage={getBackgroundImageOfContainer(group)}
                        backgroundColor={getBackgroundColorOfContainer(group)}
                        isHasWidgets={group.widgets.length}
                      />
                    )}

                    {visibleWidgets(group).length !== 0 && (
                      <div
                        data-target-group="1"
                        onClick={(e) => {
                          dispatch(
                            setSettings({
                              isShowHistory: false,
                              isShowControlsHistory: false,
                              isShowHistoryProperty: false,
                            })
                          );
                          if (!isFullScreen && e.target.classList.value === 'react-grid-layout layout') {
                            history(`/reports/${reportId}/${group.id}`);
                          }
                        }}
                        style={{
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          backgroundImage: getBackgroundImageOfContainer(group),
                          backgroundColor: getBackgroundColorOfContainer(group),
                          height: '100%',
                          width: '100%',
                          overflow: 'hidden',
                          borderRadius: '2px',
                        }}
                      >
                        <ResponsiveGridLayout
                          className="layout"
                          layouts={getGroupLayouts(group)}
                          cols={getGroupCols(group)}
                          margin={[0, 0]}
                          containerPadding={[0, 0]}
                          isBounded={false}
                          isResizable={isEditMode}
                          resizeHandle={
                            <div
                              data-widget-resize-handle
                              className="react-resizable-handle react-resizable-handle-se"
                            ></div>
                          }
                          onDragStop={(layout) => {
                            setWidgetsPositionsWithinGroups({
                              ...widgetsPositionsWithinGroups,
                              [group.layouts[0].id]: layout,
                            });

                            window.dispatchEvent(new Event('resize'));
                          }}
                          onResizeStop={(layout) => {
                            setWidgetsPositionsWithinGroups({
                              ...widgetsPositionsWithinGroups,
                              [group.layouts[0].id]: layout,
                            });

                            window.dispatchEvent(new Event('resize'));
                          }}
                          onLayoutChange={(layout) => {
                            setWidgetsPositionsWithinGroups({
                              ...widgetsPositionsWithinGroups,
                              [group.layouts[0].id]: layout,
                            });

                            window.dispatchEvent(new Event('resize'));
                          }}
                          preventCollision={false}
                          allowOverlap={true}
                          measureBeforeMount={true}
                          isDraggable={isEditMode}
                          compactType={null}
                          rowHeight={getGroupRowHeight(group)}
                        >
                          {visibleWidgets(group).map(({ widget }) => {
                            const handleClickWidget = function () {
                              dispatch(
                                setSettings({
                                  isShowHistory: false,
                                  isShowControlsHistory: false,
                                  isShowHistoryProperty: false,
                                })
                              );
                              if (!isFullScreen && widgetId !== widget.id) {
                                history(`/reports/${reportId}/${group.id}/${widget.id}`);
                              }
                            };

                            const hoverWidget = (id) => {
                              if (objectHoveredId === id) {
                                dispatch(setContext({ objectHoveredId: null }));
                              }
                              return objectHoveredId === id;
                            };

                            return (
                              <div
                                onClick={handleClickWidget}
                                style={{
                                  padding: '2px',
                                  filter: hoverWidget(widget.id) ? 'brightness(130%)' : '',
                                }}
                                data-grid={{
                                  ...getGridWidgets(group, widget.id),
                                }}
                                key={widget.id}
                              >
                                <WidgetWrap
                                  widgetType={widget.schemaTags[3]}
                                  object={widget}
                                  key={widget.id}
                                  {...widget}
                                  reportId={reportId}
                                  groupNames={[{ groupName: { equalTo: 'Settings' } }]}
                                  group={group}
                                  groupId={group.id}
                                  order={group.order}
                                  saveBoard={saveBoard}
                                />
                              </div>
                            );
                          })}
                        </ResponsiveGridLayout>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </ResponsiveGridLayout>
        </div>
      </Wrapper>
    </>
  );
});
export default Report;
