import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC, memo } from 'react';
import { msg } from '../../../../../constants/messages';
import useHandleCopy from '../../../../side-card/menu/handlers/useHandleCopy';
import st from './style.module.css';

export interface IListItem {
  text?: string;
  handleClose?: () => void;
}

const Buttons: FC<IListItem> = ({ text, handleClose }) => {
  const handleCopy = useHandleCopy();

  return (
    <Grid item>
      <Button
        color="inherit"
        className={st.button}
        onClick={() => {
          handleCopy({
            object: {
              name: '',
            },
            message: 'Value copied successfully',
            text,
          });
        }}
      >
        {msg.default.copy}
      </Button>
      <Button color="primary" onClick={handleClose}>
        {msg.default.ok}
      </Button>
    </Grid>
  );
};

export default memo(Buttons);
