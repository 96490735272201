import { gql } from '../../../__generated__';

const LINK_OBJECTS = gql(/* GraphQL */ `
  mutation linkObject($widgetId: UUID!, $objectId: UUID!) {
    createObjectsToObject(input: { objectsToObject: { object1Id: $widgetId, object2Id: $objectId } }) {
      objectsToObject {
        id
      }
    }
  }
`);

const UNLINK_OBJECTS = gql(/* GraphQL */ `
  mutation unlinkObject($linkId: UUID!) {
    deleteObjectsToObject(input: { id: $linkId }) {
      clientMutationId
    }
  }
`);

export { LINK_OBJECTS, UNLINK_OBJECTS };
