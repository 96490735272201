import EditIcon from '@mui/icons-material/Edit';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { msg } from '../../../constants/messages';

const AccessSection = ({ classes, item }) => {
  return (
    <>
      <ListSubheader color="primary" className={classes.listSubheader}>
        <Typography variant="subtitle2">{msg.sideCard.access}</Typography>
      </ListSubheader>

      <ListItem>
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText primary={`Editors: ${item.userGroupByEditorgroup.groupName}`} />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <PlayCircleOutlineIcon />
        </ListItemIcon>
        <ListItemText primary={`Users: ${item.userGroupByUsergroup.groupName}`} />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <RemoveRedEyeIcon />
        </ListItemIcon>
        <ListItemText primary={`Readers: ${item.userGroupByReadergroup.groupName}`} />
      </ListItem>
    </>
  );
};

export default AccessSection;
