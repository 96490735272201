import { gql, useLazyQuery } from '@apollo/client';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { Box, styled } from '@mui/system';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { msg } from '../../constants/messages';
import { isDashboard, isReport } from '../../utils/objectType';
import useCustomNavigate from '../hooks/useCustomNavigate';

const SEARCH = gql`
  query loadPure($filter: ObjectFilter) {
    objects(orderBy: NAME_ASC, first: 10, filter: $filter) {
      id
      name
      schemaTags
    }
  }
`;

const CustomAutocomplete = styled(Autocomplete, {
  shouldForwardProp: (prop) => prop !== 'isOpened',
})(({ isOpened, value }) => ({
  width: isOpened ? '300px' : '36px',
  height: '36px',
  transition: 'width ease .2s',
  overflow: 'hidden',
  borderRadius: '18px',

  '& .MuiInputBase-root': {
    height: '36px',
    padding: '0px',
    paddingLeft: '0',
    paddingRight: '8px',
    borderRadius: '18px',

    '& ': {
      paddingRight: '30px !important',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },

    '& .MuiIconButton-root': {
      // color: "rgba(0, 0, 0, 0.87)",
      width: '36px',
      height: '36px',
    },

    '& .MuiInputAdornment-root ': {
      marginRight: 0,
    },

    '.MuiAutocomplete-endAdornment': {
      display: isOpened && value.length > 0 ? 'visible' : 'none',
    },
  },
  '& .MuiAutocomplete-inputRoot': {
    flexWrap: 'nowrap',
    // backgroundColor: "white",
  },
}));

const SearchControl = () => {
  const { dashboardId, reportId } = useParams();
  const [searchField, setSearchField] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [open, setOpen] = useState(false);

  const [options, setOptions] = useState([]);
  const history = useCustomNavigate();

  const [loadObjectFromOther, { loading }] = useLazyQuery(SEARCH, {
    fetchPolicy: 'network-only',
  });

  const getIconByTags = (option) => {
    if (!option.id) return null;

    return <DashboardIcon sx={{ color: 'text.secondary' }} />;
  };

  const loadCb = async () => {
    const { data } = await loadObjectFromOther({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          objectsToObjectsByObject2IdConnection: {
            every: {
              object1Id: {
                notEqualTo: dashboardId,
              },
            },
          },
          schemaTags: {
            overlaps: ['report', 'dashboard'],
          },
          or: [
            {
              name: { includesInsensitive: searchField },
            },
            {
              stringId: { includesInsensitive: searchField },
            },
          ],
        },
      },
    });

    const dashboards = data?.objects?.filter((item) => isDashboard(item.schemaTags));
    const reports = data?.objects?.filter((item) => isReport(item.schemaTags));

    setOptions([...dashboards, ...reports]);
  };

  const handleSearchField = (event, value) => {
    setSearchField(value);
  };

  const isCurrent = (id) => {
    return id === dashboardId || id === reportId;
  };

  useDebounce(loadCb, 500, [searchField]);

  return (
    <>
      <Paper elevation={4} sx={{ borderRadius: '18px', marginLeft: '16px' }}>
        <CustomAutocomplete
          isOpened={isOpened}
          options={options}
          freeSolo
          value={searchField}
          filterOptions={(options) => options}
          filterSelectedOptions={false}
          noOptionsText="No data"
          loading={loading}
          componentsProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8],
                  },
                },
              ],
            },
          }}
          onBlur={() => {
            setIsOpened(true);
          }}
          onInputChange={(event, value, reason) => handleSearchField(event, value, reason)}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          groupBy={(option) => {
            if (!option?.schemaTags) {
              return;
            }
            if (isReport(option.schemaTags)) {
              return 'Reports';
            }

            if (isDashboard(option.schemaTags)) {
              return 'Dashboards';
            }
          }}
          getOptionDisabled={(option) => isCurrent(option.id)}
          getOptionLabel={(option) => {
            if (option?.name !== undefined) {
              return option.name;
            } else {
              return option;
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsOpened(!isOpened);
                      }}
                      data-test="search-fullscreen"
                      size="small"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={msg.appBar.search}
            />
          )}
          renderOption={(props, option) => (
            <>
              {!option.isDivider && (
                <li
                  {...props}
                  onClick={() => {
                    setSearchField('');
                    setOpen(false);
                    if (isDashboard(option.schemaTags)) {
                      history(`/boards/${option.id}`);
                    }

                    if (isReport(option.schemaTags)) {
                      history(`/reports/${option.id}`);
                    }
                  }}
                  data-test-search={option.name}
                  key={props.id}
                  style={{ height: '48px' }}
                >
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: 'flex', width: 44 }}>
                      {getIconByTags(option)}
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: 'calc(100% - 44px)',
                        wordWrap: 'break-word',
                      }}
                    >
                      <Box component="span">{option.name}</Box>
                    </Grid>
                  </Grid>
                </li>
              )}

              {option.isDivider && <Divider />}
            </>
          )}
        />
      </Paper>
    </>
  );
};

export default SearchControl;
