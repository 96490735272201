import { Map } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as ImgBoard } from '../../assets/board.svg';
import { isCollection } from '../../utils/objectType';
import BulletInBoard from '../icons/bulletInBoard';
import useUniversalMenu from '../side-card/menu/useUniversalMenu';
import useLongPress from '../useLongPress';
import useMoreMenu from '../useMoreMenu';

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: '40px', // to maintain distance   icon <---> text
  },
  //avatar
  item1ToHideOrShow: {
    //visibility: "inherit"
    display: 'block',
  },

  item1ToHide: {
    display: 'none',
  },

  // favorite + moremenu
  item2ToHideOrShow: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'opacity 0.2s linear',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      opacity: 1,
    },
  },

  link: {
    display: 'block',
    width: '100%',
    textDecoration: 'none',
    color: 'inherit',
    '* &:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
    },
  },
  item3ToHideOrShow: {
    display: 'none',
  },

  item3ToShow: {
    display: 'block',
  },

  itemToHover: {
    '&:hover $item1ToHideOrShow': {
      visibility: 'hidden',
      display: 'none',
    },

    '&:hover $item2ToHideOrShow': {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.2s linear',
    },

    '&:hover $item3ToHideOrShow': {
      visibility: 'visible',
      display: 'block',
    },
  },
}));

const CustomListItem = (props) => {
  const { item, itemsChecked, setItemsChecked } = props;
  const { dashboardId } = useParams();

  const classes = useStyles();

  const handleToggle = (value) => {
    const currentIndex = itemsChecked.indexOf(value);
    const newChecked = [...itemsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setItemsChecked(newChecked);
  };

  useEffect(() => {
    setItemsChecked([]);
  }, [dashboardId]);

  // to highlight current object
  const handleMouseOver = () => {
    // dispatch(setContext({ objectHoveredId: e.currentTarget.id }));
  };
  // to highlight current object
  const handleMouseLeave = () => {
    // dispatch(setContext({ objectHoveredId: null }));
  };

  const onLongPress = useLongPress();

  const { menuBasedOnType } = useUniversalMenu();

  const getIconByTags = (item) => {
    if (item?.type?.length) {
      if (item.type[0].value === 'minimap') {
        return <Map />;
      } else {
        return <BulletInBoard />;
      }
    }

    if (isCollection(item.schemaTags)) {
      return <ImgBoard />;
    }

    return <DashboardIcon />;
  };
  const { MoreMenu, openMoreMenu } = useMoreMenu();
  return (
    <>
      <MoreMenu items={() => menuBasedOnType(item)} />
      <ListItem
        data-list-item={item.name}
        key={item.key}
        id={item.id}
        button
        classes={{
          root: classes.itemToHover,
        }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onContextMenu={(event) => event.preventDefault()}
      >
        <ListItemIcon
          className={itemsChecked.indexOf(item.id) === -1 ? classes.item3ToHideOrShow : classes.item3ToShow}
        >
          <Checkbox
            onClick={(e) => {
              e.preventDefault();
              //            handleToggle(index);
              handleToggle(item.id);
            }}
            checked={itemsChecked.indexOf(item.id) !== -1}
            edge="start"
            tabIndex={-1}
            disableRipple
            style={{
              marginLeft: '-5px',
              marginRight: '9px',
              paddingRight: '14px',
            }}
            color="primary"
          />
        </ListItemIcon>

        <ListItemAvatar
          className={itemsChecked.indexOf(item.id) === -1 ? classes.item1ToHideOrShow : classes.item1ToHide}
        >
          <Avatar>{getIconByTags(item)}</Avatar>
        </ListItemAvatar>

        <Link to={item.link || ''} className={classes.link}>
          <ListItemText
            {...onLongPress(() => handleToggle(item.id))}
            primary={item.name}
            secondary={item.enabled ? <span>Enabled</span> : <span>Disabled</span>}
          />
        </Link>

        {itemsChecked.indexOf(item.id) === -1 && (
          <>
            <ListItemSecondaryAction className={classes.item2ToHideOrShow}>
              <IconButton edge="end" aria-label="favorite" style={{ display: 'none' }} size="large">
                <StarBorderIcon />
              </IconButton>
              <IconButton
                edge="end"
                data-list-item-test={`contextMenu_${item.name}`}
                aria-label="more"
                onClick={(e) => {
                  e.preventDefault();
                  openMoreMenu(e);
                }}
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </>
        )}
      </ListItem>
    </>
  );
}; //CustomListItem

export default CustomListItem;
