import { useApolloClient, useMutation } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import { loader } from 'graphql.macro';
import { useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../components/CommonModal';
import { msg } from '../constants/messages';
import { OBJECTS_QUERY } from '../graphql/queries';
import { updateProfile } from '../store/profileSlice';
import { useAuth } from '../utils/useAuth';
import CustomAutocomplete from './CustomAutocomplete';
import CustomSelect from './CustomSelect';

const themes = [
  // { id: 0, title: 'System', value: 'system' },
  { id: 1, title: 'Classic', value: 'classic' },
  { id: 2, title: 'Dark', value: 'dark' },
];

const UPDATE_OBJECT_PROPERTY = loader('../graphql/UpdateObjectWithProperties.graphql');

const EditProgramSettingsModal = (props) => {
  const { loadUser } = useAuth();
  const profile = useSelector((state) => state.profile);

  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);

  let defaultValues = {};

  const client = useApolloClient();
  const [listOfObjects, setListOfObjects] = useState([]);
  const [listOfReports, setListOfReports] = useState([]);
  const [modes] = useState([
    {
      title: 'Full screen',
      value: 'full',
    },
    {
      title: 'Card',
      value: 'card',
    },
  ]);

  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  useEffect(() => {
    const queryObjects = async () => {
      setLoading(true);
      try {
        const resultBoards = await client.query({
          query: OBJECTS_QUERY,
          variables: {
            filter: {
              schemaTags: {
                contains: ['dashboard', 'board'],
              },
            },
          },
          fetchPolicy: 'network-only',
        });

        const resultReports = await client.query({
          query: OBJECTS_QUERY,
          variables: {
            filter: {
              schemaTags: {
                contains: ['report', 'board'],
              },
            },
          },
          fetchPolicy: 'network-only',
        });

        setListOfObjects(
          resultBoards.data.objects.map((item) => {
            return { value: item.id, title: item.name };
          })
        );

        setListOfReports(
          resultReports.data.objects.map((item) => {
            return { value: item.id, title: item.name };
          })
        );

        setValues(defaultValues);
      } catch (err) {
        toast.error(`${err.toString()}`);
      } finally {
        setLoading(false);
      }
    };

    queryObjects().then(() => {});
  }, []);

  useEffect(() => {
    const queryProps = async () => {
      try {
        defaultValues['programDefaultDashboard'] = profile.programDefaultDashboard;
        defaultValues['programDefaultReport'] = profile.programDefaultReport;
        defaultValues['programDefaultMode'] = profile.programDefaultMode || 'card';
        defaultValues['programTheme'] = profile.programTheme || 'classic';
        defaultValues['programShowCoreTime'] = profile.programShowCoreTime || false;
        setValues(defaultValues);
      } catch (err) {
        toast.error(err.toString());
      } finally {
      }
    }; //queryProps

    queryProps().then(() => {});
  }, []);

  const dispatch = useDispatch();

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const handleUpdateWidgetProps = (valuesReady) => {
    toast
      .promise(
        updateProperty({
          variables: {
            input: {
              detailedObject: [
                {
                  objectId: profile.id,
                  keyedProperties: valuesReady,
                },
              ],
            },
          },
        }),
        {
          loading: 'Updating...',
          success: () => 'Updated',
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => {
        submit();
        loadUser().then(() => {});
      });
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title="Program settings"
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.editNotificationsSettingsModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                dispatch(
                  updateProfile({
                    programDefaultDashboard: values.programDefaultDashboard,
                    programDefaultReport: values.programDefaultReport,
                  })
                );

                handleUpdateWidgetProps([
                  {
                    propertyKey: 'programDefaultDashboard',
                    value: values.programDefaultDashboard,
                  },
                  {
                    propertyKey: 'programDefaultReport',
                    value: values.programDefaultReport,
                  },
                  {
                    propertyKey: 'programDefaultMode',
                    value: values.programDefaultMode,
                  },
                  {
                    propertyKey: 'programShowCoreTime',
                    value: values.programShowCoreTime,
                  },
                  {
                    propertyKey: 'programTheme',
                    value: values.programTheme,
                  },
                ]);
              }}
            >
              {msg.editNotificationsSettingsModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              {msg.editNotificationsSettingsModal.general}
            </Typography>
          </Grid>

          <Grid item>
            <CustomSelect
              name="programTheme"
              label={'Theme'}
              list={themes}
              value={values.programTheme}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              disabled={true}
              label={'Language'}
              list={[{ title: 'English', value: 'english' }]}
              value={'english'}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={isLoading}
              name="programDefaultDashboard"
              label={'Default board'}
              list={listOfObjects}
              value={values['programDefaultDashboard'] ?? ''}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={isLoading}
              name="programDefaultReport"
              label={'Default report'}
              list={listOfReports}
              value={values['programDefaultReport'] ?? ''}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={isLoading}
              name="programDefaultMode"
              label={'Default mode'}
              list={modes}
              value={values['programDefaultMode'] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <InputLabel htmlFor="programShowCoreTime">Show server time</InputLabel>
            <Switch
              name="programShowCoreTime"
              id="programShowCoreTime"
              checked={values['programShowCoreTime']}
              onChange={(e) => {
                setValues({ programShowCoreTime: e.target.checked });
              }}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditProgramSettingsModal);
