import { gql, useLazyQuery, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import TuneIcon from '@mui/icons-material/Tune';
import { ListItemButton, ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { msg } from '../../../constants/messages';
import { isMonitoringObject } from '../../../utils/objectType';
import EditDynamicProperty from '../../modals/EditDynamicProperty';
import EditWidgetAlarmsModal from '../../modals/EditWidgetAlarmsModel/EditWidgetAlarmsModal';
import SelectSchema from '../../modals/SelectSchema';
import useMoreMenu from '../../useMoreMenu';
import AccessSection from '../basic/AccessSection';
import DescriptionSection from '../basic/DescriptionSection';
import ServiceSection from '../basic/ServiceSection';
import useHandleCopy from '../menu/handlers/useHandleCopy';
import { ColumnContainer } from './ColumnContainer';
import ColumnModal from './MonitorTableColumnModal';

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: '15px',
    marginBottom: '10px',
  },
  itemToHideOrShow: {
    visibility: 'hidden',
    opacity: 1,
    transition: 'opacity 0.2s linear',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      opacity: 1,
    },
  },
  itemToHover: {
    '&:hover $itemToHideOrShow': {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.2s linear',
    },
  },
}));

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const GET_SCHEMA = gql`
  query getSchema($id: UUID!) {
    schema(id: $id) {
      id
      mTags
      name
      schemataByParentSchemaId(filter: { mTags: { equalTo: ["application", "monitor", "object monitoring item"] } }) {
        id
        name
        mTags
        schemaProperties(orderBy: [GROUP_NAME_ASC, DESCRIPTION_ASC]) {
          id
          key
          groupName
          property
          description
          type {
            name
          }
          valueSet
          valueRange
        }
      }
      schemaProperties(orderBy: [GROUP_NAME_ASC, DESCRIPTION_ASC]) {
        id
        key
        groupName
        property
        description
        type {
          name
        }
        valueSet
        valueRange
      }
    }
  }
`;

const MonitorTableGeneral = (props) => {
  const { item } = props;

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const classes = useStyles({
    listItem: {
      minHeight: '56px',
    },
  });

  const columns = item.objectProperties.find((item) => item.key === 'sourceColumns').value?.columns || [];
  const schema = () => {
    return item.objectProperties.find((item) => item.key === 'sourceSchemaId');
  };

  const schemaId = () => {
    return item.objectProperties.find((item) => item.key === 'sourceSchemaId').value;
  };

  const columnsProperty = () => {
    return item.objectProperties.find((item) => item.key === 'sourceColumns');
  };
  const valueProperty = () => {
    return item.objectProperties.find((item) => item.key === 'valueValue');
  };
  const settingsLinkedOnly = () => {
    return item.objectProperties.find((item) => item.key === 'sourceLinkedOnly');
  };

  const [loadSchema, { data }] = useLazyQuery(GET_SCHEMA, {
    variables: {
      id: schemaId(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const schemaName = () => {
    return data?.schema?.name || 'n/a';
  };

  const schemaRawProperties = () => {
    if (!data) return [];
    return data?.schema?.schemaProperties;
  };

  const schemaProperties = () => {
    if (!data) return [];

    const fragmentProperties = data.schema.schemataByParentSchemaId.map((item) => {
      const infoNameProperty = item.schemaProperties.find((prop) => prop.key === 'infoName');
      const stateValueProperty = item.schemaProperties.find((prop) => prop.key === 'stateValue');

      return {
        id: stateValueProperty.id,
        title: infoNameProperty ? infoNameProperty.value || item.name : null,
        value: stateValueProperty ? stateValueProperty.id : null,
      };
    });

    const ownProperties = data?.schema?.schemaProperties.map((item) => {
      return {
        value: item.id,
        title: `${item.groupName}/${item?.description || item.property}`,
        ...item,
      };
    });

    if (isMonitoringObject(data.schema.mTags)) {
      return fragmentProperties;
    }

    return ownProperties;
  };
  // group properties by groupName (array of properties -> array of groupNames)

  const { MoreMenu: ValueMenu, openMoreMenu: openValueMenu } = useMoreMenu();

  const { MoreMenu: ColumnMenu, openMoreMenu: openColumnMenu } = useMoreMenu();

  const handleCopy = useHandleCopy();

  const handleUpdateProperty = (id, value) => {
    return updateProperty({
      variables: {
        input: {
          id,
          patch: {
            value,
          },
        },
      },
    });
  };

  const handleDelete = (id) => {
    const conditionPropertyItem = columnsProperty();
    const oldValues = cloneDeep(conditionPropertyItem.value.columns);
    const conditionIndex = oldValues.findIndex((item) => item.property.id === id);

    oldValues.splice(conditionIndex, 1);

    oldValues.map((item, index) => ({ ...item, order: index }));

    let patch = {
      ...conditionPropertyItem.value,
      columns: oldValues.map((item, index) => ({ ...item, order: index })),
    };

    updateProperty({
      variables: {
        input: {
          id: columnsProperty().id,
          patch: {
            value: patch,
          },
        },
      },
    }).then(() => {
      if (patch.columns.length === 0) {
        return updateProperty({
          variables: {
            input: {
              id: valueProperty().id,
              patch: {
                value: [],
              },
            },
          },
        });
      }
    });
  };

  useEffect(() => {
    if (schemaId()) {
      loadSchema().then(() => {});
    }
  }, [item]);

  return (
    <div>
      <List>
        <ValueMenu
          items={[
            {
              icon: <EditIcon />,
              title: 'Edit',
              id: 'edit_prop',
              handleAction: (obj) => {
                switch (obj.type) {
                  case 'schema':
                    SelectSchema({
                      handleSave: handleUpdateProperty,
                      property: schema().value,
                      id: obj.propId,
                      value: obj.propValue,
                    })
                      .then(() =>
                        handleUpdateProperty(columnsProperty().id, {
                          columns: [],
                        })
                      )
                      .catch(() => {});
                    break;
                  case 'linked':
                    EditDynamicProperty({
                      handleSave: handleUpdateProperty,
                      property: settingsLinkedOnly(),
                      id: obj.propId,
                      value: obj.propValue,
                    })
                      .then()
                      .catch(() => {});
                    break;
                }
              },
              disabled: false,
            },
            {
              icon: <FilterNoneIcon />,
              title: msg.default.copy,
              id: 'copy',
              handleAction: (obj) => {
                handleCopy({
                  text: obj.propValue,
                  message: 'Value copied',
                });
              },
              disabled: false,
            },
          ]}
        />
        <ColumnMenu
          items={[
            {
              icon: <EditIcon />,
              title: 'Edit',
              id: 'edit_prop',
              handleAction: (obj) => {
                ColumnModal({
                  condition: obj.item,
                  isEdit: true,
                  conditionIndex: obj.propIndex,
                  conditionProperty: columnsProperty(),
                  properties: schemaProperties(),
                  schemaRawProperties: schemaRawProperties(),
                })
                  .then()
                  .catch(() => {});
              },
              disabled: false,
            },
            {
              icon: <NotificationsIcon />,
              title: 'Alarms',
              id: 'copy',
              handleAction: (obj) => {
                EditWidgetAlarmsModal({
                  handlePrepareData: (alarms) => {
                    const allProperty = columnsProperty().value;
                    const targetProperty = obj.item.property.id;

                    const newProp = allProperty.columns.map((item) => {
                      if (item.property.id === targetProperty) {
                        return {
                          ...item,
                          alerts: alarms,
                        };
                      }
                      return item;
                    });

                    return {
                      columns: newProp,
                    };
                  },
                  propertyId: columnsProperty().id,
                  value: obj.item.alerts,
                  isMonitor: true,
                })
                  .then(() => {})
                  .catch(() => {});
              },
            },
            {
              icon: <DeleteIcon />,
              title: 'Delete',
              id: 'delete',
              handleAction: (obj) => {
                handleDelete(obj.id);
              },
              disabled: false,
            },
          ]}
        />
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Settings</Typography>
        </ListSubheader>

        <ListItem classes={{ container: classes.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <SubtitlesIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Schema: {schemaName()}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  columns: columns,
                  propKey: schema().key,
                  propValue: schema().value,
                  propId: schema().id,
                  type: 'schema',
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: classes.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <TuneIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">Linking: {settingsLinkedOnly().value ? 'Linked only' : 'All'}</Typography>
            }
            onClick={() => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: settingsLinkedOnly().key,
                  propValue: settingsLinkedOnly().value,
                  propId: settingsLinkedOnly().id,
                  type: 'linked',
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Columns</Typography>
        </ListSubheader>

        <ColumnContainer
          schema={schema()}
          classes={classes}
          openColumnMenu={openColumnMenu}
          columns={columns}
          handleUpdate={(data) => {
            const prepareBeforeSend = data.map((item) => ({
              alerts: item.alerts,
              order: item.order,
              property: item.property,
            }));

            if (prepareBeforeSend.length === columns.length) {
              handleUpdateProperty(columnsProperty().id, {
                columns: prepareBeforeSend,
              }).then(() => {});
            }
          }}
        />

        <ListItemButton
          disabled={!data?.schema}
          style={{ height: '48px' }}
          onClick={() => {
            ColumnModal({
              isEdit: false,
              conditionProperty: columnsProperty(),
              properties: schemaProperties(),
              schemaRawProperties: schemaRawProperties(),
            })
              .then()
              .catch(() => {});
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Add column</Typography>} />
        </ListItemButton>

        <ServiceSection classes={classes} item={item} />

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </div>
  );
};

export default MonitorTableGeneral;
