import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

const sideBarWidth = 412;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: '0px', //sideBarWidth,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      border: 'none',
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: sideBarWidth,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    }, //500,
  },
}));

const ContentLayout = (props) => {
  const isFullScreen = useSelector((state) => state.settings.isFullScreen);
  const isEditMode = useSelector((state) => state.settings.isEditMode);
  const classes = useStyles();

  return (
    <>
      <main
        style={{
          userSelect: isEditMode ? 'none' : 'auto',
          WebkitUserSelect: isEditMode ? 'none' : 'auto',
          overflow: 'auto',
        }}
        className={clsx(classes.content, {
          [classes.contentShift]: !isFullScreen,
        })}
      >
        {props.children}
      </main>
    </>
  );
};

export default ContentLayout;
