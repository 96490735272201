import { gql, useLazyQuery } from '@apollo/client';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { orderBy } from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import { create } from 'react-modal-promise';
import CommonModal from '../../../../components/CommonModal';
import { msg } from '../../../../constants/messages';
import CustomAutocomplete from '../../../CustomAutocomplete';

export const GET_SCHEMA_PROPERTIES = gql`
  query schema($schemaId: UUID!) {
    schema(id: $schemaId) {
      schemaProperties {
        id
        description
        groupName
        groupDescription
        units
      }
    }
  }
`;

const ParameterPropertyModal = (props) => {
  const [properties, setProperties] = useState([]);

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), {
    property: null,
  });

  const [loadSchemaProperties] = useLazyQuery(GET_SCHEMA_PROPERTIES, {
    onCompleted: (e) => {
      setProperties(
        orderBy(
          e.schema.schemaProperties.map((item) => {
            return {
              value: item.id,
              title: item.description || item.property,
            };
          }),
          ['title'],
          ['asc']
        )
      );
    },
  });

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleLinkWidget = () => {
    props.save(values['property']);
    submit();
  };

  useEffect(() => {
    if (props.propertyId) {
      setValues({ property: props.propertyId });
    }
  }, [props.propertyId]);

  useEffect(() => {
    if (props.schemaId) {
      loadSchemaProperties({
        variables: {
          schemaId: props.schemaId,
        },
      }).then(() => {});
    }
  }, []);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title="Select property"
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleLinkWidget}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomAutocomplete
              disabled={!Boolean(props.schemaId)}
              value={values?.property?.value || props.propertyId}
              name="property"
              list={properties}
              fullWidth={true}
              label="Property name"
              clearFieldIcon={true}
              onChange={(e) => {
                setValues({ property: e.target.rawValue });
              }}
            ></CustomAutocomplete>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(ParameterPropertyModal);
