import ReactEChartsCore from 'echarts-for-react';
import { LineChart } from 'echarts/charts';
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import React, { useEffect, useState } from 'react';
import { highlightSelectedStyle } from '../../constants/constants';
import useColors from '../../utils/useColors';
import { useInterval } from '../../utils/useTimer';
import WidgetEditControls from '../WidgetEditControls';

const W_DiagramChart = React.memo((props) => {
  const { title, objectProperties, selected } = props;
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent,
    ToolboxComponent,
    DataZoomComponent,
  ]);

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;

  let valueInitial = [];

  if (getPropValue('valueChart1')) {
    if (Array.isArray(getPropValue('valueChart1'))) {
      valueInitial = getPropValue('valueChart1');
    }
  }

  const simulation = getPropValue('settingsSimulation');

  const type = getPropValue('settingsType');
  const { getColorBasedOnStyle } = useColors();
  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(getPropValue('settingsStyle'));

  // mapColorByName(getPropValue("styleChart1Color"))

  const rndRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  let simulatedData = [];
  let simulatedData2 = [];
  let simulatedData3 = [];

  for (let i = 0; i < 20; i++) {
    if (i <= 10) {
      simulatedData.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(60, 90),
      });
      simulatedData2.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(40, 70),
      });
      simulatedData3.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(10, 90),
      });
    } else {
      simulatedData.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(10, 30),
      });
      simulatedData2.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(50, 90),
      });
      simulatedData3.push({
        x: 1633096800000 + 3600 * 1000 * i,
        y: rndRange(10, 90),
      });
    }
  }

  const timerSimulation = useInterval();

  const [setValue] = useState(simulation ? simulatedData : valueInitial);

  const setData = () => {
    setValue((prevState) => [
      ...prevState.filter((index) => index !== 0),
      {
        x: prevState[prevState.length - 1].x + 3600 * 1000,
        y: rndRange(70, 90),
      },
    ]);
  };

  useEffect(() => {
    if (simulation) {
      timerSimulation.current = setInterval(setData, 2000);
    } else {
      return clearInterval(timerSimulation.current);
    }
  }, [simulation]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexBasis: 'auto',
          flexGrow: 1,
          position: 'relative',
          height: '100%',
          backgroundColor: bgColor,
          overflow: 'hidden',
          filter: selected ? highlightSelectedStyle : '',
          borderRadius: '2px',
        }}
      >
        {title && (
          <div
            style={{
              height: '36px',
              fontSize: '20px',
              backgroundColor: bgColor,
              color: fgColor,
              marginTop: '5px',
              alignSelf: 'center',
            }}
          >
            {title}
          </div>
        )}
        {type === 'pie' && (
          <ReactEChartsCore
            echarts={echarts}
            option={{
              tooltip: {
                trigger: 'item',
              },
              backgroundColor: bgColor,
              series: [
                {
                  type: 'pie',
                  color: ['#2780E3', '#181818', '#FF7518'],
                  label: {
                    show: false,
                  },
                  radius: '90%',
                  labelLine: {
                    show: false,
                  },
                  data: [
                    { value: 30, name: 'Label 1' },
                    { value: 33, name: 'Label 2' },
                    { value: 37, name: 'Label 3' },
                  ],
                  // emphasis: {
                  //   itemStyle: {
                  //     shadowBlur: 10,
                  //     shadowOffsetX: 0,
                  //     shadowColor: 'rgba(0, 0, 0, 0.5)'
                  //   }
                  // }
                },
              ],
            }}
            lazyUpdate={true}
            style={{ width: '100%', height: '100%' }}
          />
        )}

        {type === 'bar' && (
          <ReactEChartsCore
            echarts={echarts}
            option={{
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow',
                },
              },
              grid: {
                left: '4%',
                right: '4%',
                bottom: '4%',
                top: '4%',
                containLabel: true,
              },
              xAxis: {
                type: 'value',

                axisTick: {
                  lineStyle: { color: fgColor, opacity: 0.3 },
                  show: false,
                },
                splitLine: {
                  lineStyle: { color: fgColor, opacity: 0.3 },
                  show: false,
                },
                axisLine: {
                  lineStyle: { color: fgColor, opacity: 0.3 },
                  show: false,
                },
              },
              yAxis: {
                axisTick: {
                  lineStyle: { color: fgColor, opacity: 0.3 },
                  show: true,
                },
                splitLine: {
                  lineStyle: { color: fgColor, opacity: 0.3 },
                  show: true,
                },
                axisLine: {
                  lineStyle: { color: fgColor, opacity: 0.3 },
                  show: true,
                },
                type: 'category',
                data: ['Label 1', 'Label 2', 'Label 3'],
                axisLabel: {
                  show: false,
                },
              },
              series: [
                {
                  type: 'bar',
                  label: {
                    show: false,
                  },
                  emphasis: {
                    focus: 'series',
                  },
                  data: [
                    {
                      value: 30,
                      itemStyle: {
                        color: '#2780E3',
                      },
                    },
                    {
                      value: 33,
                      itemStyle: {
                        color: '#181818',
                      },
                    },
                    {
                      value: 37,
                      itemStyle: {
                        color: '#FF7518',
                      },
                    },
                  ],
                },
              ],
            }}
            lazyUpdate={true}
            style={{ width: '100%', height: '100%' }}
          />
        )}

        <WidgetEditControls {...props} />
      </div>
    </>
  );
});

export default W_DiagramChart;
