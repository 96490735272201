import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { loader } from 'graphql.macro';
import { useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import CommonModal from '../../components/CommonModal';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import { msg } from '../../constants/messages';
import { DEVICES_LINKED_QUERY } from '../../graphql/queries';
import { isMonitoringItem, isMonitoringObject } from '../../utils/objectType';
import CustomInput from '../CustomInput';
import CustomSelect from '../CustomSelect';
import FormField from '../FormField';
const styles = (theme) => ({
  alertLabel: {
    color: theme.palette.gray1,
  },
});
const UPDATE_OBJECT_PROPERTY = loader('../../graphql/UpdateObjectWithProperties.graphql');

export const OBJECT_PROPS_QUERY_WITH_FRAGMENTS = gql`
  query listProperties($deviceId: UUID!) {
    object(id: $deviceId) {
      schemaTags
      objectsToObjectsByObject1Id(filter: { forced: { equalTo: 1 } }) {
        object2 {
          id
          name
          schemaTags
          objectProperties(orderBy: [GROUP_NAME_ASC, PROPERTY_ASC]) {
            id
            groupName
            property
            value
            key
            linkedPropertyId
            spec {
              description
              property
              propertyI18N
            }
          }
        }
      }
      objectProperties(orderBy: [GROUP_NAME_ASC, PROPERTY_ASC]) {
        id
        groupName
        property
        key
        linkedPropertyId
        value
        spec {
          description
          property
          propertyI18N
        }
      }
    }
  }
`;

const EditTimerSourceModal = (props) => {
  const client = useApolloClient();
  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);

  const useStyles = makeStyles((theme) => styles(theme));

  const classes = useStyles();

  const [listOfSources, setListOfSources] = useState([]);
  const [listOfProps, setListOfProps] = useState([]);

  let defaultValues = {};

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  const setProperties = (result) => {
    if (isMonitoringObject(result.data.object.schemaTags)) {
      const monitoringProperties = result.data.object.objectsToObjectsByObject1Id
        .filter((item) => isMonitoringItem(item.object2.schemaTags))
        .map((item) => ({
          title: item.object2.objectProperties.find((prop) => prop.key === 'infoName').value,
          value: item.object2.objectProperties.find((prop) => prop.key === 'stateValue').id,
        }))
        .flat(3);

      setListOfProps([...monitoringProperties]);
    } else {
      const ownProperties = result.data.object.objectProperties.map((item) => ({
        value: item.id,
        title: `${item.groupName} / ${item.spec.description || item.spec.property}`,
      }));

      const fragmentProperties = result.data.object.objectsToObjectsByObject1Id
        .map((item) =>
          item.object2.objectProperties.map((property) => ({
            ...property,
            fragmentName: item.object2.name,
          }))
        )
        .flat(3)
        .map((item) => ({
          value: item.id,
          title: `${item.fragmentName} / ${item.groupName} / ${item.spec.description || item.spec.property}`,
        }));

      setListOfProps([...ownProperties, ...fragmentProperties]);
    }
  };

  // get props of device on device selection
  const handleDeviceChange = async (e) => {
    if (e.target.value === '') {
      setListOfProps([]);
      return;
    }

    try {
      const result = await client.query({
        query: OBJECT_PROPS_QUERY_WITH_FRAGMENTS,
        variables: { deviceId: e.target.value },
        fetchPolicy: 'network-only',
      });

      setProperties(result);
    } catch (err) {
      toast.error(err.toString());
    }
  };
  const getPropertyByKey = (key) => {
    return props.object.objectProperties.find((item) => item.key === key);
  };
  const condition = getPropertyByKey('settingsCondition');
  const settingsAggregation = getPropertyByKey('settingsAggregation');

  useEffect(() => {
    const queryProps = async () => {
      try {
        let result;
        result = await client.query({
          query: DEVICES_LINKED_QUERY,
          variables: { widgetId: props.object.id },
          fetchPolicy: 'network-only',
        });

        setListOfSources(
          result.data.objects.map((item) => {
            return { value: item.id, title: item.name };
          })
        );

        const getTargetObjectId = () => {
          const t = props.object.objectProperties.find((item) => item.key === 'settingsSource');

          return t;
        };

        if (getTargetObjectId().value.objectId) {
          const result_linked_property = await client.query({
            query: OBJECT_PROPS_QUERY_WITH_FRAGMENTS,
            variables: { deviceId: getTargetObjectId().value.objectId },
            fetchPolicy: 'network-only',
          });

          setProperties(result_linked_property);

          defaultValues['source'] = getTargetObjectId().value.objectId;
          defaultValues['property'] = getTargetObjectId().value.propertyId;
          defaultValues['settingsAggregation'] = settingsAggregation.value;
          defaultValues['startOperator'] = condition.value.start.operator;
          defaultValues['startValue'] = condition.value.start.value;
          defaultValues['endOperator'] = condition.value.end.operator;
          defaultValues['endValue'] = condition.value.end.value;
        } else {
          defaultValues['settingsAggregation'] = '';
          defaultValues['source'] = '';
          defaultValues['property'] = '';
          defaultValues['startOperator'] = '';
          defaultValues['startValue'] = '';
          defaultValues['endOperator'] = '';
          defaultValues['endValue'] = '';
        }

        setValues(defaultValues);
      } catch (err) {
        toast.error(err.toString());
      }
    };

    queryProps();
  }, []);

  const handleUpdate = async () => {
    const valuesReady = [
      {
        propertyKey: 'settingsAggregation',
        value: values.settingsAggregation,
      },
      {
        propertyKey: 'settingsCondition',
        value: {
          start: {
            value: values.startValue,
            operator: values.startOperator,
          },
          end: {
            value: values.endValue,
            operator: values.endOperator,
          },
        },
      },
      {
        propertyKey: 'settingsSource',
        value: {
          objectId: values.source,
          propertyId: values.property,
        },
      },
    ];

    toast
      .promise(
        updateProperty({
          variables: {
            input: {
              detailedObject: [
                {
                  objectId: props.object.id,
                  keyedProperties: valuesReady,
                },
              ],
            },
          },
        }),
        {
          loading: 'Updating source...',
          success: () => `Updated`,
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => {
        submit();
      });
    // try {
    //   const result_update = await client.mutate({
    //     mutation: UPDATE_PROP_MUTATION,
    //     variables: {
    //       propId: props.propId,
    //       linkedPropId: values["property"],
    //     },
    //   });
    //
    //   toast.success(msg.editWidgetModal.updated, {
    //     position: "bottom-center",
    //   });
    // } catch (err) {
    //   toast.error(err.toString(), {
    //     position: "bottom-center",
    //   });
    // }
  }; //handleUpdate

  const submit = () => props.onResolve();
  const reject = () => props.onReject();
  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (name === 'source') handleDeviceChange(e);

    if (checked) value = checked;

    setValues({ [name]: value });
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.editWidgetSourceModal.editSource}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.editWidgetSourceModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleUpdate}>
              {msg.editWidgetSourceModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomAutocomplete
              name="source"
              label={msg.editWidgetSourceModal.source}
              list={listOfSources}
              value={values['source'] ?? null}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item>
            <CustomAutocomplete
              name="property"
              label={msg.editWidgetSourceModal.property}
              list={listOfProps}
              value={values['property'] ?? null}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary" className={classes.alertLabel}>
              Start condition
            </Typography>
          </Grid>

          <Grid item container>
            <Grid item xs={6} style={{ paddingRight: '10px' }}>
              <CustomSelect
                clearFieldIcon={true}
                name="startOperator"
                list={[
                  { value: '>', title: '>' },
                  { value: '<', title: '<' },
                  { value: '=', title: '=' },
                  { value: '!=', title: '!=' },
                  {
                    value: 'contains',
                    title: msg.editWidgetAlarmsModal.contains,
                  },
                ]}
                value={values['startOperator'] ?? ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item container xs={6} alignContent="flex-end">
              <CustomInput
                name="startValue"
                label="&nbsp;"
                clearFieldIcon={true}
                value={values['startValue'] ?? ''}
                onChange={handleInputChange}
                type="text"
              />
            </Grid>
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary" className={classes.alertLabel}>
              Stop condition
            </Typography>
          </Grid>

          <Grid item container>
            <Grid item xs={6} style={{ paddingRight: '10px' }}>
              <CustomSelect
                clearFieldIcon={true}
                name="endOperator"
                list={[
                  { value: '>', title: '>' },
                  { value: '<', title: '<' },
                  { value: '=', title: '=' },
                  { value: '!=', title: '!=' },
                  {
                    value: 'contains',
                    title: msg.editWidgetAlarmsModal.contains,
                  },
                ]}
                value={values['endOperator'] ?? ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item container xs={6} alignContent="flex-end">
              <CustomInput
                name="endValue"
                label="&nbsp;"
                clearFieldIcon={true}
                value={values['endValue'] ?? ''}
                onChange={handleInputChange}
                type="text"
              />
            </Grid>
          </Grid>
          <Grid item>
            <FormField
              values={{
                [settingsAggregation.spec.key]: values.settingsAggregation,
              }}
              field={settingsAggregation.spec}
              handleInputChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditTimerSourceModal);
