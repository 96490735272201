import { gql } from '../../../../__generated__';

const GET_CONTROLS_LIST = gql(/* GraphQL */ `
  query getControlsList($id: UUID!) {
    object(id: $id) {
      id
      schema {
        id
        schemaControls {
          id
          rpc
          argument
          description
        }
      }
    }
  }
`);

export { GET_CONTROLS_LIST };
