import { Button } from '@mui/material';
import { create } from 'react-modal-promise';
import { msg } from '../../constants/messages';
import ConfirmationModal from './ConfirmationModal';

const ConfirmRpcExecution = (props) => {
  const submit = () => props.onResolve();

  const handleClose = () => submit();

  return (
    <>
      <ConfirmationModal
        modalOpen={props.isOpen}
        title={msg.deleteGroupModal.deleteGroup}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                props.handleSave();
                submit();
              }}
            >
              Confirm
            </Button>
          </>
        }
      >
        {props.text}
      </ConfirmationModal>
    </>
  );
};

export default create(ConfirmRpcExecution);
