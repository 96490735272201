import { Box, circularProgressClasses } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const ProgressLoader = (props) => {
  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: '#2780E34F',
          }}
          size={props.size}
          thickness={4}
          value={100}
        />
        <CircularProgress
          color={props.color}
          variant={props.variant}
          sx={{
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            right: 0,
            margin: 'auto',
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={props.size}
          thickness={4}
          value={props.progress}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant={'subtitle2'} color={props.color}>
            {`${Math.round(props.progress)}%`}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ProgressLoader;
