import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import React from 'react';

const Spinner = React.memo(() => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ margin: 'auto', minHeight: '100vh' }}
    >
      <CircularProgress size={80} />
    </Grid>
  );
});

export default Spinner;
