import { gql, useLazyQuery, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import TuneIcon from '@mui/icons-material/Tune';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { msg } from '../../../constants/messages';
import { setSettings } from '../../../store/settingsSlice';
import { hideNull } from '../../../utils/hideNull';
import FunctionIcon from '../../icons/functionIcon';
import ConditionModal from '../../modals/ConditionModal';
import EditDynamicProperty from '../../modals/EditDynamicProperty';
import SelectSchema from '../../modals/SelectSchema';
import useMoreMenu from '../../useMoreMenu';
import AccessSection from '../basic/AccessSection';
import DescriptionSection from '../basic/DescriptionSection';
import ServiceSection from '../basic/ServiceSection';
import HistoryProperty from '../history/HistoryProperty';
import useHandleCopy from '../menu/handlers/useHandleCopy';
import ParameterPeriodCalculator from './ParameterPeriodCalculator';

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: '15px',
    marginBottom: '10px',
  },
  itemToHideOrShow: {
    visibility: 'hidden',
    opacity: 1,
    transition: 'opacity 0.2s linear',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      opacity: 1,
    },
  },
  itemToHover: {
    '&:hover $itemToHideOrShow': {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.2s linear',
    },
  },
}));

const GET_SCHEMA = gql`
  query getSchema($id: UUID!) {
    schema(id: $id) {
      id
      name
      schemaProperties(sort: { sortBy: { field: GROUP_NAME } }) {
        id
        key
        groupName
        property
        description
        type {
          name
        }
        valueSet
        valueRange
      }
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const TabGeneralCalculatorStatistic = (props) => {
  const { item } = props;
  const dispatch = useDispatch();

  const handleCopy = useHandleCopy();
  const [idProperty, setIdProperty] = useState(null);
  const isShowHistoryProperty = useSelector((state) => state.settings.isShowHistoryProperty);

  const conditions = () => {
    return item.objectProperties.find((item) => item.key === 'settingsFilter').value.conditions;
  };

  const conditionProperty = () => {
    return item.objectProperties.find((item) => item.key === 'settingsFilter');
  };

  const schemaId = () => {
    return item.objectProperties.find((item) => item.key === 'settingsSchemaid').value;
  };

  const settingsLinkedOnly = () => {
    return item.objectProperties.find((item) => item.key === 'settingsLinkedOnly');
  };

  const settingsSchemaId = () => {
    return item.objectProperties.find((item) => item.key === 'settingsSchemaid');
  };

  const settingsPeriod = () => {
    return item.objectProperties.find((item) => item.key === 'settingsPeriod');
  };

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const [loadSchema, { data }] = useLazyQuery(GET_SCHEMA, {
    variables: {
      id: schemaId(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const schemaName = () => {
    return data?.schema?.name || 'n/a';
  };

  const handleDelete = (conditionIndex) => {
    const conditionPropertyItem = conditionProperty();
    const oldValues = cloneDeep(conditionPropertyItem.value.conditions);

    oldValues.splice(conditionIndex, 1);

    let patch = {
      ...conditionPropertyItem.value,
      filtering: Boolean(oldValues.length),
      conditions: [...oldValues],
    };

    updateProperty({
      variables: {
        input: {
          id: conditionPropertyItem.id,
          patch: {
            value: patch,
          },
        },
      },
    }).then(() => {});
  };

  const handleUpdateProperty = (id, value) => {
    return updateProperty({
      variables: {
        input: {
          id,
          patch: {
            value,
          },
        },
      },
    });
  };

  const schemaProperties = () => {
    if (!data) return [];
    return data?.schema?.schemaProperties.map((item) => {
      return {
        value: `${item.groupName}/${item.property}`,
        title: `${item.groupName}/${item?.description || item.property}`,
        ...item,
      };
    });
  };

  const schemaRawProperties = () => {
    if (!data) return [];
    return data?.schema?.schemaProperties;
  };
  const classes = useStyles({
    listItem: {
      minHeight: '56px',
    },
  });

  const { MoreMenu: ValueMenu, openMoreMenu: openValueMenu } = useMoreMenu();

  const getValue = (value) => {
    if (value === null) return 'n/a';
    return value;
  };

  const valueProperty = () => {
    return item.objectProperties.find((item) => item.key === 'valueValue');
  };

  useEffect(() => {
    if (schemaId()) {
      loadSchema().then(() => {});
    }
  }, [item]);

  return (
    <>
      {isShowHistoryProperty && <HistoryProperty id={idProperty} object={props.item} />}
      <ValueMenu
        items={[
          {
            icon: <EditIcon />,
            title: 'Edit',
            id: 'edit_prop',
            handleAction: (obj) => {
              switch (obj.type) {
                case 'schema':
                  SelectSchema({
                    handleSave: handleUpdateProperty,
                    property: settingsSchemaId(),
                    id: obj.propId,
                    value: obj.propValue,
                  })
                    .then()
                    .catch(() => {});
                  break;
                case 'linked':
                  EditDynamicProperty({
                    handleSave: handleUpdateProperty,
                    property: settingsLinkedOnly(),
                    id: obj.propId,
                    value: obj.propValue,
                  })
                    .then()
                    .catch(() => {});
                  break;
              }
            },
            disabled: false,
          },
          {
            icon: <HistoryIcon />,
            title: 'Show history',
            id: 'show_history',
            handleAction: (obj) => {
              setIdProperty(obj.propId);
              dispatch(setSettings({ isShowHistoryProperty: true }));
            },
            disabled: false,
          },
          {
            icon: <FilterNoneIcon />,
            title: msg.default.copy,
            id: 'copy',
            handleAction: (obj) => {
              handleCopy({
                text: JSON.stringify(obj.propValue),
                message: 'Value copied',
              });
            },
            disabled: false,
          },
        ]}
      />

      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>

        <ListItem style={{ height: '48px' }} classes={{ container: classes.itemToHover }} button onClick={() => {}}>
          <ListItemIcon>
            <FunctionIcon />
          </ListItemIcon>
          <ListItemText
            onClick={() => {
              handleCopy({
                text: item.id,
                message: 'Copied',
              });
            }}
            primary={
              <Typography variant="body1">
                {getValue(item.objectProperties.find((item) => item.key === 'valueValue').value)}
              </Typography>
            }
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: valueProperty().key,
                  propValue: JSON.stringify(valueProperty().value),
                  propId: valueProperty().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Settings</Typography>
        </ListSubheader>

        <ListItem classes={{ container: classes.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <SubtitlesIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{schemaName()}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: settingsSchemaId().key,
                  propValue: settingsSchemaId().value,
                  propId: settingsSchemaId().id,
                  type: 'schema',
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: classes.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <TuneIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">{hideNull(settingsLinkedOnly().value) ? 'Linked only' : 'All'}</Typography>
            }
            onClick={() => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: settingsLinkedOnly().key,
                  propValue: settingsLinkedOnly().value,
                  propId: settingsLinkedOnly().id,
                  type: 'linked',
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: '48px', paddingRight: '96px' }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>
            <EventIcon></EventIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">{settingsPeriod().value.title}</Typography>}
            onClick={() => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              size="small"
              onClick={() => {
                ParameterPeriodCalculator({
                  selected: settingsPeriod(),
                  save: (period) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsPeriod().id,
                          patch: {
                            value: period,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Filter</Typography>
        </ListSubheader>

        {conditions().map((item, index) => {
          return (
            <ListItem
              classes={{
                container: classes.itemToHover,
              }}
              style={{ height: '48px', paddingRight: '96px' }}
              button
              onClick={() => {}}
              key={index}
            >
              <ListItemIcon />
              <ListItemText
                primary={<Typography variant="body1">{`${item.property} ${item.operator} ${item.value}`}</Typography>}
                onClick={() => {}}
              />
              <ListItemSecondaryAction className={classes.itemToHideOrShow}>
                <IconButton
                  size="small"
                  onClick={() => {
                    ConditionModal({
                      condition: item,
                      isEdit: true,
                      conditionIndex: index,
                      conditionProperty: conditionProperty(),
                      properties: schemaProperties(),
                      schemaRawProperties: schemaRawProperties(),
                    })
                      .then()
                      .catch(() => {});
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleDelete(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <ListItem
          style={{ height: '48px' }}
          button
          onClick={() => {
            ConditionModal({
              isEdit: false,
              conditionProperty: conditionProperty(),
              properties: schemaProperties(),
              schemaRawProperties: schemaRawProperties(),
            })
              .then()
              .catch(() => {});
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Add condition</Typography>} />
        </ListItem>

        <ServiceSection classes={classes} item={item} />

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default TabGeneralCalculatorStatistic;
