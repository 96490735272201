import EditWidgetSourceModal from '../../EditWidgetSourceModal';
import EditWidgetAlarmsModal from '../../modals/EditWidgetAlarmsModel/EditWidgetAlarmsModal';
import useMenu from '../menu/useMenu';

export default function useValueMenu({ property, object, linkedObjects }) {
  const items = useMenu();

  return [
    items.genMenuEditItem({
      title: 'Edit source',
      handleAction: () => {
        EditWidgetSourceModal({
          widgetId: object.id,
          propId: property.id,
          propKey: property.key,
          schemaTags: object.schemaTags,
        })
          .then()
          .catch(() => {});
      },
    }),
    items.genMenuShowHistory({
      disabled: true,
    }),
    items.getMenuAlarms({
      handleAction: () => {
        const properties = object.objectProperties.filter(
          (item) => item.key === 'alarmsAlerts' || item.key === 'alarmsAlertsOptions'
        );
        EditWidgetAlarmsModal({
          object,
          properties,
          linkedObjects,
        })
          .then()
          .catch(() => {});
      },
    }),
  ];
}
