interface TDashboardForm {
  generalTitle: string;
  generalBgColor: string;
  generalTitleStyle: string;
  generalBackgroundImageUid: string | null;
  generalBackgroundImageName: string | null;
}

export const DASHBOARD_DEFAULT_FORM: TDashboardForm = {
  generalTitle: null,
  generalBgColor: '#333333',
  generalTitleStyle: 'dark',
  generalBackgroundImageUid: null,
  generalBackgroundImageName: null,
};

export const TITLE_STYLE = [
  {
    value: 'dark',
    title: 'Dark',
  },
  {
    value: 'light',
    title: 'Light',
  },
];

export const BOARD_BG_OPTIONS = [
  {
    value: '#ffffff',
    title: 'White',
  },
  {
    value: '#616161',
    title: 'Grey',
  },
  {
    value: '#333333',
    title: 'Dark grey',
  },
  {
    value: '#000000',
    title: 'Black',
  },
];
