import { format } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';

const downloadCSV = (object, device, valueOuter) => {
  const value = valueOuter || object.objectProperties.find((item) => item.key === 'valueValue')?.value || [];

  const headers = object.objectProperties.find((item) => item.key === 'settingsColumns')?.value || [];

  if (value.length === 0) {
    return;
  }

  let prepareForExport = [];

  value.forEach((item) => {
    const localItem = {
      'Date time': format(new Date(item?.timestamp || item?.time), 'yyyy-MM-dd HH:mm'),
    };

    item.data.forEach((itemValueInRow) => {
      const name = headers.columns.find((item) => item.value.value === itemValueInRow.propertyId);
      if (!name || !name?.value?.title) {
        localItem[itemValueInRow.propertyId] = itemValueInRow.value;
      } else {
        localItem[`${name?.alias || name.value.title} (${name.mode})`] = itemValueInRow.value;
      }
    });

    prepareForExport.push(localItem);
  });

  const csvExporter = new ExportToCsv({
    title: device.value ? device.title : object.name,
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    useTextFile: false,
    filename: device.value ? device.title : object.name,
    useBom: true,
    useKeysAsHeaders: true,
  });

  csvExporter.generateCsv(prepareForExport.reverse());
};

export { downloadCSV };
