import CloseIcon from '@mui/icons-material/Close';
import { FormControl } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { castToType } from '../utils/misc.js';

const CustomSelectColor = (props) => {
  const theme = useTheme();

  const useStyles = makeStyles(() => ({
    icon: {
      right: 30,
    },
    selectSelect: {
      paddingLeft: '0px',
    },
    colorDefault: {
      backgroundColor: theme.palette.wBlue,
      color: theme.palette.wWhite,
      '&:focus': {
        backgroundColor: theme.palette.wBlue,
        color: theme.palette.wWhite,
      },
      '&:hover': {
        backgroundColor: '#3790F3 !important',
        color: theme.palette.wWhite,
      },
    },
    colorRed: {
      backgroundColor: theme.palette.wRed,
      color: theme.palette.wWhite,
      '&:focus': {
        backgroundColor: theme.palette.wRed,
        color: theme.palette.wWhite,
      },
      '&:hover': {
        backgroundColor: '#E51010 !important',
        color: theme.palette.wWhite,
      },
    },

    colorBlue: {
      backgroundColor: theme.palette.wBlue,
      color: theme.palette.wWhite,
      '&:focus': {
        backgroundColor: theme.palette.wBlue,
        color: theme.palette.wWhite,
      },
      '&:hover': {
        backgroundColor: '#3790F3 !important',
        color: theme.palette.wWhite,
      },
    },

    colorGreen: {
      backgroundColor: theme.palette.wGreen,
      color: theme.palette.wWhite,
      '&:focus': {
        backgroundColor: theme.palette.wGreen,
        color: theme.palette.wWhite,
      },
      '&:hover': {
        backgroundColor: '#3E8D42 !important',
        color: theme.palette.wWhite,
      },
    },

    colorYellow: {
      backgroundColor: theme.palette.wYellow,
      color: theme.palette.wBlack,
      '&:focus': {
        backgroundColor: theme.palette.wYellow,
        color: theme.palette.wBlack,
      },
      '&:hover': {
        backgroundColor: '#FFE610 !important',
        color: theme.palette.wBlack,
      },
    },

    colorOrange: {
      backgroundColor: theme.palette.wOrange,
      color: theme.palette.wWhite,
      '&:focus': {
        backgroundColor: theme.palette.wOrange,
        color: theme.palette.wWhite,
      },
      '&:hover': {
        backgroundColor: '#FF8528 !important',
        color: theme.palette.wWhite,
      },
    },

    colorGray3: {
      backgroundColor: theme.palette.gray3,
      color: theme.palette.wWhite,
      '&:focus': {
        backgroundColor: theme.palette.gray3,
        color: theme.palette.wWhite,
      },
      '&:hover': {
        backgroundColor: '#555555 !important',
        color: theme.palette.wWhite,
      },
    },
    colorGrey: {
      backgroundColor: theme.palette.gray4,
      color: theme.palette.wWhite,
      '&:focus': {
        backgroundColor: theme.palette.gray4,
        color: theme.palette.wWhite,
      },
      '&:hover': {
        backgroundColor: '#616161 !important',
        color: theme.palette.wWhite,
      },
    },
    colorYellowGreen: {
      backgroundColor: '#C0CA33',
      color: theme.palette.wWhite,
      '&:focus': {
        backgroundColor: '#C0CA33',
        color: theme.palette.wWhite,
      },
      '&:hover': {
        backgroundColor: '#C0CA33 !important',
        color: theme.palette.wWhite,
      },
    },
    colorDeepRed: {
      backgroundColor: '#920000',
      color: theme.palette.wWhite,
      '&:focus': {
        backgroundColor: '#920000',
        color: theme.palette.wWhite,
      },
      '&:hover': {
        backgroundColor: '#920000 !important',
        color: theme.palette.wWhite,
      },
    },
  }));

  const { name, label, colors } = props;

  const classes = useStyles();

  const handleInputChangeCasted = (event, type) => {
    let { name, value, checked } = event.target;
    const e = {
      target: { name: name, value: castToType(value, type), checked: checked },
    };
    props.onChange(e);
  };

  const colorsMap = {
    red: { color: theme.palette.wRed, class: classes.colorRed, title: 'red' },
    green: {
      color: theme.palette.wGreen,
      class: classes.colorGreen,
      title: 'green',
    },
    orange: {
      color: theme.palette.wOrange,
      class: classes.colorOrange,
      title: 'orange',
    },
    yellow: {
      color: theme.palette.wYellow,
      class: classes.colorYellow,
      title: 'yellow',
    },
    blue: {
      color: theme.palette.wBlue,
      class: classes.colorBlue,
      title: 'blue',
    },
    black: {
      color: theme.palette.wBlack,
      class: classes.colorBlack,
      title: 'black',
    },
    white: {
      color: theme.palette.wWhite,
      class: classes.colorWhite,
      title: 'white',
    },
    transparent: {
      color: theme.palette.wTransparent,
      class: classes.colorTransparent,
      title: 'transparent',
    },
    default: {
      color: theme.palette.wBlue,
      class: classes.colorDefault,
      title: 'default',
    },
    gray3: {
      color: theme.palette.gray3,
      class: classes.colorGray3,
      title: 'gray',
    },
    gray4: {
      color: theme.palette.gray4,
      class: classes.colorGrey,
      title: 'grey',
    },
    yellowGreen: {
      color: '#C0CA33',
      class: classes.colorYellowGreen,
      title: 'yellow green',
    },
    deepRed: {
      color: '#920000',
      class: classes.colorDeepRed,
      title: 'deep red',
    },
  };

  return (
    <FormControl fullWidth variant={'standard'}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        name={name}
        id={`${name}-label`}
        value={props.value}
        labelId={`${name}-label`}
        data-test-select-color={props['data-test'] || props.name}
        disabled={Boolean(props.disabled)}
        onChange={(e) => {
          handleInputChangeCasted(e, props.propType);
        }}
        {...(props.clearFieldIcon && props.value
          ? { classes: { icon: classes.icon, select: classes.selectSelect } }
          : {})}
        endAdornment={
          props.clearFieldIcon &&
          props.value && (
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="reset"
                onClick={() => props.onChange({ target: { name: props.name, value: '' } })}
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          )
        }
        fullWidth
        style={{
          backgroundColor: colorsMap[props.value]?.color,
          color: props.value === 'yellow' ? theme.palette.wBlack : theme.palette.wWhite,
        }}
      >
        {colors.map((color) => {
          return (
            <MenuItem value={color} key={color} classes={{ root: colorsMap[color]?.class }}>
              {colorsMap[color]?.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CustomSelectColor;
