import { gql } from '../../../__generated__';

const UPDATE_PROPERTIES = gql(/* GraphQL */ `
  mutation UpdateObjectWithProperties($input: UpdateObjectWithPropertiesInput!) {
    updateObjectWithProperties(input: $input) {
      clientMutationId
    }
  }
`);

export { UPDATE_PROPERTIES };
