import { useApolloClient } from '@apollo/client';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { highlightSelectedStyle } from '../../constants/constants';
import { msg } from '../../constants/messages';
import { GET_DATA_SUBSCRIPTION } from '../../graphql/queries';
import useColors from '../../utils/useColors';
import useMedia from '../../utils/useMedia';

const useStyles = makeStyles(() => ({
  root: {},
}));

const W_Timer = (props) => {
  const { id, objectProperties, selected } = props;
  const { getImageById } = useMedia();
  const { getColorBasedOnStyle } = useColors();
  const theme = useTheme();
  const client = useApolloClient();
  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;
  const size = getPropValue('settingsSize');

  let iconId = getPropValue('settingsIcon');
  // let iconId = getPropValue("settingsIcon");
  // let valueCurrentIcon = getPropValue("valueCurrentIcon");
  let statusAlarmInitial = getPropValue('statusAlarm');
  const valueInitial = getPropValue('valueValue') ?? msg.misc.na;
  const title = getPropValue('settingsTitle');
  const style = getPropValue('settingsStyle');
  const valueCurrentColor = getPropValue('valueCurrentColor');
  const [value, setValue] = useState(valueInitial);
  const [alarm, setAlarm] = useState(statusAlarmInitial);
  const [colors, setColors] = useState(getColorBasedOnStyle(style, valueCurrentColor));

  const classes = useStyles();
  const isEditMode = useSelector((state) => state.settings.isEditMode);

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === 'valueValue') {
        setValue(data.Objects.relatedNode?.value);
      } else if (data.Objects.relatedNode?.key === 'settingsIcon') {
        setColors(getColorBasedOnStyle(style, data.Objects.relatedNode?.value));
      } else if (data.Objects.relatedNode?.key === 'settingsStyle') {
        setColors(getColorBasedOnStyle(data.Objects.relatedNode?.value, valueCurrentColor));
      } else if (data.Objects.relatedNode?.key === 'statusAlarm') {
        setAlarm(data.Objects.relatedNode?.value);
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  let total_px = 0,
    icon_px = 0,
    font_px = 0;

  switch (size) {
    case 'tiny':
      total_px = '48';
      icon_px = '20'; //45
      font_px = '20';
      break;
    case 'small':
      total_px = '48';
      icon_px = '20'; //45
      font_px = '20';
      break;
    case 'medium':
      total_px = '96';
      icon_px = '34'; //70
      font_px = '48';
      break;
    // case "large":
    //   total_px = "192";
    //   icon_px = "130";
    //   font_px = "36";
    //   break;
    default:
  }

  return (
    <>
      <Tooltip
        title={title ?? ''}
        disableTouchListener
        {...(title ? { disableHoverListener: false } : { disableHoverListener: true })}
      >
        <div
          onClick={() => {
            props.handleClickWidget();
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            height: `100%`,
            backgroundColor: colors.bg,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: isEditMode ? 'none' : 'auto',
            filter: selected ? highlightSelectedStyle : '',
            borderRadius: '2px',
            background:
              alarm === 'triggered'
                ? `linear-gradient(225deg, ${theme.palette.wRed} 15px, ${colors.bg} 15px )`
                : `${colors.bg}`,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1,
              paddingRight: '8px',
              paddingLeft: '8px',
            }}
          >
            <div
              className={classes.root}
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                WebkitMaskRepeat: `no-repeat`,
                WebkitMaskSize: `contain`,
                WebkitMaskImage: `url(${getImageById(iconId)})`,
                maskImage: `url(${getImageById(iconId)})`,
                maskRepeat: `no-repeat`,
                maskSize: `contain`,
                backgroundColor: colors.fg,
                height: `${icon_px}px`,
                width: `${icon_px}px`,
                marginRight: '7px',
              }}
            ></div>
            <Typography
              style={{
                display: 'flex',
                whiteSpace: 'nowrap',
                fontSize: `${font_px}px`,
                color: colors.fg,
              }}
            >
              {value}
            </Typography>
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default W_Timer;
