import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { dispatch as dispatchBus } from 'use-bus';
import * as yup from 'yup';
import CommonModal from '../../../components/CommonModal';
import { msg } from '../../../constants/messages';
import useIsFieldRequired from '../../../hooks/formik/useIsFieldRequired';
import { SETTINGS_STYLE_OPTIONS } from '../../../utils/constants/selectOptions';
import { getPropertyValueByKey } from '../../../utils/getPropertyByKey';
import { WIDGETS_ENUM } from '../../../utils/widgetTypes';
import CustomInput from '../../CustomInput';
import CustomSelect from '../../CustomSelect';
import CustomSelectIcon from '../../CustomSelectIcon';
import CustomSwitch from '../../CustomSwitch';
import { TRegularChartProps } from '../types';
import { useAddWidget } from '../useAddWidget';
import {
  FORMAT_OPTIONS,
  FORM_DEFAULT_VALUES,
  ORIENTATION_OPTIONS,
  SETTINGS_SIZE_OPTIONS,
  SETTINGS_SIZE_OPTIONS_MINIMAP,
} from './constants';

const CreateDatabox = (props: TRegularChartProps & InstanceProps<{}>) => {
  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const { createWidgetFn, updateWidget, isLoading } = useAddWidget({
    group: props.group,
    widgetType: WIDGETS_ENUM.DATABOX,
    cb: submit,
  });

  const isEdit = props?.widget?.id !== undefined;

  const isMinimap = (): unknown => props?.group?.type?.some((item) => item?.value === 'minimap');

  const getPrevValues = () => {
    const defaultValues = {
      ...FORM_DEFAULT_VALUES,
    };

    if (props?.widget?.objectProperties) {
      for (const property in FORM_DEFAULT_VALUES) {
        defaultValues[property] = getPropertyValueByKey(props.widget.objectProperties, property);
      }
    }

    return defaultValues;
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .required('Name is required')
      .when([], {
        is: () => isEdit,
        then: (schema) => schema.required('Name is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
  });

  const formik = useFormik({
    initialValues: {
      description: isEdit ? props.widget.description : '',
      name: props.name,
      properties: {
        ...getPrevValues(),
      },
    },
    validationSchema,
    onSubmit: ({ properties, name, description }) => {
      if (isEdit) {
        updateWidget({
          values: properties,
          defaultValues: { ...getPrevValues() },
          id: props.widget.id,
          name,
          description,
          cb: submit,
        });
      } else {
        createWidgetFn({
          values: properties,
          name,
          description,
          cb: submit,
        });
      }
    },
  });

  useEffect(() => {
    if (isEdit) {
      dispatchBus('@@board/SAVE_BOARD');
    }
  }, []);

  const isFieldRequired = useIsFieldRequired(validationSchema);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={isEdit ? 'Edit widget' : 'Add widget'}
        handleClose={reject}
        loading={isLoading}
        buttons={
          <>
            <Button data-test="close-chart" color="inherit" onClick={reject}>
              {msg.addWidgetModal.buttonCancel}
            </Button>
            <Button
              disabled={isLoading}
              data-test="save-databox"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          {isEdit && (
            <>
              <Grid item>
                <CustomInput
                  required={isFieldRequired('name')}
                  name="name"
                  label="Name"
                  clearFieldIcon={true}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.errors.name}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Content
            </Typography>
          </Grid>

          <Grid item>
            <CustomSelectIcon
              name="properties.settingsIcon"
              filter={['application', 'monitor', 'icon']}
              label={'Icon'}
              value={formik.values.properties.settingsIcon}
              onChange={formik.handleChange}
              data-test=""
              clearFieldIcon={false}
              propType={''}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsFormat"
              label="Format"
              value={formik.values.properties.settingsFormat}
              list={FORMAT_OPTIONS}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="properties.settingsShowTitle"
              label="Show title"
              value={formik.values.properties.settingsShowTitle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Appearance
            </Typography>
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsStyle"
              label="Style"
              list={SETTINGS_STYLE_OPTIONS}
              value={formik.values.properties.settingsStyle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsSize"
              label="Size"
              list={isMinimap() ? SETTINGS_SIZE_OPTIONS_MINIMAP : SETTINGS_SIZE_OPTIONS}
              value={formik.values.properties.settingsSize}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsOrientation"
              label="Orientation"
              list={ORIENTATION_OPTIONS}
              value={formik.values.properties.settingsOrientation}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Description
            </Typography>
          </Grid>
          <Grid item>
            <CustomInput
              name="description"
              label="Description"
              clearFieldIcon={true}
              multiline={true}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(CreateDatabox);
