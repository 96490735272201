import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import { format, subHours } from 'date-fns';
import { useState } from 'react';
import useBus from 'use-bus';
import { COLOR_SCHEMAS, highlightSelectedStyle } from '../../constants/constants';
import useColors from '../../utils/useColors';
import { BASE_HEIGHT } from '../../utils/widgetSizes';
import WidgetEditControls from '../WidgetEditControls';

const useStyles = makeStyles(() => ({
  table: {
    backgroundColor: '#2780E3',
    height: '100%',
  },

  tableCellHead: {
    userSelect: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderBottom: 'none',
    fontSize: '16px',
    fontFamily: 'Roboto-Medium',
  },

  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderBottom: 'none',
    fontSize: '16px',
    fontFamily: 'Roboto-Regular',
  },
}));

const W_HistoryTable = (props) => {
  const { objectProperties, selected } = props;

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;

  const simulation = objectProperties.find((obj) => obj.key === 'settingsSimulation')?.value;
  const settingsRowCount = objectProperties.find((obj) => obj.key === 'settingsRowCount')?.value;
  const columnsLabel1 = objectProperties.find((obj) => obj.key === 'columnsLabel1')?.value;
  const columnsLabel2 = objectProperties.find((obj) => obj.key === 'columnsLabel2')?.value;
  const columnsLabel3 = objectProperties.find((obj) => obj.key === 'columnsLabel3')?.value;
  const columnsLabel4 = objectProperties.find((obj) => obj.key === 'columnsLabel4')?.value;
  const columnsLabel5 = objectProperties.find((obj) => obj.key === 'columnsLabel5')?.value;

  const colors = [getPropValue('settingsStyle'), null];

  const { getColorBasedOnStyle } = useColors();
  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(getPropValue('settingsStyle'));
  const [value, setValue] = useState(0);

  useBus(
    '@@iwow/FAKE_UPDATE_HISTORY',
    (e) => {
      if (e.id === props.id) {
        setValue(rndRange(0, 10));
      }
    },
    [value]
  );

  const classes = useStyles();

  const getColorOfRow = (index) => {
    const isOdd = () => index % 2;

    if (isOdd()) {
      return '';
    }

    const theme = colors[0];

    switch (theme) {
      case COLOR_SCHEMAS.DARK_ON_LIGHT:
        return '#F1F1F1';
      default:
        return 'rgba(255, 255, 255, 0.1)';
    }
  };

  const rndRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        backgroundColor: bgColor,
        filter: selected ? highlightSelectedStyle : '',
        borderRadius: '2px',
      }}
    >
      <div style={{ width: '100%' }}>
        <Table
          size="small"
          className={classes.table}
          aria-label="simple table"
          style={{ backgroundColor: 'transparent' }}
        >
          <TableHead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: bgColor,
              height: `${BASE_HEIGHT}px`,
            }}
          >
            <TableRow>
              <TableCell className={classes.tableCellHead} style={{ color: fgColor }}>
                Time
              </TableCell>
              {columnsLabel1 && (
                <TableCell align="center" className={classes.tableCellHead} style={{ color: fgColor }}>
                  {columnsLabel1}
                </TableCell>
              )}
              {columnsLabel2 && (
                <TableCell align="center" className={classes.tableCellHead} style={{ color: fgColor }}>
                  {columnsLabel2}
                </TableCell>
              )}
              {columnsLabel3 && (
                <TableCell align="center" className={classes.tableCellHead} style={{ color: fgColor }}>
                  {columnsLabel3}
                </TableCell>
              )}
              {columnsLabel4 && (
                <TableCell align="center" className={classes.tableCellHead} style={{ color: fgColor }}>
                  {columnsLabel4}
                </TableCell>
              )}
              {columnsLabel5 && (
                <TableCell align="center" className={classes.tableCellHead} style={{ color: fgColor }}>
                  {columnsLabel5}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array(settingsRowCount)
              .fill()
              .map((item, index) => (
                <TableRow
                  key={index}
                  style={{
                    height: `${BASE_HEIGHT}px`,
                    backgroundColor: getColorOfRow(index),
                  }}
                >
                  <TableCell component="th" scope="row" className={classes.tableCell} style={{ color: fgColor }}>
                    {format(subHours(new Date(), index), 'HH:')}00
                  </TableCell>
                  {columnsLabel1 && (
                    <TableCell align="center" className={classes.tableCell} style={{ color: fgColor }}>
                      {simulation ? rndRange(1, 10) / 2 : value}
                    </TableCell>
                  )}
                  {columnsLabel2 && (
                    <TableCell align="center" className={classes.tableCell} style={{ color: fgColor }}>
                      {simulation ? rndRange(1, 10) / 2 : value}
                    </TableCell>
                  )}
                  {columnsLabel3 && (
                    <TableCell align="center" className={classes.tableCell} style={{ color: fgColor }}>
                      {simulation ? rndRange(1, 10) / 2 : value}
                    </TableCell>
                  )}
                  {columnsLabel4 && (
                    <TableCell align="center" className={classes.tableCell} style={{ color: fgColor }}>
                      {simulation ? rndRange(1, 10) / 2 : value}
                    </TableCell>
                  )}
                  {columnsLabel5 && (
                    <TableCell align="center" className={classes.tableCell} style={{ color: fgColor }}>
                      {simulation ? rndRange(1, 10) / 2 : value}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <WidgetEditControls {...props} />
    </div>
  );
};

export default W_HistoryTable;
