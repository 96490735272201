import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ACCESS_TOKEN_MUTATION, REFRESH_TOKEN_MUTATION, USERID_QUERY, USER_QUERY } from '../graphql/queries';
import { updateProfile } from '../store/profileSlice';
import { updateUser } from '../store/userSlice';

export const useAuth = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [isLoadingUser, setIsLoadingUser] = useState(false);

  const getUserId = async () => {
    const result_userid = await client.query({
      query: USERID_QUERY,
      variables: {},
      fetchPolicy: 'network-only',
    });

    return result_userid?.data.getUserId;
  };

  const loadUser = async () => {
    setIsLoadingUser(true);
    const id = await getUserId();

    const result_user = await client.query({
      query: USER_QUERY,
      variables: {
        id: id,
      },
      fetchPolicy: 'network-only',
    });

    dispatch(updateUser(result_user.data.user));

    const profile = { id: result_user.data.user.userProfiles[0].profile.id };

    result_user.data.user.userProfiles[0].profile.objectProperties.forEach((prop) => {
      profile[prop.key] = prop.value;
    });

    setIsLoadingUser(false);
    dispatch(updateProfile(profile));

    return profile;
  }; //loadUser

  const loginFromApp = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    try {
      const result_access = await client.mutate({
        mutation: ACCESS_TOKEN_MUTATION,
        variables: {
          input: {
            userRefreshToken: refreshToken,
            accessTokenExpiration: 24 * 60,
            profileTags: ['application', 'board', 'user profile'],
          },
        },
        fetchPolicy: 'no-cache',
      });

      const jwt = result_access.data.authAccessToken.jwtToken;

      if (jwt) {
        localStorage.setItem('authToken', jwt);
        await loadUser();
      } else {
        dispatch(updateUser(null));
        return Promise.reject(new Error('User does not exist'));
      }
    } catch (err) {
      dispatch(updateUser(null));
      return Promise.reject(new Error('Authorization failed'));
    } //catch
  }; //loginFromApp

  const logout = async () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenId');
    localStorage.removeItem('linkToViewBoardId');
  };

  const login = async (input) => {
    const result_refresh = await client.mutate({
      mutation: REFRESH_TOKEN_MUTATION,
      variables: {
        input: input,
      },
    });

    const userRefreshToken = result_refresh.data.authRefreshToken.refreshToken.token;
    const tokenId = result_refresh.data.authRefreshToken.refreshToken.id;
    const twoFa = result_refresh.data.authRefreshToken.refreshToken?.twoFa?.type;

    localStorage.setItem('tokenId', tokenId);
    localStorage.setItem('refreshToken', userRefreshToken);

    if (twoFa === 'none') {
      const result_access = await client.mutate({
        mutation: ACCESS_TOKEN_MUTATION,
        variables: {
          input: {
            userRefreshToken: userRefreshToken,
            accessTokenExpiration: 24 * 60,
            profileTags: ['application', 'board', 'user profile'],
          },
        },
        fetchPolicy: 'no-cache',
      });

      const jwt = result_access.data.authAccessToken.jwtToken;
      if (jwt) {
        localStorage.setItem('authToken', jwt);
      } else {
        return Promise.reject(new Error('User does not exist'));
      }

      await loadUser();
    } else {
      return Promise.resolve({
        twoFA: result_refresh.data.authRefreshToken.refreshToken?.twoFa,
      });
    }
  };

  function submitOTP(otp) {
    return client
      .mutate({
        mutation: ACCESS_TOKEN_MUTATION,
        variables: {
          input: {
            userRefreshToken: localStorage.getItem('refreshToken'),
            otp,
            accessTokenExpiration: 24 * 60,
            profileTags: ['application', 'board', 'user profile'],
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then(async (r) => {
        const jwt = r.data.authAccessToken.jwtToken;

        if (jwt) {
          localStorage.setItem('authToken', jwt);
        } else {
          return Promise.reject(new Error('User does not exist'));
        }

        await loadUser();
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
  return { login, logout, loginFromApp, loadUser, getUserId, isLoadingUser, submitOTP };
};
