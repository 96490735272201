import { gql } from '../../../__generated__';

const GET_COLLECTIONS = gql(/* GraphQL */ `
  query getCollections {
    collections: objects(
      filter: { schemaTags: { contains: ["application", "board", "collection"] } }
      orderBy: NAME_ASC
    ) {
      id
      name
      enabled
      schemaTags
    }
  }
`);

const COLLECTIONS_QUERY_FULL = gql(/* GraphQL */ `
  query getFullCollections {
    dashboards: objects(
      filter: { schemaTags: { contains: ["application", "board", "collection"] } }
      orderBy: NAME_ASC
    ) {
      id
      enabled
      name
      objectsToObjectsByObject2Id {
        id
        object1 {
          id
          name
          enabled
          schemaTags
        }
      }
    }
  }
`);

export { GET_COLLECTIONS, COLLECTIONS_QUERY_FULL };
