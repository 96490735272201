import { gql, useMutation } from '@apollo/client';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';
import imgUpload from '../../assets/upload.svg';
import useFileUpload from '../../utils/useFileUpload';
import useMedia from '../../utils/useMedia';

const styles = () => ({
  wrapper: {
    position: 'relative',
    height: '160px',
  },
  iconButton: {
    backgroundColor: '#000000',
    opacity: '0.44',
    height: '40px',
    width: '40px',
    color: '#ffffff',
    marginLeft: '16px',
    '&:hover': {
      backgroundColor: '#000000',
      opacity: '0.6',
    },
  },
});

const REMOVE = gql`
  mutation remove($input: mnDeleteObjectInput!) {
    mnDeleteObject(input: $input) {
      clientMutationId
    }
  }
`;

const PictureItem = ({ backgroundUID, backgroundBlankUrl = imgUpload, setImageId, cbDelete = () => {} }) => {
  const { FileUpload, uploadInputRef, fileIsUploading } = useFileUpload();
  const { getImageById } = useMedia();
  const useStyles = makeStyles((theme) => styles(theme));
  const [deleteObjects] = useMutation(REMOVE);
  const classes = useStyles();

  const handleDelete = () => {
    deleteObjects({
      variables: {
        input: {
          mnPatch: [{ id: backgroundUID }],
        },
      },
    }).then(() => {
      cbDelete();
    });
  };

  return (
    <div className={classes.wrapper}>
      <div style={{ position: 'relative', width: '100%' }}>
        <img src={`${backgroundUID ? getImageById(backgroundUID) : backgroundBlankUrl}`} style={{ width: '100%' }} />
      </div>

      {/* buttons upload/copy/delete */}
      <div style={{ position: 'absolute', right: '16px', top: '8px' }}>
        {backgroundUID && (
          <IconButton className={classes.iconButton} onClick={() => {}} size="large">
            <FilterNoneIcon />
          </IconButton>
        )}

        {!backgroundUID && (
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              uploadInputRef.current && uploadInputRef.current.click();
            }}
            size="large"
          >
            <CloudUploadIcon />
          </IconButton>
        )}

        {backgroundUID && (
          <IconButton className={classes.iconButton} onClick={handleDelete} size="large">
            <DeleteIcon />
          </IconButton>
        )}
      </div>

      <div style={{ position: 'absolute', top: '0', width: '100%' }}>
        {fileIsUploading && <LinearProgress style={{ width: '100%' }} />}
      </div>

      {!fileIsUploading && (
        <FileUpload
          file_uid={backgroundUID}
          accept={['image/png', 'image/jpeg']}
          maxSize={10000000}
          handleUploaded={(value) => {
            setImageId(value);
          }}
          handleError={() => {}}
        />
      )}
    </div>
  );
};

export default PictureItem;
