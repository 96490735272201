import { gql } from '@apollo/client';

export const REFRESH_TOKEN_MUTATION = gql`
  mutation authRefreshToken($input: AuthRefreshTokenInput!) {
    authRefreshToken(input: $input) {
      refreshToken {
        twoFa {
          type
          info
        }
        token
        id
      }
    }
  }
`;

export const ACCESS_TOKEN_MUTATION = gql`
  mutation authAccessToken($input: AuthAccessTokenInput!) {
    authAccessToken(input: $input) {
      jwtToken
    }
  }
`;

export const USERID_QUERY = gql`
  query getUserId {
    getUserId
  }
`;

export const USER_QUERY = gql`
  query loadUser($id: UUID!) {
    user(id: $id) {
      id
      type
      login
      mEmail
      mName
      mPhone
      mExternalId
      mIcon
      mPicture
      mTags
      description
      enabled
      defaultEditorgroup
      defaultReadergroup
      defaultUsergroup
      usersToGroups {
        userGroup {
          id
          groupName
        }
      }
      userProfiles(first: 1, filter: { object: { schemaTags: { contains: ["board"] } } }) {
        id
        profile: object {
          objectsToObjectsByObject1Id(
            filter: {
              object2: { enabled: { equalTo: true }, schemaTags: { equalTo: ["application", "notification profile"] } }
            }
          ) {
            id
            object2 {
              id
              name
              deliveryChannel: dynamicAttribute(
                match: { groupName: "Settings", property: "Delivery channel", index: "0" }
              )
              objectProperties {
                id
                key
                value
              }
            }
          }
          id
          objectProperties {
            id
            value
            key
            groupName
            property
          }
        }
      }
      notificationsByBy {
        objectId
        objectName
        message
        tags
        createdAt
      }
      usersToObjects {
        nodeId
        object {
          id
          name
          schemaType
        }
      }
    }
  }
`;

export const APPS_QUERY = gql`
  query userApps($id: UUID!) {
    user(id: $id) {
      userProfiles {
        object {
          schemaType
          enabled
          name: property(propertyName: "Program/Name")
          icon: property(propertyName: "Program/Icon")
          url: property(propertyName: "Program/URL")
        }
      }
    }
  }

  #  query userApps($userId: UUID!) {
  #    schemata(filter: { mTags: { contains: ["application", "user profile"] } }, orderBy: NAME_ASC) {
  #      id
  #      name: property(propertyName: "Program/Name")
  #      icon: property(propertyName: "Program/Icon")
  #      url: property(propertyName: "Program/URL")
  #      user: objects(
  #        filter: {
  #          userProfilesConnection: {
  #            some: { userId: { equalTo: $userId } }
  #            every: { object: { enabled: { equalTo: true } } }
  #          }
  #        }
  #      ) {
  #        userProfiles {
  #          user {
  #            login
  #            enabled
  #          }
  #        }
  #      }
  #    }
  #  }
`;

// query icons for CustomSelectIcon (DataBox widget)
export const ICONS_QUERY = gql`
  query fetch($filter: [String!]) {
    objects(filter: { schemaTags: { contains: $filter } }, orderBy: NAME_ASC) {
      id
      name
    }
  }
`;

export const OBJECTS_QUERY = gql`
  query listDevices($filter: ObjectFilter) {
    objects(orderBy: NAME_ASC, filter: $filter) {
      id
      name
      schemaId
      schemaTags
    }
  }
`;

export const DEVICES_LINKED_STAT_QUERY = gql`
  query listConnectedObjects {
    objects(
      filter: { schemaTags: { contains: ["application", "dispatcher", "statistics", "timeseries"] } }
      orderBy: NAME_ASC
    ) {
      id
      name
    }
  }
`;

export const DEVICES_LINKED_QUERY = gql`
  query listConnectedObjects($widgetId: UUID!) {
    objects(filter: { objectsToObjectsByObject2IdConnection: { some: { object1: { id: { equalTo: $widgetId } } } } }) {
      id
      name
      objectsToObjectsByObject1Id {
        forced
        object2 {
          id
        }
      }
    }
  }
`;

export const OBJECT_PROPS_QUERY = gql`
  query listProperties($deviceId: UUID!) {
    objectProperties(filter: { objectId: { equalTo: $deviceId } }, orderBy: [GROUP_NAME_ASC, PROPERTY_ASC]) {
      id
      groupName
      property
      key
      linkedPropertyId
      spec {
        description
      }
    }
  }
`;

// get linked device and it's state (e.g. name "Parking Sensor #1", property "Battery Level")
export const DEVICE_PROP_LINKED_QUERY = gql`
  query getProperty($linkedPropId: UUID!) {
    objectProperty(id: $linkedPropId) {
      objectId
      property
      groupName
      object {
        objectsToObjectsByObject2Id {
          forced
          object2 {
            id
            name
            objectProperties {
              id
              property
            }
          }
          object1 {
            id
            name
            objectProperties {
              id
            }
          }
        }
        name
      }
    }
  }
`;

export const OBJECT_PROP_QUERY = gql`
  query getProperty($propId: UUID!) {
    objectProperty(id: $propId) {
      key
      value
      property
      groupName
      type
      objectId
      spec {
        valueSet
      }
    }
  }
`;

// get RPCs (controls)
export const RPCS_QUERY = gql`
  query getRPC($deviceId: UUID!) {
    object(id: $deviceId) {
      schema {
        schemaControls(filter: { isRpc: { equalTo: true } }, orderBy: [RPC_DESC, ARGUMENT_DESC]) {
          rpc
          description
          schemaId
        }
      }
    }
  }
`;

// get params for RPC
export const RPC_PARAMS_QUERY = gql`
  query getRPCParams($schemaId: UUID!, $rpc: String!) {
    schemaControls(
      filter: { schemaId: { equalTo: $schemaId }, rpc: { equalTo: $rpc }, isRpc: { equalTo: false } }
      orderBy: [RPC_DESC, ARGUMENT_DESC]
    ) {
      key: nodeId
      valueRange
      valueSet
      defaultValue
      description
      argument
      typeSpec
      typeId: typeSpec
    }
  }
`;

export const RPC_CALL_MUTATION = gql`
  mutation registerRPC($widgetId: UUID!, $auth_token: String) {
    createControlExecution(
      input: { controlExecution: { name: "CommandButtonClick", params: { jwt: $auth_token }, objectId: $widgetId } }
    ) {
      controlExecution {
        id
      }
    }
  }
`;

export const WIDGET_PROP_LINKED_ID_QUERY = gql`
  query getLProperty($propId: UUID!) {
    objectProperty(id: $propId) {
      linkedPropertyId
    }
  }
`;

export const UPDATE_PROP_MUTATION = gql`
  mutation savePropertyLink($propId: UUID!, $linkedPropId: UUID) {
    updateObjectProperty(input: { id: $propId, patch: { linkedPropertyId: $linkedPropId } }) {
      clientMutationId
    }
  }
`;

export const WIDGETS_PROPS_QUERY = gql`
  query widgetsProps($widgetType: String!) {
    schemata(first: 1, filter: { mTags: { equalTo: ["application", "board", "widget", $widgetType] } }) {
      id
      objectsCount
      schemaProperties(orderBy: [GROUP_NAME_DESC, PROPERTY_DESC]) {
        id
        groupName
        defaultValue
        property
        type {
          name
        }
        description
        hidden
        valueSet
        key
      }
    }
  }
`;

// groupNames - array of groups to read properties from. Used for editing properties only from required groups
// (for example only Settings or Settings and Value)
// format: [{groupName: {equalTo: "Settings"}}, {groupName: {equalTo: "Value"}}]

export const WIDGET_QUERY = gql`
  query getWidget($objId: UUID!, $groupNames: [ObjectPropertyFilter!]) {
    object(id: $objId) {
      id
      name
      description
      schemaName
      enabled
      muted
      userGroupByEditorgroup {
        id
        groupName
      }
      userGroupByUsergroup {
        id
        groupName
      }
      userGroupByReadergroup {
        id
        groupName
      }
      objectProperties(orderBy: [PROPERTY_ASC], filter: { or: $groupNames }) {
        groupName
        property
        id
        spec {
          units
          type {
            name
          }
          key
          description
          hidden
          type {
            name
          }
          valueSet
        }
        key
        value
      }
    }
  }
`;

export const USERGROUPS_QUERY = gql`
  query userGroups {
    userGroups {
      id
      groupName
      description
    }
  }
`;

export const DASHBOARD_QUERY = gql`
  query getDashboard($objId: UUID!) {
    object(id: $objId) {
      name
      description
      schemaName
      enabled
      muted
      readergroup
      usergroup
      editorgroup
      objectProperties(sort: { sortBy: { field: PROPERTY } }) {
        groupName
        property
        spec {
          units
          description
          type {
            name
          }
          valueSet
          hidden
        }
        key
        value
      }
    }
  }
`;

export const GROUPS_PROPS_QUERY = gql`
  query groupsProps {
    schemata(first: 1, filter: { mTags: { equalTo: ["application", "board", "group"] } }) {
      id
      objectsCount
      schemaProperties(orderBy: [GROUP_NAME_DESC, PROPERTY_DESC]) {
        id
        groupName
        defaultValue
        property
        type {
          name
        }
        description
        hidden
        valueRange
        valueSet
        key
      }
    }
  }
`;

export const DASHBOARDS_PROPS_QUERY = gql`
  query dashboardsProps {
    schemata(first: 1, filter: { mTags: { equalTo: ["application", "board", "dashboard"] } }) {
      id
      objectsCount
    }
  }
`;

// get notifications about given object id
export const NOTIFICATIONS_QUERY = gql`
  query notifications($objId: UUID!) {
    notifications(filter: { objectId: { equalTo: $objId } }, orderBy: CREATED_AT_DESC) {
      id
      createdAt
      objectName
      message
      tags
      userByBy {
        id
        login
      }
    }
  }
`;

// get controls about given object id
export const CONTROLS_QUERY = gql`
  query controls($objId: UUID!) {
    object(id: $objId) {
      controlExecutions {
        objectId
        done
        error
        ack
        name
      }
      schema {
        schemaControls {
          description
          argument
          id
          nodeId
          rpc
        }
      }
    }
  }
`;

/*
// get schemas names
export const SCHEMAS_NAMES_QUERY = gql`
query listSchemas {
  schemata {
    name
    id
  }
}
`;
*/

// get schemas names
export const SCHEMAS_NAMES_QUERY = gql`
  query listSchemas($schemaType: SchemaTypes!) {
    schemata(filter: { type: { equalTo: $schemaType }, not: { mTags: { contains: ["widget"] } } }) {
      name
      description
      id
    }
  }
`;

// list all schemas
export const SCHEMAS_QUERY = gql`
  query listSchemas {
    schemata(orderBy: NAME_ASC) {
      name
      id
    }
  }
`;

// get schema properties
export const SCHEMA_PROPS_QUERY = gql`
  query schemaProperties($schemaId: UUID!) {
    schemaProperties(filter: { schemaId: { equalTo: $schemaId } }, orderBy: [GROUP_NAME_ASC, PROPERTY_ASC]) {
      id
      groupName
      property
      key
      description
    }
  }
`;

// get schema property
export const SCHEMA_PROP_QUERY = gql`
  query getSchema($propId: UUID!) {
    schemaProperty(id: $propId) {
      schemaId
    }
  }
`;

export const DELETE_OBJECT_MUTATION = gql`
  mutation deleteObject($objId: UUID!) {
    deleteObject(input: { id: $objId }) {
      clientMutationId
    }
  }
`;

export const UPDATE_OBJECT_ACCESS_MUTATION = gql`
  mutation UpdateObj($objId: UUID!, $editorsGroup: UUID!, $readersGroup: UUID!, $usersGroup: UUID!) {
    updateObject(
      input: { id: $objId, patch: { editorgroup: $editorsGroup, usergroup: $usersGroup, readergroup: $readersGroup } }
    ) {
      clientMutationId
    }
  }
`;

// update only properties (no name, description, ..)
export const UPDATE_WIDGET_PROPS_MUTATION = gql`
  mutation UpdateObj($widgetId: UUID!, $values: [PropertyByKeyInput]) {
    updateObjectWithProperties(input: { detailedObject: [{ objectId: $widgetId, keyedProperties: $values }] }) {
      clientMutationId
    }
  }
`;

export const GET_DATA_SUBSCRIPTION = gql`
  subscription Objects($objId: UUID!) {
    Objects(filterA: { id: [$objId] }) {
      event
      relatedNode {
        ... on Object {
          id
          name
          enabled
          description
        }
        ... on ObjectProperty {
          id
          updatedAt
          userByBy {
            id
            login
            mName
          }
          linkedPropertyId
          groupName
          property
          value
          key
        }
        ... on ObjectsToObject {
          id
          object1Id
          object2Id
        }
      }
      relatedNodeId
    }
  }
`;
