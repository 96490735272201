import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import * as yup from 'yup';
import CommonModal from '../components/CommonModal';
import CustomInput from '../components/CustomInput';
import CustomSwitch from '../components/CustomSwitch';
import { msg } from '../constants/messages';
import useIsFieldRequired from '../hooks/formik/useIsFieldRequired';

const ADD_NEW_DASHBOARD = gql`
  mutation addNewDashboard($input: CreateObjectWithPropertiesInput!) {
    createObjectWithProperties(input: $input) {
      clientMutationId
      uuid
    }
  }
`;
const SCHEMAS_NAMES_QUERY = gql`
  query listSchemas {
    schemata(first: 1, filter: { mTags: { contains: ["collection", "board"] } }) {
      name
      id
    }
  }
`;

const AddNewCollection = (props) => {
  const client = useApolloClient();
  const [, setName] = useState('');
  const [description, setDescription] = useState('');
  const [addObject] = useMutation(ADD_NEW_DASHBOARD);

  let defaultValues = {};

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  // get props of device on device selection
  const handleSchemaTypeChange = async () => {
    try {
      const result = await client.query({
        query: SCHEMAS_NAMES_QUERY,
        fetchPolicy: 'network-only',
      });

      setValues({ schemaName: result.data.schemata[0].id });
    } catch (err) {
    } finally {
    }
  }; //handleSchemaTypeChange

  //TODO: load list of schema names here (instead of current hardcoded list)
  useEffect(() => {
    defaultValues['schemaType'] = '';
    defaultValues['schemaName'] = '';
    defaultValues['enabled'] = true;
    setValues(defaultValues);
    setName('');
    setDescription('');
    handleSchemaTypeChange().then(() => {});
  }, []);

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (name === 'schemaType') handleSchemaTypeChange(e).then(() => {});
    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const validationSchema = yup.object({
    name: yup.string().trim().required('Name is required'),
    // schemaName: yup
    //   .string()
    //   .nullable()
    //   .required('Schema name is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      schemaName: '',
    },
    validationSchema: validationSchema,
    onSubmit: (valuesForm, actions) => {
      toast
        .promise(
          addObject({
            variables: {
              input: {
                name: valuesForm.name,
                description: description,
                schemaId: values['schemaName'],
                enabled: Boolean(values['enabled']),
              },
            },
          }),
          {
            loading: 'Creating new collection...',
            success: () => `Collection created`,
            error: (err) => `${err.toString()}`,
          }
        )
        .then(() => {
          actions.resetForm();
          submit();
        });
    },
  });

  const isFieldRequired = useIsFieldRequired(validationSchema);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={'Add collection'}
        forceTitle={true}
        contentStyles={{
          padding: '14px 16px 16px 14px',
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.addNewObjectModal.buttonCancel}
            </Button>
            <Button color="primary" type="submit" onClick={formik.handleSubmit}>
              {msg.addNewObjectModal.buttonAdd}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              name="name"
              label={msg.addNewObjectModal.name}
              clearFieldIcon={true}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              required={isFieldRequired('name')}
            />
          </Grid>

          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="enabled"
              label={msg.addNewObjectModal.enabled}
              value={values['enabled'] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomInput
              name="description"
              label={msg.addNewObjectModal.description}
              clearFieldIcon={true}
              value={description ?? ''}
              multiline={true}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

AddNewCollection.propTypes = {
  widgetId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default create(AddNewCollection);
