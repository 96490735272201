import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, memo } from 'react';
import { Link } from 'react-router-dom';

export interface IListItem {
  name?: string;
  value?: string;
  type?: 'text' | 'link' | 'title';
}

const ListItem: FC<IListItem> = ({ name, value, type }) => (
  <Grid paddingBlock={type === 'title' ? '13px' : '12px'} pl="16px">
    <Box display="flex" justifyContent="space-between">
      {type === 'title' ? (
        <Typography color="primary" variant="subtitle2">
          {name}
        </Typography>
      ) : (
        <span>{name}</span>
      )}
      {type === 'text' ? (
        <span>{value}</span>
      ) : (
        <Link target="_blank" to={value}>
          {value}
        </Link>
      )}
    </Box>
  </Grid>
);

export default memo(ListItem);
