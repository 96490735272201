import { gql, useMutation } from '@apollo/client';
import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { cloneDeep, differenceBy } from 'lodash';
import { useEffect, useState } from 'react';
import { create } from 'react-modal-promise';
import { msg } from '../../../constants/messages';
import CommonModal from '../../CommonModal';
import CustomAutocomplete from '../../CustomAutocomplete';

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const MonitorStatusColumnModal = ({
  isEdit,
  value: currentValue,
  conditionProperty,
  conditionIndex,
  onResolve,
  onReject,
  isOpen,
}) => {
  const [value, setValue] = useState(null);

  const properties = [
    {
      title: 'All',
      value: 'all',
    },
    {
      title: 'Low battery',
      value: 'low_battery',
    },
    {
      title: 'No response',
      value: 'not_response',
    },
    {
      title: 'GPS not fix',
      value: 'gps_fix',
    },
    {
      title: 'Alarm triggered',
      value: 'alarm_triggered',
    },
  ];

  const columns = conditionProperty.value.columns;

  const [updateProperty, { loading }] = useMutation(UPDATE_PROPERTY);

  const submit = () => onResolve();

  const reject = () => onReject();

  useEffect(() => {
    if (isEdit) {
      setValue(currentValue);
    }
  }, [conditionProperty]);

  const handleEditProperty = (value) => {
    updateProperty({
      variables: {
        input: {
          id: conditionProperty.id,
          patch: {
            value: value,
          },
        },
      },
    }).then(() => {
      submit();
    });
  };

  return (
    <CommonModal
      key="MonitorTableColumnModal"
      modalOpen={isOpen}
      title={isEdit ? 'Edit column' : 'Add column'}
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>
            {msg.default.cancel}
          </Button>
          <Button
            disabled={!Boolean(value?.value)}
            color="primary"
            onClick={async () => {
              const oldValues = cloneDeep(conditionProperty.value.columns);

              if (isEdit) {
                oldValues.splice(conditionIndex, 1, {
                  ...value,
                });
                let patch = {
                  ...conditionProperty.value,
                  columns: [...oldValues],
                };
                handleEditProperty(patch);
              } else {
                oldValues.push({
                  ...value,
                });

                let patch = {
                  ...conditionProperty.value,
                  columns: [...oldValues],
                };

                handleEditProperty(patch);
              }
            }}
          >
            {loading ? <CircularProgress size={23} /> : isEdit ? msg.default.save : msg.default.add}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <CustomAutocomplete
            name="schemaProperties"
            label="Property"
            list={differenceBy(properties, columns, 'value')}
            value={value?.value || ''}
            onChange={(e) => {
              setValue(e.target.rawValue);
            }}
            required={true}
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(MonitorStatusColumnModal);
