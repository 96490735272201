import FilterNoneIcon from '@mui/icons-material/FilterNone';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { msg } from '../../../constants/messages';
import { setSettings } from '../../../store/settingsSlice';
import { hideNull } from '../../../utils/hideNull';
import ArrowDown from '../../icons/arrowDown';
import ArrowUp from '../../icons/arrowUp';
import ArrowUpDown from '../../icons/arrowUpDown';
import FunctionIcon from '../../icons/functionIcon';
import useMoreMenu from '../../useMoreMenu';
import AccessSection from '../basic/AccessSection';
import DescriptionSection from '../basic/DescriptionSection';
import ServiceSection from '../basic/ServiceSection';
import HistoryProperty from '../history/HistoryProperty';
import useHandleCopy from '../menu/handlers/useHandleCopy';

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: '15px',
    marginBottom: '10px',
  },
  itemToHideOrShow: {
    visibility: 'hidden',
    opacity: 1,
    transition: 'opacity 0.2s linear',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      opacity: 1,
    },
  },
  itemToHover: {
    '&:hover $itemToHideOrShow': {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.2s linear',
    },
  },
}));

const TabGeneralTimeseriesStatistic = (props) => {
  const { item } = props;
  const classes = useStyles({
    listItem: {
      minHeight: '56px',
    },
  });

  const { MoreMenu: ValueMenu, openMoreMenu: openValueMenu } = useMoreMenu();

  const dispatch = useDispatch();

  const handleCopy = useHandleCopy();
  const [idProperty, setIdProperty] = useState(null);
  const isShowHistoryProperty = useSelector((state) => state.settings.isShowHistoryProperty);

  const propValue = () => {
    return item.objectProperties.find((item) => item.key === 'valueValue');
  };

  const propValueMinimum = () => {
    return item.objectProperties.find((item) => item.key === 'valueMinimum');
  };

  const propValueMaximum = () => {
    return item.objectProperties.find((item) => item.key === 'valueMaximum');
  };

  const propValueAverage = () => {
    return item.objectProperties.find((item) => item.key === 'valueAverage');
  };

  return (
    <>
      {isShowHistoryProperty && <HistoryProperty id={idProperty} object={props.item} />}
      <ValueMenu
        items={[
          {
            icon: <HistoryIcon />,
            title: 'Show history',
            id: 'show_history',
            handleAction: (obj) => {
              setIdProperty(obj.propId);
              dispatch(setSettings({ isShowHistoryProperty: true }));
            },
            disabled: false,
          },
          {
            icon: <FilterNoneIcon />,
            title: msg.default.copy,
            id: 'copy_uuid',
            handleAction: (obj) => {
              handleCopy({
                text: obj.propValue,
                message: 'Value copied',
              });
            },
            disabled: false,
          },
        ]}
      />
      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>

        <ListItem classes={{ container: classes.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <FunctionIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{hideNull(propValue()?.value.y)}</Typography>} />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: propValue().key,
                  propValue: JSON.stringify(propValue().value),
                  propId: propValue().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem classes={{ container: classes.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <ArrowDown />
          </ListItemIcon>
          <ListItemText
            onClick={() => {
              handleCopy({
                text: item.id,
                message: 'Copied',
              });
            }}
            primary={<Typography variant="body1">{hideNull(propValueMinimum().value)}</Typography>}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: propValueMinimum().key,
                  propValue: JSON.stringify(propValueMinimum().value),
                  propId: propValueMinimum().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: classes.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <ArrowUp />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{hideNull(propValueMaximum().value)}</Typography>} />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: propValueMaximum().key,
                  propValue: JSON.stringify(propValueMaximum().value),
                  propId: propValueMaximum().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: classes.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <ArrowUpDown />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{hideNull(propValueAverage().value)}</Typography>} />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: propValueAverage().key,
                  propValue: JSON.stringify(propValueAverage().value),
                  propId: propValueAverage().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ServiceSection classes={classes} item={item} />

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default TabGeneralTimeseriesStatistic;
