import CloseIcon from '@mui/icons-material/Close';
import TodayIcon from '@mui/icons-material/Today';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import { compareAsc, format, parseISO, sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { create } from 'react-modal-promise';
import CommonModal from '../../../../components/CommonModal';
import { msg } from '../../../../constants/messages';

const ParameterDateRange = (props) => {
  const theme = useTheme();

  const formatStart = props.formatStart || 'yyyy-MM-dd 00:00';
  const formatEnd = props.formatEnd || 'yyyy-MM-dd 23:59';

  const [type] = useState('device');
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);

  const reject = () => props.onReject();

  const handleClose = () => reject();
  const [startDate, setStartDate] = useState(sub(Date.now(), { weeks: 1 }));
  const [endDate, setEndDate] = useState(Date.now());

  const handleSave = () => {
    if (!startDate && !endDate) {
      props.save({
        title: `n/a`,
        value: null,
      });
      handleClose();
    } else if (startDate && endDate) {
      props.save({
        title: `${format(startDate, 'dd-MM-yyyy')} — ${format(endDate, 'dd-MM-yyyy')}`,
        value: [format(startDate, formatStart), format(endDate, formatEnd)],
      });
      handleClose();
    }
  };

  useEffect(() => {
    if (props.selected.value.value) {
      setStartDate(parseISO(props.selected.value.value[0]));
      setEndDate(parseISO(props.selected.value.value[1]));
    }
  }, [type]);

  useEffect(() => {
    if (startDate && endDate) {
      if (compareAsc(startDate, endDate) === 1) {
        setEndDate(null);
      }
    }
  }, [startDate, endDate]);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={'Select date range'}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleSave}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <div style={{ display: 'flex' }}>
              <div style={{ padding: '16px 16px 16px 0px' }}>
                <TodayIcon style={{ color: theme.palette.gray1 }} />
              </div>
              <DatePicker
                clearable
                disableOpenPicker
                open={openStart}
                onOpen={() => setOpenStart(true)}
                onClose={() => setOpenStart(false)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    InputProps={{
                      ...props.inputProps,
                      readOnly: true,
                      endAdornment: startDate && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="reset"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setStartDate(null);
                            }}
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onClick={() => setOpenStart(true)}
                    style={{ width: '100%' }}
                    variant="standard"
                    label="From"
                    helperText={null}
                  />
                )}
                disableFuture={true}
                inputFormat="dd-MM-yyyy"
                variant="inline"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
            </div>
          </Grid>
          <Grid item>
            <div style={{ display: 'flex' }}>
              <div style={{ padding: '16px 16px 16px 0px' }}>
                <TodayIcon style={{ color: theme.palette.gray1 }} />
              </div>
              <DatePicker
                clearable
                disableFuture={true}
                disableOpenPicker
                open={openEnd}
                onOpen={() => setOpenEnd(true)}
                onClose={() => setOpenEnd(false)}
                renderInput={(props) => (
                  <TextField
                    onClick={() => setOpenEnd(true)}
                    style={{ width: '100%' }}
                    {...props}
                    InputProps={{
                      ...props.inputProps,
                      readOnly: true,
                      endAdornment: endDate && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="reset"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setEndDate(null);
                            }}
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    label="To"
                    helperText={null}
                  />
                )}
                inputFormat="dd-MM-yyyy"
                variant="inline"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(ParameterDateRange);
