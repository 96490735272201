import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { loader } from 'graphql.macro';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import { msg } from '../../constants/messages';
import ConfirmationModal from '../modals/ConfirmationModal';

const DASHBOARD_QUERY = loader('../../graphql/DashboardQuery.graphql');
const UPDATE_PROPERTIES_BY_IDS = loader('../../graphql/UpdateObjectPropertiesByIdMutation.graphql');

const REMOVE = gql`
  mutation remove($input: mnDeleteObjectInput!) {
    mnDeleteObject(input: $input) {
      clientMutationId
    }
  }
`;

const FIND_WIDGETS = gql`
  query getObject($filter: ObjectFilter!) {
    objects(filter: $filter) {
      objectsToObjectsByObject1Id {
        object2 {
          id
        }
      }
    }
  }
`;

const DeleteGroupsModal = (props) => {
  const [deleteObjects] = useMutation(REMOVE);
  const [loadAllObjects, { data }] = useLazyQuery(FIND_WIDGETS, {
    variables: {
      filter: {
        or: props.ids.map((item) => ({ id: { equalTo: item } })),
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data: dashboardData, refetch } = useQuery(DASHBOARD_QUERY, {
    variables: {
      dashboardId: props.dashboardId,
    },
  });

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);

  const handleClose = () => reject();

  const handleSave = () => {
    const targetLayout = dashboardData?.dashboard.layouts[0];
    const newLayout = targetLayout.value.lg.filter((item) => !props.ids.includes(item.i));

    const arrayOfIds = data.objects.map((item) => [...item.objectsToObjectsByObject1Id.map((obj) => obj.object2.id)]);
    let ids = [...props.ids];

    arrayOfIds.forEach((arr) => {
      ids = [...ids, ...arr];
    });

    props.setItemsChecked([]);

    submit();

    toast
      .promise(
        Promise.all([
          updateProperties({
            variables: {
              input: {
                propertiesArray: [
                  {
                    propertyId: targetLayout.id,
                    value: {
                      lg: newLayout,
                      md: newLayout,
                      sm: newLayout,
                      xs: newLayout,
                      xxs: newLayout,
                    },
                  },
                ],
              },
            },
          }),
          deleteObjects({
            variables: {
              input: {
                mnPatch: ids.map((item) => ({ id: item })),
              },
            },
          }),
        ]),
        {
          loading: 'Deleting groups ...',
          success: () => `Groups deleted`,
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => {
        refetch({
          dashboardId: props.dashboardId,
        });
      });
  };

  useEffect(() => {
    loadAllObjects();
  }, []);

  return (
    <ConfirmationModal
      modalOpen={props.isOpen}
      title={msg.deleteGroupsModal.deleteGroups}
      handleClose={handleClose}
      buttons={
        <>
          <Button color="inherit" onClick={handleClose}>
            {msg.deleteGroupsModal.buttonCancel}
          </Button>
          <Button color="primary" onClick={handleSave}>
            {msg.deleteGroupsModal.buttonDelete}
          </Button>
        </>
      }
    >
      {msg.formatString(msg.deleteGroupsModal.text, {
        containersCount: props.ids ? props.ids.length : 0,
      })}
    </ConfirmationModal>
  );
};

export default create(DeleteGroupsModal);
