/*
CustomObjectProperty - editing object properties. Two selects - Source (object) and Property (property of object) and Clear button
*/

import { useApolloClient } from '@apollo/client';
import Grid from '@mui/material/Grid';
import { useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import CustomAutocomplete from '../components/CustomAutocomplete';
import { msg } from '../constants/messages';
import { OBJECTS_QUERY, OBJECT_PROPS_QUERY, OBJECT_PROP_QUERY } from '../graphql/queries';
import { castToType } from '../utils/misc.js';

const CustomObjectProperty = (props) => {
  const client = useApolloClient();

  const [listOfSources, setListOfSources] = useState([]);
  const [listOfProps, setListOfProps] = useState([]);

  let defaultValues = {};

  // for forms

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  // get props of device on device selection
  const handleDeviceChange = async (e) => {
    //

    if (e.target.value === '') {
      setListOfProps([]);
      return;
    }

    try {
      const result = await client.query({
        query: OBJECT_PROPS_QUERY,
        variables: { deviceId: e.target.value },
        fetchPolicy: 'network-only',
      });

      setListOfProps(
        result.data.objectProperties.map((item) => {
          return {
            value: item.id,
            title: `${item.groupName} / ${item.spec.description}`,
          };
        })
      );
    } catch (err) {
      toast.error(err.toString());
    } finally {
    }
  }; //handleDeviceChange

  useEffect(() => {
    const queryProps = async () => {
      try {
        //

        const result_objects = await client.query({
          query: OBJECTS_QUERY,
          variables: {},
          fetchPolicy: 'network-only',
        });

        setListOfSources(
          result_objects.data.objects.map((item) => {
            return { value: item.id, title: item.name };
          })
        );

        // if there is initial value in db, load list of properties and set current Property select item to it
        if (props.value) {
          // get object id by property id (to set current Source select item)
          const result_objectAndProp = await client.query({
            query: OBJECT_PROP_QUERY,
            variables: {
              propId: props.value,
            },
            fetchPolicy: 'network-only',
          });

          // get list of properties for selected object (if selected) to fill Property select
          const result_linked_property = await client.query({
            query: OBJECT_PROPS_QUERY,
            variables: {
              deviceId: result_objectAndProp.data.objectProperty.objectId,
            },
            fetchPolicy: 'network-only',
          });

          setListOfProps(
            result_linked_property.data.objectProperties.map((item) => {
              return {
                value: item.id,
                title: `${item.groupName} / ${item.spec.description}`,
              };
            })
          );

          // set current item from list of objects
          defaultValues['source'] = result_objectAndProp.data.objectProperty.objectId;

          // set current item from list of properties
          defaultValues['property'] = props.value; // property id
        } //if
        else {
          // unset both selects because there is no value in property
          defaultValues['source'] = null;
          defaultValues['property'] = null;
        }

        setValues(defaultValues);
      } catch (err) {
        toast.error(err.toString());
      } finally {
      }
    }; //queryProps

    queryProps().then(() => {});
  }, []);

  useEffect(() => {
    if (props.setButtonSaveDisabled) {
      if (values['source'] && !values['property']) {
        props.setButtonSaveDisabled(true);
      } else {
        props.setButtonSaveDisabled(false);
      }
    }
  }, [values]);

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (name === 'source' && value) handleDeviceChange(e).then(() => {});
    if (name === 'property') handleInputChangeCasted(e, props.propType);

    // if Source select is empty, reset Property select as well
    if (name === 'source' && !value) {
      props.onChange({
        target: { name: 'source', value: null, checked: null },
      });
      props.onChange({
        target: { name: 'property', value: null, checked: null },
      });
      setValues({ source: null, property: null });
      return;
    }

    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const handleInputChangeCasted = (event, type) => {
    let { value, checked } = event.target;
    const e = {
      target: {
        name: props.name,
        value: castToType(value, type),
        checked: checked,
      },
    };

    props.onChange(e);
  };

  return (
    <>
      <Grid container direction="column" spacing={2} id={`${props.name}-label`}>
        <Grid item>
          <CustomAutocomplete
            name="source"
            label={msg.editWidgetSourceModal.source}
            list={listOfSources}
            value={values['source'] ?? ''}
            onChange={handleInputChange}
            clearFieldIcon={true}
          />
        </Grid>

        <Grid item>
          <CustomAutocomplete
            name="property"
            label={msg.editWidgetSourceModal.property}
            list={listOfProps}
            value={values['property'] ?? ''}
            onChange={handleInputChange}
            clearFieldIcon={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CustomObjectProperty;
