import { createSvgIcon } from '@mui/material';

const percentIcon = createSvgIcon(
  <path
    d="M15.5 0.5L17.5 2.5L2.5 17.5L0.5 15.5L15.5 0.5ZM4 1C5.66 1 7 2.34 7 4C7 5.66 5.66 7 4 7C2.34 7 1 5.66 1 4C1 2.34 2.34 1 4 1ZM14 11C15.66 11 17 12.34 17 14C17 15.66 15.66 17 14 17C12.34 17 11 15.66 11 14C11 12.34 12.34 11 14 11ZM4 3C3.45 3 3 3.45 3 4C3 4.55 3.45 5 4 5C4.55 5 5 4.55 5 4C5 3.45 4.55 3 4 3ZM14 13C13.45 13 13 13.45 13 14C13 14.55 13.45 15 14 15C14.55 15 15 14.55 15 14C15 13.45 14.55 13 14 13Z"
    fill="currentColor"
  />,
  'percentIcon'
);

export default percentIcon;
