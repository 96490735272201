import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { create } from 'react-modal-promise';
import * as yup from 'yup';
import { msg } from '../../constants/messages';
import useIsFieldRequired from '../../hooks/formik/useIsFieldRequired';
import { isCounter } from '../../utils/objectType';
import CommonModal from '../CommonModal';
import CustomAutocomplete from '../CustomAutocomplete';
import CustomInput from '../CustomInput';
import CustomSwitch from '../CustomSwitch';

const UPDATE_OBJECT = gql`
  mutation update($input: UpdateObjectInput!) {
    updateObject(input: $input) {
      clientMutationId
    }
  }
`;

const GET_SCHEMAS = gql`
  query listSchemas($schemaType: SchemaTypes!) {
    schemata(filter: { type: { equalTo: $schemaType } }) {
      name
      id
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const EditDevice = (props) => {
  const [updateObject, { loading }] = useMutation(UPDATE_OBJECT);
  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const [, setName] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [description, setDescription] = useState('');

  // settings
  const [schemaId, setSchemaId] = useState('');
  const [linkedOnly, setLinkedOnly] = useState(false);

  const [loadSchemas, { data }] = useLazyQuery(GET_SCHEMAS, {
    variables: {
      schemaType: 'DEVICE',
    },
    fetchPolicy: 'cache-and-network',
  });

  const schemaIdProperty = () => {
    return props.object.objectProperties.find((item) => item.key === 'settingsSchemaid');
  };

  const linkedOnlyProperty = () => {
    return props.object.objectProperties.find((item) => item.key === 'settingsLinkedOnly');
  };

  const validationSchema = yup.object({
    name: yup.string().trim().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: props.object.name,
    },
    validationSchema: validationSchema,
    onSubmit: (valuesForm, actions) => {
      handleSave(valuesForm).then(() => {
        actions.resetForm();
      });
    },
  });

  useEffect(() => {
    setName(props.object.name);
    setEnabled(props.object.enabled);
    setDescription(props.object.description || '');
    loadSchemas();

    if (isCounter(props.object.schemaTags)) {
      setSchemaId(schemaIdProperty().value);
      setLinkedOnly(linkedOnlyProperty().value);
    }
  }, [props.object.id]);

  const schemaNames = () => {
    if (data) {
      return data.schemata.map((item) => {
        return { value: item.id, title: item.name };
      });
    }
    return [];
  };

  const handleSave = async (valuesForm) => {
    await updateObject({
      variables: {
        input: {
          id: props.object.id,
          patch: {
            enabled,
            description,
            name: valuesForm.name,
          },
        },
      },
    })
      .then(() => {
        if (isCounter(props.object.schemaTags)) {
          return Promise.allSettled([
            updateProperty({
              variables: {
                input: {
                  id: schemaIdProperty().id,
                  patch: {
                    value: schemaId,
                  },
                },
              },
            }),
            updateProperty({
              variables: {
                input: {
                  id: linkedOnlyProperty().id,
                  patch: {
                    value: linkedOnly,
                  },
                },
              },
            }),
          ]);
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        handleClose();
      });
  };

  const submit = () => props.onResolve();

  const handleClose = () => submit();

  const isFieldRequired = useIsFieldRequired(validationSchema);

  return (
    <CommonModal
      key="EditDevice"
      modalOpen={props.isOpen}
      title={msg.editDeviceModal.title}
      handleClose={handleClose}
      buttons={
        <>
          <Button onClick={handleClose} color="inherit" data-test-button="objectEditCancel">
            {msg.default.cancel}
          </Button>
          <Button data-test-button="objectEditAdd" color="primary" disabled={loading} onClick={formik.handleSubmit}>
            {formik.isSubmitting ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CustomInput
            data-test-name="objectName"
            name="name"
            label={msg.addNewObjectModal.name}
            clearFieldIcon={true}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            required={isFieldRequired('name')}
          />
        </Grid>
        <Grid item container xs={12} justifyContent="space-between" alignItems="center">
          <CustomSwitch
            data-test-switch="objectSwitch"
            name="notificationsEmailNotifications"
            label={msg.addNewObjectModal.enabled}
            value={enabled}
            onChange={() => setEnabled(!enabled)}
          />
        </Grid>
        <Grid item>
          <Typography color="primary" variant="subtitle2" style={{ marginBottom: '12px' }}>
            {msg.addNewObjectModal.description}
          </Typography>
          <CustomInput
            data-test-description="objectDescription"
            name="name"
            label={msg.addNewObjectModal.description}
            clearFieldIcon={true}
            value={description}
            multiline={true}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Grid>

        {isCounter(props.object.schemaTags) && (
          <>
            <Grid item>
              <Typography color="primary" variant="subtitle2" style={{ marginBottom: '12px' }}>
                Settings
              </Typography>
            </Grid>
            <Grid item>
              <CustomAutocomplete
                name="schemaName"
                label={msg.addNewObjectModal.schemaName}
                list={schemaNames()}
                value={schemaId}
                onChange={(e) => setSchemaId(e.target.value)}
              />
            </Grid>
            <Grid item container xs={12} justifyContent="space-between" alignItems="center">
              <CustomSwitch
                name="notificationsEmailNotifications"
                label={msg.addNewObjectModal.linkedOnly}
                value={linkedOnly}
                onChange={() => setLinkedOnly(!linkedOnly)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </CommonModal>
  );
};

export default create(EditDevice);
