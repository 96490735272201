import { gql, useMutation } from '@apollo/client';
import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { create } from 'react-modal-promise';
import { msg } from '../../constants/messages';
import CommonModal from '../CommonModal';
import CustomInput from '../CustomInput';

const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      clientMutationId
    }
  }
`;

const ExecuteControl = ({ control, controls, objId, onResolve, isOpen }) => {
  const [createExecution, { loading }] = useMutation(CREATE_EXECUTION);
  const [values, setValues] = useState(controls.map((item) => ({ ...item, value: '' })));

  const submit = () => onResolve();

  const handleClose = () => submit();

  const handleEdit = (value, index) => {
    const newValues = [...values];
    newValues[index].value = value;
    setValues(newValues);
  };
  const handleSave = async () => {
    let params = {};

    values.forEach((item) => {
      params[item.argument] = item.value;
    });

    createExecution({
      variables: {
        input: {
          controlExecution: {
            objectId: objId,
            params: params,
            name: control.argument,
          },
        },
      },
    })
      .then(() => submit())
      .catch(() => {});
  };

  return (
    <CommonModal
      key="ExecuteControl"
      modalOpen={isOpen}
      title={control.description || control.argument}
      handleClose={handleClose}
      buttons={
        <>
          <Button color="inherit" onClick={handleClose}>
            {msg.default.cancel}
          </Button>
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={23} /> : msg.default.execute}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        {values.map((control, index) => {
          return (
            <Grid item>
              <CustomInput
                key={control.argument}
                name={control.argument}
                label={control.description}
                placeholder={control.argument}
                clearFieldIcon={true}
                value={control.value}
                onChange={(e) => handleEdit(e.target.value, index)}
              />
            </Grid>
          );
        })}

        {!values.length && (
          <Typography
            variant="body2"
            style={{
              height: '40px',
              paddingLeft: '8px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            No arguments
          </Typography>
        )}
      </Grid>
    </CommonModal>
  );
};

export default create(ExecuteControl);
