import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Zoom } from '@mui/material';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',

    // desktops
    [theme.breakpoints.up('sm')]: {
      left: '335px',
      bottom: '15px',
    },

    // mobiles
    [theme.breakpoints.down('sm')]: {
      right: '25px',
      bottom: '90px',
    },
  },
}));

function TopButton(props) {
  const theme = useTheme();
  const classes = useStyles();
  //const trigger = useScrollTrigger({    disableHysteresis: true,    threshold: 100  });
  //const trigger = true;

  const handleClick = () => {
    //    const anchor = (event.target.ownerDocument || document).querySelector('#top');
    //  if (anchor) {
    //anchor.scrollIntoView({ behavior: 'auto', block: 'center' });
    // props.topRef.current.scrollIntoView({ behavior: "auto", block: "center" });
    //}
  };

  return (
    <Zoom in={props.in}>
      <div className={classes.root}>
        <Fab
          onClick={handleClick}
          style={{ backgroundColor: theme.palette.white }}
          size="small"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUp />
        </Fab>
      </div>
    </Zoom>
  );
}

export default TopButton;
