import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useReducer, useState } from 'react';
import { Renderable, toast, ValueOrFunction } from 'react-hot-toast';
import { create, InstanceProps } from 'react-modal-promise';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import ImageItem from '../../../components/common/image-item/ImageItem';
import CommonModal from '../../../components/CommonModal';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomInput from '../../../components/CustomInput';
import CustomSelect from '../../../components/CustomSelect';
import { BOARD_BG_OPTIONS, TITLE_STYLE } from '../../../components/dashboard/constants';
import DeleteReportModal from '../../../components/DeleteReportModal';
import SelectColor from '../../../components/forms/SelectColor';
import useCustomNavigate from '../../../components/hooks/useCustomNavigate';
import useHandleCopy from '../../../components/side-card/menu/handlers/useHandleCopy';
import RpcSubscribeWrapper from '../../../components/side-card/static-table/RpcSubscribeWrapper';
import { msg } from '../../../constants/messages';
import useIsFieldRequired from '../../../hooks/formik/useIsFieldRequired';
import { setSettings } from '../../../store/settingsSlice';
import useMedia from '../../../utils/useMedia';
import { COLLECTIONS_QUERY_FULL, GET_COLLECTIONS } from '../../collections/api/GetCollections';
import { LINK_OBJECTS, UNLINK_OBJECTS } from '../../collections/api/LinkObjects';
import { ADD_REPORT } from '../api/AddReport';
import { CREATE_EXECUTION } from '../api/CreateExecution';
import { LOAD_GROUPS } from '../api/GetGroups';
import { GET_REPORT_FULL } from '../api/GetReport';
import { GET_REPORT_PROPS } from '../api/GetReportProps';
import { REPORTS_QUERY_WITHOUT_COLLECTIONS } from '../api/ReportsList';
import { UPDATE_PROPERTIES } from '../api/UpdateProperties';

type AddReportModalProps = {
  reportId?: unknown;
};

type TValuesDefault = {
  generalBackgroundImageName?: string | null;
  generalBackgroundImageUid?: string | null;
  generalBgColor?: string;
  generalTitle?: string;
  generalTitleStyle?: string;
  [key: string]: unknown;
};

const AddReportModal = ({
  onResolve: submit,
  onReject: reject,
  reportId,
  isOpen,
}: AddReportModalProps & InstanceProps<{ reportId: string }>) => {
  const isEdit = Boolean(reportId);
  const history = useCustomNavigate();
  const dispatch = useDispatch();
  const { uploadMedia } = useMedia();
  const handleCopy = useHandleCopy();

  const handleClose = () => reject();

  const [addDashboard] = useMutation(ADD_REPORT);
  const groupsQuery = useQuery(LOAD_GROUPS);
  const [linkObjects] = useMutation(LINK_OBJECTS);
  const [unlinkObjects] = useMutation(UNLINK_OBJECTS);
  const collectionsQuery = useQuery(GET_COLLECTIONS);
  const [collectionsLazyQuery] = useLazyQuery(COLLECTIONS_QUERY_FULL, {
    fetchPolicy: 'network-only',
  });
  const [reportQuery, { data: reportData }] = useLazyQuery(GET_REPORT_FULL);
  const [updateProperties] = useMutation(UPDATE_PROPERTIES);
  const [createExecution] = useMutation(CREATE_EXECUTION);

  const [accessRights, setAccessRights] = useState({
    readerGroup: null,
    userGroup: null,
    editorGroup: null,
  });
  const [description, setDescription] = useState<string>('');
  const [collection, setCollection] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    generalBackgroundImageName: null,
    generalBackgroundImageUid: null,
    generalBgColor: '#ffffff',
    generalTitle: '',
    generalTitleStyle: 'dark',
  };

  const [backgroundFileItem, setBackgroundFileItem] = useState(null);

  const valuesReducer = (prev: TValuesDefault, updated: TValuesDefault): TValuesDefault => ({
    ...prev,
    ...updated,
  });

  const [values, setValues] = useReducer(valuesReducer, defaultValues);

  const [reportsLazyQuery] = useLazyQuery(REPORTS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: 'network-only',
  });

  const validationSchema = yup.object({
    name: yup.string().trim().required('Name is required'),
  });

  const afterCreate = (id) => {
    history(`/reports/${id}`);
    dispatch(setSettings({ isEditMode: true }));
    submit();
  };

  const uploadImage = async (): Promise<string | null> => {
    if (!backgroundFileItem) {
      return null;
    }

    try {
      const { data }: { data: string } = await uploadMedia(backgroundFileItem);
      return data;
    } catch (e) {
      return null;
    }
  };

  const handleUpdateReport = async (formikValues) => {
    const generalBackgroundImageUid = await uploadImage();

    const payload = {
      objectId: reportId,
      name: formikValues.name,
      description,
      keyedProperties: [
        {
          propertyKey: 'generalTitle',
          value: values.generalTitle,
        },
        {
          propertyKey: 'generalTitleStyle',
          value: values.generalTitleStyle,
        },
        {
          propertyKey: 'generalBgColor',
          value: values.generalBgColor,
        },
        {
          propertyKey: 'generalBackgroundImageUid',
          value: generalBackgroundImageUid || values.generalBackgroundImageUid,
        },
        {
          propertyKey: 'generalBackgroundImageName',
          value: values.generalBackgroundImageName,
        },
      ],
      editorgroup: accessRights.editorGroup,
      usergroup: accessRights.userGroup,
      readergroup: accessRights.readerGroup,
    };

    toast
      .promise(
        updateProperties({
          variables: {
            input: {
              detailedObject: [payload],
            },
          },
        }).then(() => reportsLazyQuery()),
        {
          loading: 'Updating...',
          success: () => 'Updated',
          error: (err: ValueOrFunction<Renderable, unknown>) => {
            if (typeof err === 'string') {
              return err.toString();
            } else {
              return 'Error appears during editing of report';
            }
          },
        }
      )
      .then(async () => {
        const currentCollectionLinkId = reportData?.object?.collections?.[0]?.id;
        if (collection) {
          if (currentCollectionLinkId) {
            await unlinkObjects({
              variables: {
                linkId: currentCollectionLinkId,
              },
            });
          }
          await linkObjects({
            variables: {
              widgetId: reportData.object.id,
              objectId: collection,
            },
          });
          await collectionsLazyQuery();
          await reportsLazyQuery();
        } else {
          if (currentCollectionLinkId) {
            await unlinkObjects({
              variables: {
                linkId: currentCollectionLinkId,
              },
            });
          }
          await collectionsLazyQuery();
          await reportsLazyQuery();
        }
      })
      .then(() => {
        submit();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddDashboard = async (formikValues) => {
    const generalBackgroundImageUid = await uploadImage();

    const payload = {
      name: formikValues.name,
      values: [
        {
          propertyKey: 'generalTitle',
          value: values.generalTitle,
        },
        {
          propertyKey: 'generalTitleStyle',
          value: values.generalTitleStyle,
        },
        {
          propertyKey: 'generalBgColor',
          value: values.generalBgColor,
        },
        {
          propertyKey: 'generalBackgroundImageUid',
          value: generalBackgroundImageUid || values.generalBackgroundImageUid,
        },
        {
          propertyKey: 'generalBackgroundImageName',
          value: values.generalBackgroundImageName,
        },
      ],
      editorGroup: accessRights.editorGroup,
      userGroup: accessRights.userGroup,
      readerGroup: accessRights.readerGroup,
      description,
    };

    toast
      .promise(
        addDashboard({
          variables: payload,
        }),
        {
          loading: 'Creating report...',
          success: () => 'Report created',
          error: (err: ValueOrFunction<Renderable, unknown>) => {
            if (typeof err === 'string') {
              return err.toString();
            } else {
              return 'Error appears during creation of report';
            }
          },
        }
      )
      .then(async ({ data }) => {
        if (collection) {
          const variables = {
            widgetId: data.createObjectWithProperties.uuid,
            objectId: collection,
          };
          await linkObjects({ variables });
          await collectionsLazyQuery();
          await reportsLazyQuery();
        } else {
          await reportsLazyQuery();
        }
        afterCreate(data.createObjectWithProperties.uuid);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema,
    onSubmit: async (formikValues) => {
      setIsLoading(true);
      if (isEdit) {
        await handleUpdateReport(formikValues);
      } else {
        await handleAddDashboard(formikValues);
      }
    },
  });

  const [dashboardsQueryProps, { loading }] = useLazyQuery(GET_REPORT_PROPS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ schemata }) => {
      const localValues = { ...defaultValues };
      schemata[0].schemaProperties.forEach((prop) => {
        localValues[prop.key] = prop.defaultValue;
      });
      setValues(localValues);
      formik.setValues({ name: `Report #${schemata[0].objectsCount + 1}` }).catch(() => {});
    },
  });

  const getGroups = () => {
    if (!groupsQuery.loading && groupsQuery.data) {
      return groupsQuery.data.userGroups.map((item) => ({
        value: item.id,
        title: item.groupName,
        disabled: item.groupName === 'Nobody',
      }));
    }
    return [];
  };

  const handleInputChange = (e) => {
    let { value } = e.target;
    const { checked } = e.target;
    if (checked) value = checked;

    setValues({ [e.target.name]: value });
  };

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: 'CopyReport',
            objectId: reportId,
            params: {
              UUID: reportId,
              NAME: `${formik.values.name} copy`,
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (isEdit) {
      reportQuery({
        variables: {
          objId: reportId,
        },
        fetchPolicy: 'network-only',
      })
        .then(({ data }) => {
          if (data) {
            data.object.objectProperties.forEach((prop) => {
              defaultValues[prop.key] = prop.value;
            });

            setCollection(String(data?.object?.collections?.[0]?.target?.id) ?? '');
            setValues(defaultValues);
            setAccessRights({
              readerGroup: data.object.readergroup,
              userGroup: data.object.usergroup,
              editorGroup: data.object.editorgroup,
            });
            setDescription(data?.object?.description ?? '');
            formik.setValues({ name: data.object.name }).catch(() => {});
          }
        })
        .catch(() => {});
    } else {
      dashboardsQueryProps().catch(() => {});
    }
  }, [reportId]);

  const getCollections = () => {
    if (!collectionsQuery.loading && collectionsQuery.data) {
      return collectionsQuery.data?.collections?.map((item) => ({
        value: item.id,
        title: item.name,
      })) as Array<{ value: string; title: string }>;
    }
    return [];
  };

  const isFieldRequired = useIsFieldRequired(validationSchema);

  return (
    <>
      <CommonModal
        loading={loading || isLoading}
        modalOpen={isOpen}
        title={isEdit ? 'Edit report' : 'Add report'}
        forceTitle={true}
        contentStyles={{
          padding: '14px 16px 16px 14px',
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.addDashboardModal.buttonCancel}
            </Button>
            <Button color="primary" data-test="createReport" onClick={() => formik.handleSubmit()}>
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              name="name"
              required={isFieldRequired('name')}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              label="Name"
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item>
            <CustomInput
              name="generalTitle"
              label="Title"
              clearFieldIcon={true}
              value={values.generalTitle}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={collectionsQuery.loading}
              label={'Collection'}
              list={getCollections()}
              value={collection}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCollection(e.target.value);
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <CustomSelect
                name="generalTitleStyle"
                label="Title style"
                list={TITLE_STYLE}
                value={values.generalTitleStyle ?? 'dark'}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectColor
                name="generalBgColor"
                label={'Background color'}
                value={values.generalBgColor ?? '#333333'}
                list={BOARD_BG_OPTIONS}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item>
            <ImageItem
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setValues({ generalBackgroundImageUid: e.target.value });
              }}
              onDelete={() => {
                setValues({ generalBackgroundImageUid: null });
              }}
              onSelectFile={(file) => {
                setBackgroundFileItem(file);
              }}
              id={values.generalBackgroundImageUid}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Access rights
            </Typography>
          </Grid>

          <Grid item>
            <CustomAutocomplete
              label={'Editors group'}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.editorGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  editorGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={groupsQuery.loading}
              label={'Users group'}
              list={getGroups()}
              value={accessRights.userGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  userGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              label={'Readers group'}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.readerGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  readerGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Description
            </Typography>
          </Grid>

          <Grid item>
            <CustomInput
              name="description"
              label={msg.addDashboardModal.description}
              clearFieldIcon={true}
              value={description}
              multiline={true}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>

          {isEdit && (
            <>
              <Grid item>
                <Button
                  data-test="copyDashboardId"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disableElevation
                  style={{ marginTop: '20px' }}
                  onClick={() => {
                    handleCopy({
                      object: {
                        name: '',
                      },
                      message: 'Report copied successfully',
                      text: reportId,
                    });
                  }}
                >
                  {msg.editDashboardModal.buttonCopy}
                </Button>

                <RpcSubscribeWrapper
                  rpcName={'CopyReport'}
                  objectId={reportId}
                  object={null}
                  handler={rpcHandler}
                  title={'Copy Report'}
                  successCb={(id: string) => {
                    window.location.href = `/reports/${id}`;
                  }}
                >
                  <LoadingButton
                    data-test-copy-report-rpc
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disableElevation
                    style={{ marginTop: '20px' }}
                  ></LoadingButton>
                </RpcSubscribeWrapper>

                <Button
                  data-test="deleteReport"
                  variant="contained"
                  color="error"
                  fullWidth
                  disableElevation
                  style={{ marginTop: '16px' }}
                  onClick={() => {
                    DeleteReportModal({
                      open: true,
                      dashboardId: reportId,
                      name: formik.values.name,
                    })
                      .then(() => {
                        submit();
                      })
                      .catch(() => {});
                  }}
                >
                  Delete report
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(AddReportModal);
