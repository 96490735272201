import { useQuery } from '@apollo/client';
import { styled, useTheme } from '@mui/system';
import { gql } from '../__generated__';

import { CollectionsBookmark } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import { Accordion, AccordionDetails, AccordionSummary, Box, ListItemButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import orderBy from 'lodash/orderBy';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as ImgLogo } from '../assets/logo.svg';
import { ReactComponent as ImgLogoDark } from '../assets/logo_dark.svg';
import { DOC_URL } from '../constants/constants';
import { msg } from '../constants/messages';
import { COLLECTIONS_QUERY_FULL } from '../modules/collections/api/GetCollections';
import { REPORTS_QUERY_WITHOUT_COLLECTIONS } from '../modules/reports/api/ReportsList';
import AddReportModal from '../modules/reports/components/AddReportModal';
import { useAppSelector } from '../store/hooks';
import { setSettings } from '../store/settingsSlice';
import useMedia from '../utils/useMedia';
import AboutModal from './AboutModal';
import AddDashboardModal from './dashboard/AddDashboardModal';
import ReportIcon from './icons/reportIcon';
import ProfileModalNew from './modals/ProfileModal';
import SettingsModal from './SettingsModal';
import useUniversalMenu from './side-card/menu/useUniversalMenu';
import useMoreMenu from './useMoreMenu';

const PREFIX = 'MainSideMenu';

const classes = {
  content: `${PREFIX}-content`,
  logo: `${PREFIX}-logo`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  listItemText: `${PREFIX}-listItemText`,
  itemToHideOrShow: `${PREFIX}-itemToHideOrShow`,
  link: `${PREFIX}-link`,
  itemToHover: `${PREFIX}-itemToHover`,
  accordionRoot: `${PREFIX}-accordionRoot`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.listItemIcon}`]: {
    minWidth: '50px',
  },

  [`& .${classes.listItemText}`]: {
    paddingRight: theme.spacing(3),
  },

  [`& .MuiListItemSecondaryAction-root`]: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'opacity 0.2s linear',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      opacity: 1,
    },
  },

  [`& .${classes.link}`]: {
    display: 'block',
    width: '100%',
    textDecoration: 'none',
    color: 'inherit',
    '* &:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
    },
  },

  [`& .${classes.itemToHover}`]: {
    '&:hover .MuiListItemSecondaryAction-root': {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.2s linear',
    },
  },

  [`& .${classes.accordionRoot}`]: {
    margin: 0,
    backgroundColor: 'transparent',
    '&::before': {
      opacity: 0,
      display: 'none',
    },
  },

  [`& .MuiAccordionSummary-content`]: {
    margin: 0,
  },
}));

const DASHBOARDS_QUERY_WITHOUT_COLLECTIONS = gql(/* GraphQL */ `
  query getDashboardsWithoutCollections {
    dashboards: objects(
      filter: {
        schemaTags: { contains: ["application", "board", "dashboard"] }
        objectsToObjectsByObject1IdConnection: {
          every: { object2: { not: { schemaTags: { contains: ["collection"] } } } }
        }
      }
      orderBy: NAME_ASC
    ) {
      id
      name
      ...LayoutObject
      ...ObjectParts
      objectsToObjectsByObject1Id {
        id
        object2 {
          id
          name
          schemaTags
        }
      }
      id
      name
      schemaTags
    }
  }
`);

interface IProps {
  account: boolean;
  setDrawerOpen: () => void;
}

const TestComponent = ({ dashboard }: { dashboard: any }) => {
  const { menuBasedOnType } = useUniversalMenu();
  const { MoreMenu, openMoreMenu } = useMoreMenu();

  return (
    <ListItemSecondaryAction className={classes.itemToHideOrShow}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-return */}
      <MoreMenu items={() => menuBasedOnType(dashboard).slice(2)} />
      <IconButton
        data-test-edit-board={dashboard?.name}
        edge="end"
        size="small"
        aria-label="edit"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openMoreMenu(e);
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
    </ListItemSecondaryAction>
  );
};

const MainSideMenu = (props: IProps) => {
  const theme = useTheme();
  const profile = useAppSelector((state) => state.profile);
  const { getImageById } = useMedia();

  const dispatch = useDispatch();

  const { data: dataDashboardsWithoutCollections, loading } = useQuery(DASHBOARDS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: 'network-only',
  });
  const { data: dataCollections } = useQuery(COLLECTIONS_QUERY_FULL, {
    fetchPolicy: 'network-only',
  });

  const { data: dataReports } = useQuery(REPORTS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: 'network-only',
  });

  const programCompanyLogoImageUID = profile?.programCompanylogoimageuid;
  const companyLogoUrl = getImageById(programCompanyLogoImageUID);

  const DefaultLogo = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '16px',
        paddingBottom: '8px',
      }}
    >
      {theme.palette.mode === 'dark' && <ImgLogoDark />}
      {theme.palette.mode === 'light' && <ImgLogo />}
    </div>
  );

  const LoadedLogoImg = styled('img')({
    width: '100%',
    objectFit: 'contain',
    height: '76px',
  });

  const LoadedLogo = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        paddingBottom: '4px',
      }}
    >
      <LoadedLogoImg src={companyLogoUrl}></LoadedLogoImg>
    </Box>
  );

  if (!profile || loading) return null;

  return (
    <Root>
      {programCompanyLogoImageUID ? <LoadedLogo /> : <DefaultLogo />}
      <List>
        <Divider />
        {props.account && <ProfileModalNew />}

        {dataCollections?.dashboards
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          .filter((item) => item.enabled)
          .map((collection) => (
            <Accordion key={collection.id} disableGutters elevation={0} classes={{ root: classes.accordionRoot }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                  paddingLeft: 0,
                  margin: 0,
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                  },
                }}
                aria-controls="panel1a-content"
              >
                <ListItem>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CollectionsBookmark />
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="subtitle2">{collection.name}</Typography>} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                {orderBy(collection.objectsToObjectsByObject2Id, ['object1.name'], ['asc'])
                  .filter((item) => item.object1)
                  .map((dashboard) => (
                    <Link
                      key={dashboard.object1.id}
                      to={`/${dashboard.object1.schemaTags.includes('report') ? 'reports' : 'boards'}/${
                        dashboard.object1.id
                      }`}
                      className={classes.link}
                      onClick={() => {
                        dispatch(
                          setSettings({
                            isDrawerOpen: false,
                            isEditMode: false,
                          })
                        );
                      }}
                    >
                      <ListItemButton
                        key={dashboard.object1.id}
                        classes={{
                          root: classes.itemToHover,
                        }}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: '51px',
                            paddingBottom: 0,
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          className={classes.listItemText}
                          primary={<Typography variant="subtitle2">{dashboard.object1.name}</Typography>}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            data-test-edit-board={dashboard.object1.name}
                            edge="end"
                            size="small"
                            aria-label="edit"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              AddDashboardModal({
                                isEdit: true,
                                dashboardId: dashboard.object1.id,
                              })
                                .then()
                                .catch(() => {});
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </Link>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
        <Accordion disableGutters elevation={0} classes={{ root: classes.accordionRoot }}>
          <AccordionSummary
            data-test="allDashboardsMenuItem"
            expandIcon={<ExpandMore />}
            classes={{ content: classes.content }}
            style={{ paddingLeft: 0, margin: 0 }}
            aria-controls="panel1a-content"
          >
            <ListItem>
              <ListItemIcon className={classes.listItemIcon}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle2">Dashboards</Typography>} />
            </ListItem>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            {dataDashboardsWithoutCollections.dashboards.map((dashboard) => {
              return (
                <Link
                  data-test-board={dashboard.name}
                  key={dashboard.id}
                  to={`/boards/${dashboard.id}`}
                  className={classes.link}
                  onClick={() => {
                    dispatch(
                      setSettings({
                        isDrawerOpen: false,
                        isEditMode: false,
                      })
                    );
                  }}
                >
                  <ListItemButton key={dashboard.id} className={classes.itemToHover}>
                    <ListItemIcon
                      style={{
                        minWidth: '51px',
                        paddingBottom: 0,
                      }}
                    ></ListItemIcon>
                    <ListItemText
                      className={classes.listItemText}
                      primary={
                        <Typography noWrap={true} variant="subtitle2">
                          {dashboard.name}
                        </Typography>
                      }
                    />
                    <TestComponent dashboard={dashboard} />
                  </ListItemButton>
                </Link>
              );
            })}
          </AccordionDetails>
        </Accordion>

        <ListItemButton
          data-test="addBoard"
          onClick={() => {
            AddDashboardModal()
              .then(() => {
                dispatch(setSettings({ isDrawerOpen: false }));
              })
              .catch(() => {});
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary={<Typography variant="subtitle2">{msg.mainSideMenu.addBoard}</Typography>}
          />
        </ListItemButton>

        <Divider />

        <Accordion disableGutters elevation={0} classes={{ root: classes.accordionRoot }}>
          <AccordionSummary
            data-test="allReportsMenuItem"
            expandIcon={<ExpandMore />}
            classes={{ content: classes.content }}
            style={{ paddingLeft: 0, margin: 0 }}
            aria-controls="panel1a-content"
          >
            <ListItem>
              <ListItemIcon className={classes.listItemIcon}>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle2">Reports</Typography>} />
            </ListItem>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            {dataReports?.reports?.map((report) => (
              <Link
                data-test-board={report.name}
                key={report.id}
                to={`/reports/${report.id}`}
                className={classes.link}
                onClick={() => {
                  dispatch(
                    setSettings({
                      isDrawerOpen: false,
                      isEditMode: false,
                    })
                  );
                }}
              >
                <ListItemButton key={report.id} className={classes.itemToHover}>
                  <ListItemIcon
                    style={{
                      minWidth: '51px',
                      paddingBottom: 0,
                    }}
                  ></ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary={
                      <Typography noWrap={true} variant="subtitle2">
                        {report.name}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction className={classes.itemToHideOrShow}>
                    <IconButton
                      data-test-edit-board={report.name}
                      edge="end"
                      size="small"
                      aria-label="edit"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        AddReportModal({
                          reportId: report.id,
                        })
                          .then()
                          .catch(() => {});
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItemButton>
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>

        <ListItemButton
          data-test="addReport"
          onClick={() => {
            AddReportModal()
              .then(() => {
                dispatch(setSettings({ isDrawerOpen: false }));
              })
              .catch(() => {});
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary={<Typography variant="subtitle2">Add report</Typography>}
          />
        </ListItemButton>

        <Divider />

        <Accordion disableGutters elevation={0} classes={{ root: classes.accordionRoot }}>
          <AccordionSummary
            data-test-menu="settings"
            expandIcon={<ExpandMore />}
            sx={{ paddingLeft: 0, margin: 0 }}
            classes={{ content: classes.content }}
          >
            <ListItem>
              <ListItemIcon className={classes.listItemIcon}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle2">{msg.mainSideMenu.settings}</Typography>} />
            </ListItem>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <ListItemButton
              data-test-menu="general"
              onClick={() => {
                SettingsModal()
                  .then()
                  .catch(() => {});
              }}
            >
              <ListItemIcon style={{ minWidth: '51px', paddingBottom: 0 }}></ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary={<Typography variant="subtitle2">General</Typography>}
              />
            </ListItemButton>
            <Link
              data-test-menu="collections"
              to={`/collections`}
              className={classes.link}
              onClick={() => {
                dispatch(setSettings({ isDrawerOpen: false }));
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ minWidth: '51px', paddingBottom: 0 }}></ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={<Typography variant="subtitle2">Collections</Typography>}
                />
              </ListItemButton>
            </Link>
          </AccordionDetails>
        </Accordion>

        <ListItemButton
          data-test-menu="about"
          onClick={() => {
            AboutModal()
              .finally(() => {})
              .then(() => {})
              .catch(() => {});
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary={<Typography variant="subtitle2">{msg.mainSideMenu.about}</Typography>}
          />
        </ListItemButton>

        <ListItemButton
          data-test-menu="help"
          component="a"
          href={window.__pixelConfig?.APP_DOC || DOC_URL}
          target="_blank"
        >
          <ListItemIcon className={classes.listItemIcon}>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary={<Typography variant="subtitle2">{msg.mainSideMenu.help}</Typography>}
          />
        </ListItemButton>
      </List>
    </Root>
  );
};

export default MainSideMenu;
