import WebSocketCustomLink from './WebSocketCustomLink';

export const wsLink = new WebSocketCustomLink({
  url: window.__pixelConfig.WS_API,
  on: {
    connected: () => {},
    connecting: () => {},
    message: () => {},
    error: (err) => {
      console.error(err);
    },
  },

  connectionParams: () => ({
    authorization: `Bearer ${localStorage.getItem('authToken')}`,
  }),
});
