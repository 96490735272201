import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useReducer } from 'react';
import { toast } from 'react-hot-toast';
import { create, InstanceProps } from 'react-modal-promise';
import { msg } from '../../../constants/messages';
import { WIDGET_QUERY } from '../../../graphql/queries';
import CommonModal from '../../CommonModal';
import CustomSelect from '../../CustomSelect';
import { defaultValues, defaultValuesReducer } from './constants';
import { ChangeAccessModalProps, GroupType } from './type';

const LOAD_GROUPS = gql`
  query loadGroups($first: Int, $offset: Int) {
    userGroups(first: $first, offset: $offset, orderBy: GROUP_NAME_ASC) {
      id
      isSystem
      groupName
      description
    }
  }
`;

const UPDATE_OBJECT = gql`
  mutation update($input: UpdateObjectInput!) {
    updateObject(input: $input) {
      clientMutationId
    }
  }
`;

const ChangeAccessModal = (props: ChangeAccessModalProps & InstanceProps<{}>) => {
  const {
    object: { userGroupByEditorgroup, userGroupByReadergroup, userGroupByUsergroup },
  } = props;

  const [loadWidget] = useLazyQuery(WIDGET_QUERY, {
    variables: {
      objId: props.object.id,
    },
    fetchPolicy: 'network-only',
  });

  const [values, setValues] = useReducer(defaultValuesReducer, defaultValues);

  const submit: () => void = () => props.onResolve();
  const handleClose = () => submit();

  const [updateObject, { loading: saveLoader }] = useMutation(UPDATE_OBJECT);

  const mapUserGroups = (groups: { groupName: string; id: string }[], disabledCondition = false): GroupType[] =>
    groups?.map((item) => ({
      title: item.groupName,
      value: item.groupName,
      disabled: disabledCondition && item.groupName === 'Nobody',
      id: item.id,
    }));

  const { loading } = useQuery(LOAD_GROUPS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ userGroups }: { userGroups: { groupName: string; id: string }[] }) => {
      const { groupName: editorsGroupName } = userGroupByEditorgroup || {};
      const { groupName: usersGroupName } = userGroupByUsergroup || {};
      const { groupName: readersGroupName } = userGroupByReadergroup || {};

      const updatedValues = {
        editorsGroup: editorsGroupName,
        usersGroup: usersGroupName,
        readersGroup: readersGroupName,
        list: mapUserGroups(userGroups),
        editorsList: mapUserGroups(userGroups, true),
      };

      setValues(updatedValues);
    },
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({ [name]: value });
  };

  const handleSave = () => {
    const findIdByTitle = (list: GroupType[], title?: string) => list?.find((item) => item.title === title)?.id;
    const patch = {
      editorgroup: findIdByTitle(values.editorsList, values.editorsGroup),
      usergroup: findIdByTitle(values.list, values.usersGroup),
      readergroup: findIdByTitle(values.list, values.readersGroup),
    };
    updateObject({
      variables: {
        input: {
          id: props.object.id,
          patch,
        },
      },
    })
      .then(() => {
        if (props.isWidget) {
          loadWidget()
            .then(() => {
              submit();
            })
            .catch(() => {});
        } else {
          submit();
        }
      })
      .catch((err) => {
        if (err instanceof Error) {
          toast.error(err.message);
        }
      });
  };

  return (
    <CommonModal
      key="ChangeAccessModal"
      modalOpen={props.isOpen}
      handleClose={handleClose}
      title="Access rights"
      loading={loading || saveLoader}
      buttons={
        <>
          <Button color="primary" onClick={handleClose}>
            {msg.changeAccessModal.buttonCancel}
          </Button>
          <Button color="primary" onClick={handleSave}>
            {msg.changeAccessModal.buttonSave}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CustomSelect
            name="editorsGroup"
            label="Editors group"
            list={values.editorsList}
            value={values.editorsGroup ?? ''}
            onChange={handleInputChange}
            clearFieldIcon={false}
          />
        </Grid>
        <Grid item>
          <CustomSelect
            name="usersGroup"
            label="Users group"
            list={values.list}
            value={values.usersGroup ?? ''}
            onChange={handleInputChange}
            clearFieldIcon={false}
          />
        </Grid>
        <Grid item>
          <CustomSelect
            name="readersGroup"
            label="Readers group"
            list={values.list}
            value={values.readersGroup ?? ''}
            onChange={handleInputChange}
            clearFieldIcon={false}
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(ChangeAccessModal);
