import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import useRoute from '../../hooks/useRoute';
import useMoreMenu from '../useMoreMenu';

const CardToolbarNormal = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'space-between',
      width: '412px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      padding: '16px',
    },
    btn: {
      background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.44)' : 'rgb(29,29,29, 0.4)',
      padding: '6px',
    },
    rightOffset: {
      marginRight: '10px',
    },
  }));
  const classes = useStyles();
  const { MoreMenu, openMoreMenu } = useMoreMenu();
  const { handleGoBack, handleGoStart, getTypeByRoute } = useRoute();

  const type = getTypeByRoute();

  return (
    <>
      <div className={classes.root}>
        {type === 'group' && (
          <IconButton className={classes.btn} disabled size="medium">
            <StarOutlineIcon />
          </IconButton>
        )}
        {type !== 'group' && (
          <IconButton data-test-card="back" className={classes.btn} onClick={handleGoBack} size="medium">
            <ArrowBackIcon />
          </IconButton>
        )}

        <MoreMenu items={props.menuItems} handleMenuItemClick={props.handleMenuItemClick} />
        <div>
          <IconButton
            data-test-card="contextMenu"
            style={{ marginRight: '10px' }}
            className={classes.btn}
            onClick={(e) => openMoreMenu(e)}
            size="medium"
          >
            <MoreVertIcon />
          </IconButton>
          <IconButton data-test-card="close" className={classes.btn} onClick={handleGoStart} size="medium">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

const CardToolbarFixed = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '412px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      padding: '16px',
      backgroundColor: theme.palette.white,
      zIndex: theme.zIndex.drawer - 1,
    },
    btn: {
      background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.44)' : 'rgb(29,29,29, 0.4)',
      padding: '6px',
    },
    rightOffset: {
      marginRight: '10px',
    },
  }));
  const classes = useStyles();
  const { MoreMenu, openMoreMenu } = useMoreMenu();
  const { handleGoBack, handleGoStart, getTypeByRoute } = useRoute();

  const type = getTypeByRoute();

  return (
    <>
      <div className={classes.root}>
        {type === 'group' && (
          <IconButton className={classes.btn} disabled size="medium">
            <StarOutlineIcon />
          </IconButton>
        )}
        {type !== 'group' && (
          <IconButton className={classes.btn} onClick={handleGoBack} size="medium">
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography noWrap={true} style={{ marginLeft: '12px' }} variant="h6">
          {props.item.name}
        </Typography>
        <span style={{ flexGrow: 1 }}></span>
        <MoreMenu items={props.menuItems} handleMenuItemClick={props.handleMenuItemClick} />
        <div style={{ flexShrink: 0 }}>
          <IconButton
            style={{ marginRight: '10px' }}
            className={classes.btn}
            onClick={(e) => {
              openMoreMenu(e);
            }}
            data-test-card="contextMenu"
            size="medium"
          >
            <MoreVertIcon />
          </IconButton>
          <IconButton className={classes.btn} onClick={handleGoStart} size="medium">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

const CardToolbar = (props) => {
  return (
    <>
      {props.isFixed && <CardToolbarFixed {...props}></CardToolbarFixed>}
      {!props.isFixed && <CardToolbarNormal {...props}></CardToolbarNormal>}
    </>
  );
};

export default CardToolbar;
