import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { msg } from '../../constants/messages';
import CustomAutocomplete from '../CustomAutocomplete';

export const SCHEMAS_QUERY = gql`
  query listSchemas($filter: SchemaFilter, $first: Int) {
    schemata(orderBy: NAME_ASC, filter: $filter, first: $first) {
      name
      id
    }
  }
`;

const schemasAutocomplete = (props) => {
  const [schemas, setSchemas] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const [filter, setFilter] = useState(null);

  const [schemasLoad, schemasResult] = useLazyQuery(SCHEMAS_QUERY, {
    onCompleted: (e) => {
      setSchemas(e.schemata.map((item) => ({ value: item.id, title: item.name })));

      if (props.schemaId) {
        setValue(props.schemaId);
      }
    },
    fetchPolicy: 'network-only',
    variables: {
      first: 20,
      filter,
    },
  });

  const handleChangeType = () => {
    const filter = {
      parentSchemaExists: false,
      objectsCount: {
        greaterThan: 0,
      },
    };

    if (props.schemaId) {
      filter.or = [
        {
          id: { equalTo: props.schemaId },
        },
        {
          name: {
            includesInsensitive: debouncedValue,
          },
        },
      ];
    } else {
      filter.name = {
        includesInsensitive: debouncedValue,
      };
    }

    if (props.type === 'monitor') {
      filter.type = {
        equalTo: 'DATASET',
      };
      filter.mTags = {
        contains: ['application', 'monitor', 'object'],
      };
    } else if (props.type === 'stat') {
      filter.type = {
        equalTo: 'DATASET',
      };
      filter.mTags = {
        contains: ['application', 'statistics'],
      };
    } else {
      filter.type = {
        equalTo: props.type.toUpperCase(),
      };
    }

    setFilter(filter);
  };

  useDebounce(
    () => {
      setDebouncedValue(inputValue);
    },
    500,
    [inputValue]
  );

  useEffect(() => {
    handleChangeType();
  }, [debouncedValue]);

  useEffect(() => {
    if (filter) {
      schemasLoad();
    }
  }, [filter]);

  useEffect(() => {
    setValue('');
    setSchemas([]);
    handleChangeType();
  }, [props.type]);

  useEffect(() => {
    if (props.schemaId && schemas.length > 0) {
      const targetValue = schemas.find((item) => item.value === props.schemaId);

      if (targetValue) {
        handleChange({
          target: {
            name: props.name,
            rawValue: targetValue,
            value: targetValue?.value,
          },
        });
      } else {
        handleChange({
          target: {
            name: null,
            rawValue: null,
            value: null,
          },
        });
      }
    }
  }, [schemas]);

  const handleInputChange = (e) => {
    if (e && e.currentTarget.value !== 0) {
      setInputValue(e.currentTarget.value.trim());
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    props.handleChange(e);
  };

  return (
    <>
      <CustomAutocomplete
        clearFieldIcon={true}
        error={props.error}
        helperText={props.helperText}
        filterOptions={(x) => x}
        name={props.name}
        label={props.label || msg.addNewObjectModal.schemaName}
        list={schemas}
        value={value}
        loading={schemasResult.loading}
        onChange={handleChange}
        onInputChange={handleInputChange}
        required={!!props?.required}
      />
    </>
  );
};

export default schemasAutocomplete;
