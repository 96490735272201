import { LazyQueryResultTuple, OperationVariables, useLazyQuery } from '@apollo/client';
import { CircularProgress, ListItem, Theme } from '@mui/material';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import { formatISO, sub } from 'date-fns';
import { cloneElement, FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  ObjectPropertiesHistoriesConnection,
  ObjectPropertiesHistoriesEdge,
  PageInfo,
} from '../../../__generated__/graphql';
import HistoryToolbar from './HistoryToolbar';
import { HistoryLayoutProps } from './HistoryTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: '0',
    // background: "white",
    width: '411px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingTop: '0',
    height: '100%',
    zIndex: '3',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    height: '100%',
    overflowY: 'auto',
  },
  header: {
    paddingRight: '4px',
    paddingLeft: '16px',
    marginTop: '0',
  },
  headerTitle: {
    width: '100%',
  },
}));

const HistoryLayout: FC<HistoryLayoutProps> = ({
  query,
  filter,
  id,
  object,
  item,
  processItem,
  prepareData,
  downloadHistory,
  downloadIds,
}) => {
  const [recordedFilter, setRecordedFilter] = useState([
    formatISO(sub(Date.now(), { days: 1 })),
    formatISO(Date.now()),
  ]);
  const [after, setAfter] = useState(null);
  const [loadHistory, { data }]: LazyQueryResultTuple<
    {
      objectPropertiesHistoriesConnection: ObjectPropertiesHistoriesConnection;
    },
    OperationVariables
  > = useLazyQuery(query, {
    variables: {
      first: 30,
      after,
      filter: {
        objectId: {
          equalTo: id,
        },
        recordedAt: {
          greaterThanOrEqualTo: recordedFilter[0],
          lessThanOrEqualTo: recordedFilter[1],
        },
        ...filter,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    loadHistory()
      .then(() => {})
      .catch(() => {});
  }, [id, recordedFilter]);

  const pageInfo = (datas: { objectPropertiesHistoriesConnection: ObjectPropertiesHistoriesConnection }): PageInfo => {
    if (datas) {
      return datas.objectPropertiesHistoriesConnection.pageInfo;
    }
    return null;
  };

  const fetchData = () => {
    if (data.objectPropertiesHistoriesConnection.pageInfo.hasNextPage) {
      setAfter(data.objectPropertiesHistoriesConnection.pageInfo.endCursor);
    }
  };

  const classes = useStyles();

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
      }}
      className={classes.root}
    >
      <HistoryToolbar
        name={object.name}
        recordedFilter={recordedFilter}
        setRecordedFilter={setRecordedFilter}
        setAfter={setAfter}
        downloadHistory={downloadHistory}
        downloadIds={downloadIds}
      />
      <div className={classes.content} id="scrollContainer">
        <List>
          <InfiniteScroll
            scrollableTarget="scrollContainer"
            dataLength={prepareData(data).length}
            next={fetchData}
            hasMore={pageInfo(data) ? pageInfo(data).hasNextPage : true}
            loader={
              <ListItem
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={24} />
              </ListItem>
            }
            endMessage={
              <Typography variant={'body1'} style={{ textAlign: 'center' }}>
                {prepareData(data).length === 0 && <span>No more data</span>}
              </Typography>
            }
          >
            {prepareData(data).map((historyItem: ObjectPropertiesHistoriesEdge, index: number) =>
              cloneElement(item, {
                item: processItem(historyItem, object),
                key: index,
              })
            )}
          </InfiniteScroll>
        </List>
      </div>
    </Box>
  );
};

export default HistoryLayout;
