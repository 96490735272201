import { useLazyQuery } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { Image } from 'mui-image';
import { ChangeEvent, useEffect, useState } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import * as yup from 'yup';
import CommonModal from '../../components/CommonModal';
import { msg } from '../../constants/messages';
import useFormikRequiredInputs from '../../hooks/formik/useFormikRequiredProps';
import useRoute from '../../hooks/useRoute';
import useMedia from '../../utils/useMedia';
import { WIDGETS_FOR_BOARD, WIDGETS_FOR_REPORT, WidgetTypesEnum } from '../../utils/widgetTypes';
import CustomInput from '../CustomInput';
import CustomSelect from '../CustomSelect';
import { GET_WIDGET_INFO } from './api/GetWidgetInfo';
import { useSelectWidget } from './useSelectWidget';

const validationSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
});

const SelectWidget = (props: { group: any } & InstanceProps<{}>) => {
  const { getImageById } = useMedia();

  const { selectByType } = useSelectWidget({ group: props.group });
  const { isBoard, isReport } = useRoute();

  const [widgetType, setWidgetType] = useState<WidgetTypesEnum>(
    isReport() ? WidgetTypesEnum.TITLE : WidgetTypesEnum.DATABOX
  );

  const [loadWidgetInfo, { data: widgetInfo, loading }] = useLazyQuery(GET_WIDGET_INFO);

  const isMinimap = () => props?.group.type[0]?.value === 'minimap';

  const getWidgetsList = () => {
    if (isBoard()) {
      return WIDGETS_FOR_BOARD;
    }

    if (isReport()) {
      return WIDGETS_FOR_REPORT.filter((item) => {
        if (!isMinimap()) {
          return !item.isMinimapOnly;
        } else {
          return item;
        }
      });
    }
  };

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: ({ name }) => {
      selectByType({
        name,
        widgetType,
        cb: submit,
      });
    },
  });

  const getDescription = (): string => {
    const schema = widgetInfo?.schemata[0];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return schema?.description;
  };

  const getImage = (): string => {
    const schema = widgetInfo?.schemata?.[0];

    if (schema && schema?.mPicture) {
      return getImageById(schema?.mPicture);
    }

    return null;
  };

  const getInfo = () => {
    loadWidgetInfo({
      variables: {
        type: widgetType,
      },
    })
      .then(({ data }) => {
        const schema = data.schemata[0];

        if (schema) {
          const typeName = [...WIDGETS_FOR_REPORT, ...WIDGETS_FOR_BOARD].find(
            (item) => item.value === widgetType
          )?.title;

          formik
            .setValues({
              name: `${typeName} #${schema.objectsCount + 1}`,
            })
            .then(() => {})
            .catch(() => {});
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getInfo();
  }, [widgetType]);

  const formikRequiredProps = useFormikRequiredInputs(formik, validationSchema);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.addWidgetModal.addWidget}
        handleClose={reject}
        loading={loading}
        buttons={
          <>
            <Button color="inherit" onClick={reject}>
              {msg.addWidgetModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Next
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomSelect
              name="widgetType"
              data-test-select-widget
              label={msg.addWidgetModal.widgetType}
              list={getWidgetsList()}
              value={widgetType}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setWidgetType(e.target.value as WidgetTypesEnum);
              }}
            />
          </Grid>
          <Grid item>
            <CustomInput label="Name" clearFieldIcon={true} {...formikRequiredProps('name')} />
          </Grid>
          {getDescription() && (
            <>
              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  Description
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{getDescription()}</Typography>
              </Grid>
            </>
          )}
          {getImage() && (
            <>
              <Grid item>
                <Image duration={500} height={200} showLoading={true} bgColor="#ddd" src={getImage()}></Image>
              </Grid>
            </>
          )}
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(SelectWidget);
