import { useMutation } from '@apollo/client';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Button, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { loader } from 'graphql.macro';
import { useEffect, useReducer } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import CommonModal from '../components/CommonModal';
import CustomRadio from '../components/CustomRadio';
import CustomSwitch from '../components/CustomSwitch';
import { msg } from '../constants/messages';
import { CHANNELS } from '../utils/notificationProfiles';
import { useAuth } from '../utils/useAuth';

const UPDATE_OBJECT_PROPERTY = loader('../graphql/UpdateObjectWithProperties.graphql');

const TITLE = {
  [CHANNELS.IN_APP]: 'Notification settings',
  [CHANNELS.SMS]: 'Sms notifications',
  [CHANNELS.WHATSAPP]: 'WhatsApp notifications',
  [CHANNELS.EMAIL]: 'Email notifications',
};

const EditNotificationsSettingsModal = ({ profile, onResolve, onReject, isOpen }) => {
  const { loadUser } = useAuth();

  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);

  let defaultValues = {};

  const theme = useTheme();
  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  useEffect(() => {
    if (profile) {
      profile?.objectProperties?.forEach((property) => {
        defaultValues[property.key] = property.value;
      });
    }
  }, [profile]);

  const submit = () => onResolve();

  const reject = () => onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (checked !== undefined) {
      if (checked === true) {
        value = true;
      } else {
        value = false;
      }
    }
    setValues({ [name]: value });
  };

  const handleUpdateWidgetProps = (valuesReady) => {
    toast
      .promise(
        updateProperty({
          variables: {
            input: {
              detailedObject: [
                {
                  objectId: profile.id,
                  keyedProperties: valuesReady,
                },
              ],
            },
          },
        }),
        {
          loading: 'Updating...',
          success: () => 'Updated',
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => {
        return loadUser();
      })
      .then(() => {
        submit();
      });
  };

  return (
    <>
      <CommonModal
        modalOpen={isOpen}
        title={TITLE[profile.deliveryChannel]}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.editNotificationsSettingsModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleUpdateWidgetProps([
                  {
                    propertyKey: 'settingsPreference',
                    value: values.settingsPreference,
                  },
                  {
                    propertyKey: 'settingsSoundInTheApp',
                    value: Boolean(values.settingsSoundInTheApp),
                  },
                  {
                    propertyKey: 'settingsExcludeTags',
                    value: values.settingsExcludeTags,
                  },
                  {
                    propertyKey: 'settingsIncludeTags',
                    value: values.settingsIncludeTags,
                  },
                ]);
              }}
            >
              {msg.editNotificationsSettingsModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              {msg.editNotificationsSettingsModal.general}
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ paddingRight: '20px' }}>
            <CustomRadio
              name="settingsPreference"
              value={values['settingsPreference'] ?? 'all'}
              list={[
                {
                  icon: <VolumeUpIcon style={{ color: theme.palette.gray1 }} />,
                  title: 'All notifications',
                  value: 'all',
                },
                {
                  icon: <StarOutlineIcon style={{ color: theme.palette.gray1 }} />,
                  title: 'Favorites only',
                  value: 'favourites',
                },
                {
                  icon: <VolumeOffIcon style={{ color: theme.palette.gray1 }} />,
                  title: 'Mute all',
                  value: 'mute',
                },
              ]}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Only deliver notification which
            </Typography>
          </Grid>

          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Autocomplete
              multiple
              fullWidth={true}
              options={[]}
              freeSolo
              onChange={(e, value) => {
                setValues({ settingsExcludeTags: value });
              }}
              name={'settingsExcludeTags'}
              defaultValue={values['settingsExcludeTags'] ?? []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="filled" color={'primary'} label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => <TextField {...params} variant="standard" label="Exclude tags" />}
            />
          </Grid>

          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Autocomplete
              multiple
              fullWidth={true}
              options={[]}
              freeSolo
              name={'settingsIncludeTags'}
              onChange={(e, value) => {
                setValues({ settingsIncludeTags: value });
              }}
              defaultValue={values['settingsIncludeTags'] ?? []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="filled" color={'primary'} label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => <TextField {...params} variant="standard" label="Include tags" />}
            />
          </Grid>
          {profile?.deliveryChannel === CHANNELS.IN_APP && (
            <>
              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  {msg.editNotificationsSettingsModal.options}
                </Typography>
              </Grid>{' '}
              <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                <CustomSwitch
                  name="settingsSoundInTheApp"
                  label="Sound in the app"
                  value={values['settingsSoundInTheApp'] ?? ''}
                  onChange={handleInputChange}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditNotificationsSettingsModal);
