import { gql } from '../../../__generated__';

export const SUBSCRIBE_TO_OBJECT = gql(/* GraphQL */ `
  subscription Objects($objId: [UUID!]) {
    Objects(filterA: { id: $objId }) {
      event
      relatedNode {
        ... on Object {
          id
          name
        }
        ... on ObjectProperty {
          linkedPropertyId
          id
          groupName
          property
          value
          key
        }
        ... on ObjectsToObject {
          id
          object1Id
          object2Id
        }
      }
      relatedNodeId
    }
  }
`);
