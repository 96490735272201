import { SettingsSizeOptions, SettingsStyleOptions } from '../../../utils/constants/selectOptions';

export enum FormatEnum {
  value = 'value',
  icon = 'icon',
  icon_value = 'icon_value',
}

export enum OrientationFormat {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

export interface TDataboxState {
  settingsStyle: SettingsStyleOptions;
  settingsSize: SettingsSizeOptions.small | SettingsSizeOptions.large;
  settingsFormat: FormatEnum;
  settingsOrientation: OrientationFormat;
  settingsIcon: string;
  settingsShowTitle: boolean;
}
