import { format } from 'date-fns';
import { getNotificationsHistory } from '../media-server';
import downloadBlob from './downloadBlob';

const downloadNotifications = ([startDate, endDate], ids) => {
  const data = {
    ids,
    from: format(startDate, 'yyyy-MM-dd 00:00'),
    to: format(endDate, 'yyyy-MM-dd 23:59'),
  };

  return getNotificationsHistory(data).then((res) => {
    downloadBlob(res.data, `PixelBoard_Notifications_${ids}_${data.from}-${data.to}.csv`, res.data.type);
  });
};

export default downloadNotifications;
