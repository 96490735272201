import { useMutation } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { CREATE_EXECUTION } from '../../../modules/reports/api/CreateExecution';
import { getPropertyByKey } from '../../../utils/getPropertyByKey';
import AccessSection from '../basic/AccessSection';
import DescriptionSection from '../basic/DescriptionSection';
import ServiceSection from '../basic/ServiceSection';
import RpcSubscribeWrapper from '../static-table/RpcSubscribeWrapper';
import GeotagsSection from './geotags/GeotagsSection';
import ParametersSection from './ParametersSection';
import PeriodsSection from './periods/PeriodsSection';

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: '15px',
    marginBottom: '10px',
  },
  itemToHideOrShow: {
    visibility: 'hidden',
    opacity: 1,
    transition: 'opacity 0.2s linear',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      opacity: 1,
    },
  },
  itemToHover: {
    '&:hover $itemToHideOrShow': {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.2s linear',
    },
  },
}));

const TabGeneralTrackingBox = (props) => {
  const { item } = props;
  const classes = useStyles();
  const [createExecution] = useMutation(CREATE_EXECUTION);

  const getProperty = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };

  const settingsPeriodsProperty = () => {
    return getPropertyByKey(item.objectProperties, 'settingsPeriods');
  };

  const settingsGeotagsProperty = () => {
    return getPropertyByKey(item.objectProperties, 'settingsGeotags');
  };

  const parametersMinimumDuration = () => {
    return getPropertyByKey(item.objectProperties, 'parametersMinimumDuration');
  };

  const [isGetDataDisabled, setIsGetDataDisabled] = useState(true);

  useEffect(() => {
    if (item) {
      const conditions = [
        getProperty('settingsGeotags')?.value?.length,
        getProperty('settingsPeriods')?.value?.length,
        getProperty('parametersDateRange')?.value?.length,
      ];

      setIsGetDataDisabled(!conditions.every((item) => item));
    }
  }, [item]);

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: 'UpdateReport',
            objectId: item.id,
            params: {
              minimum_duration: parametersMinimumDuration().value,
            },
          },
        },
      },
    });
  };

  return (
    <>
      <List>
        <ParametersSection classes={classes} item={item} />
        <GeotagsSection geotagsProperty={settingsGeotagsProperty()} classes={classes} item={item} />
        <PeriodsSection periodsProperty={settingsPeriodsProperty()} classes={classes} />
        <Box m={1} mb={4}>
          <RpcSubscribeWrapper
            rpcName={'UpdateReport'}
            objectId={item.id}
            disabled={isGetDataDisabled}
            object={item}
            handler={rpcHandler}
            title={'Get data'}
          >
            <LoadingButton fullWidth={true} variant={'outlined'}></LoadingButton>
          </RpcSubscribeWrapper>
        </Box>
        <ServiceSection classes={classes} item={item} />
        <AccessSection classes={classes} item={item} />
        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default TabGeneralTrackingBox;
