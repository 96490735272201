import { gql, useLazyQuery } from '@apollo/client';
import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { create } from 'react-modal-promise';
import { msg } from '../../constants/messages';
import CommonModal from '../CommonModal';
import CustomAutocomplete from '../CustomAutocomplete';

const GET_OBJECT = gql`
  query getObject($id: UUID!) {
    object(id: $id) {
      id
      objectProperties(sort: { sortBy: { field: GROUP_NAME } }) {
        id
        key
        spec {
          units
          id
          description
          property
          groupName
          key
        }
      }
    }
  }
`;

const SelectDeviceProperty = (props) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(props.value || null);

  const [loadObject, { data }] = useLazyQuery(GET_OBJECT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: props.objectId,
    },
    onCompleted: () => {
      // setHumanReadableObject(data.object.name);
    },
  });

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const list = () => {
    if (data) {
      return data.object.objectProperties.map((item) => {
        return {
          value: item.id,
          title: `${item.spec.groupName}/${item.spec.description || item.spec.property}`,
        };
      });
    }
    return [];
  };

  const handleSave = async () => {
    setLoading(true);
    props.handleSave(props.id, value).finally(() => {
      setLoading(false);
      submit();
    });
  };

  useEffect(() => {
    loadObject();

    if (props.value) {
      setValue(props.value);
    }
  }, []);

  return (
    <CommonModal
      key="EditDevice"
      modalOpen={props.isOpen}
      title="Edit property"
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>
            {msg.default.cancel}
          </Button>
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CustomAutocomplete
            name="Property"
            label="Property"
            list={list()}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(SelectDeviceProperty);
