import orderBy from 'lodash/orderBy';
import { AlarmType, ControlsListType, ValuesType } from './type';
/* eslint-disable @typescript-eslint/no-unsafe-argument */
const generateIntervalCalculator = (operator: (interval: number) => string | number) => {
  return function (timeIntervalInMinutes: string | number) {
    const interval = parseInt(timeIntervalInMinutes as string);
    return isNaN(interval) ? '' : operator(interval);
  };
};

export const modulo60Calculator = generateIntervalCalculator((interval) => Math.floor(interval % 60));
export const divide60Calculator = generateIntervalCalculator((interval) => Math.floor(interval / 60));

const calculateTimeInMinutes = (from: string, to: string, values: ValuesType) => {
  const fromInMinutes = parseInt(values[from]) * 60 + parseInt(values[to]);
  return isNaN(fromInMinutes) ? '' : fromInMinutes;
};

export const createAlarmConfig = (
  alarmNumber: number,
  alarmsWeekDays: { alarm1: number[]; alarm2: number[]; alarm3: number[] },
  values: ValuesType
): AlarmType => {
  const isNumberTimeout = isNaN(parseInt(values[`alarmsAlert${alarmNumber}_timeout_value`]));
  const isNumberTimer = isNaN(parseInt(values[`alarmsAlert${alarmNumber}_timer_value`]));
  return {
    condition: {
      operator: values[`alarmsAlert${alarmNumber}_condition_operator`],
      value: values[`alarmsAlert${alarmNumber}_condition_value`],
    },
    timeout: {
      units: values[`alarmsAlert${alarmNumber}_timeout_units`],

      value: isNumberTimeout ? '' : parseInt(values[`alarmsAlert${alarmNumber}_timeout_value`]),
    },
    timeIntervalInMinutes: {
      from: calculateTimeInMinutes(
        `alarmsAlert${alarmNumber}_timeIntervalInMinutes_from_h`,
        `alarmsAlert${alarmNumber}_timeIntervalInMinutes_from_m`,
        values
      ),
      to: calculateTimeInMinutes(
        `alarmsAlert${alarmNumber}_timeIntervalInMinutes_to_h`,
        `alarmsAlert${alarmNumber}_timeIntervalInMinutes_to_m`,
        values
      ),
    },
    weekDays: alarmsWeekDays[`alarm${alarmNumber}`],
    timer: {
      dismiss: values[`alarmsAlert${alarmNumber}_timer_dismiss`],
      units: values[`alarmsAlert${alarmNumber}_timer_units`],
      value: isNumberTimer ? '' : parseInt(values[`alarmsAlert${alarmNumber}_timer_value`]),
    },
  };
};

export const alarmOptionsInitialValue = (linkedObjectsList) => {
  return {
    linkedObjectsList,
    arguments: [],
    params: [],
    selectedControls: { rpc: null, value: null },
    selectedLinkedObject: { value: null, title: null },
  };
};

export const getControlsList = (data) => {
  const controls = data.object.schema.schemaControls.filter((i) => i.argument === i.rpc);
  const sortedControls = orderBy(controls, ['rpc'], ['asc']);

  const params = data.object.schema.schemaControls.filter((i) => i.argument !== i.rpc);
  const sortedParams = orderBy(params, ['argument'], ['asc']);

  const controlsList: ControlsListType[] = sortedControls.map((control) => ({
    title: control.description || control.rpc,
    value: control.id,
    rpc: control.rpc,
  }));
  return { controlsList, params: sortedParams };
};

export const defaultAlarmOptions = {
  controls: {
    rpc: null,
    value: null,
  },
  arguments: [],
  linkedObject: {
    value: null,
  },
};
