import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import { sub } from 'date-fns';
import { useState } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { msg } from '../../../constants/messages';
import CommonModal from '../../CommonModal';

const containerStyles = { padding: '20px 0' };

interface CustomPeriodModalProps {
  selectRange: (start: Date, end: Date) => void;
  defaultStart?: string;
  defaultEnd?: string;
}

const CustomPeriodModal = ({
  onResolve: handleClose,
  defaultStart,
  defaultEnd,
  isOpen,
  selectRange,
}: CustomPeriodModalProps & InstanceProps<{}>) => {
  const [startDate, setStartDate] = useState<Date>(
    defaultStart ? new Date(defaultStart) : sub(Date.now(), { weeks: 1 })
  );
  const [endDate, setEndDate] = useState<Date>(defaultEnd ? new Date(defaultEnd) : new Date());

  return (
    <>
      <CommonModal
        key="DateTimePicker"
        modalOpen={isOpen}
        title="Custom period"
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.default.cancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                try {
                  selectRange(startDate, endDate);
                } finally {
                  handleClose();
                }
              }}
            >
              {msg.default.save}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={6} sx={containerStyles}>
          <Grid item>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} variant="standard" label="From" />}
              maxDate={endDate}
              disableFuture
              inputFormat="dd-MM-yyyy HH:mm a"
              value={startDate}
              onChange={setStartDate}
            />
          </Grid>
          <Grid item>
            <DateTimePicker
              disableFuture
              minDate={startDate}
              inputFormat="dd-MM-yyyy HH:mm a"
              renderInput={(props) => <TextField {...props} sx={{ width: '100%' }} variant="standard" label="To" />}
              value={endDate}
              onChange={setEndDate}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(CustomPeriodModal);
