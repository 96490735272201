import { useMutation, useQuery } from '@apollo/client';
import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { loader } from 'graphql.macro';
import { useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import CommonModal from '../components/CommonModal';
import CustomInput from '../components/CustomInput';
import { msg } from '../constants/messages';
import { GROUPS_PROPS_QUERY } from '../graphql/queries';
import useIsFieldRequired from '../hooks/formik/useIsFieldRequired';
import useRoute from '../hooks/useRoute';
import CustomSelect from './CustomSelect';
import PictureItem from './forms/PictureItem';
import SelectColor from './forms/SelectColor';
import useCustomNavigate from './hooks/useCustomNavigate';

const ADD_GROUP_MUTATION = loader('../graphql/AddGroupMutation.graphql');
const UPDATE_GROUP_MUTATION = loader('../graphql/UpdateGroupMutation.graphql');
const UPDATE_PROPERTY = loader('../graphql/UpdatePropertyMutation.graphql');
const DASHBOARD_QUERY = loader('../graphql/DashboardQuery.graphql');

const AddGroupModal = (props) => {
  const { isReport } = useRoute();
  const params = useParams();
  let defaultValues = {};

  const entityId = params.dashboardId || params.reportId;
  const [fields, setFields] = useState([]);
  const [name, setName] = useState(props?.object?.name || '');
  const [description, setDescription] = useState(props?.object?.description || '');
  const [, setImage] = useState(fields.find((item) => item.key === 'customBackgroundImage'));

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);
  const { data: dashboardData } = useQuery(DASHBOARD_QUERY, {
    variables: {
      dashboardId: entityId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const validationSchema = yup.object({
    id: yup.bool(),
    name: yup.string().trim().required('Name is required'),
    generalColumnsCount: yup.number().when('id', {
      is: false,
      then: yup
        .number()
        .test('maxDigitsAfterDecimal', 'Decimal number not allowed', (number) => {
          return !(number && number.toString().includes('.'));
        })
        .min(1, 'Minimum column count is 1')
        .max(20, 'Maximum column count is 20')
        .required('Columns count is required'),
      otherwise: yup.number().nullable(),
    }),
    generalRowsCount: yup.number().when('id', {
      is: false,
      then: yup
        .number()
        .test('maxDigitsAfterDecimal', 'Decimal number not allowed', (number) => {
          return !(number && number.toString().includes('.'));
        })
        .min(1, 'Minimum row count is 1')
        .max(200, 'Maximum row count is 200')
        .required('Row count is required'),
      otherwise: yup.number().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      id: false,
      name,
      generalColumnsCount: '',
      generalRowsCount: '',
    },
    validationSchema,
    onSubmit: () => {
      setValues({
        ...values,
        ...formik.values,
      });
      if (isEdit()) {
        handleSaveGroup();
      } else {
        handleAddGroup();
      }
    },
  });

  const { loading: groupPropsLoading } = useQuery(GROUPS_PROPS_QUERY, {
    variables: {
      dashboardId: entityId,
    },
    onCompleted: (data) => {
      setFields(data.schemata[0].schemaProperties);

      if (!isEdit()) {
        data.schemata[0].schemaProperties.forEach((prop) => {
          defaultValues[prop.key] = prop.defaultValue;
        });
        setValues(defaultValues);
        formik.setValues({
          name: `Group #${data.schemata[0].objectsCount + 1}`,
          id: false,
          generalColumnsCount: defaultValues.generalColumnsCount,
          generalRowsCount: defaultValues.generalRowsCount,
        });
        setName(`Group #${data.schemata[0].objectsCount + 1}`);
      } else {
        props.object.objectProperties
          .filter((prop) => !prop.spec.hidden)
          .forEach((prop) => {
            setValues({ [prop.key]: prop.value });
          });

        formik.setValues({
          name: props.object.name,
          id: true,
          generalColumnsCount: defaultValues.generalColumnsCount,
          generalRowsCount: defaultValues.generalRowsCount,
        });
        setName(props.object.name);
        setDescription(props.object.description);
      }
    },
    fetchPolicy: 'network-only',
  });

  const [addGroup, { loading: isAddingToGroup }] = useMutation(ADD_GROUP_MUTATION);
  const [updateGroup, { loading: isEditingGroup }] = useMutation(UPDATE_GROUP_MUTATION);
  const [updateDashboardLayout, { loading: isUpdatingLayout }] = useMutation(UPDATE_PROPERTY);

  const history = useCustomNavigate();

  const isLoading = () => isAddingToGroup || isEditingGroup || isUpdatingLayout;

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const isEdit = () => {
    return props?.object?.id;
  };

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const getValueSet = (key) => {
    const prop = fields.find((item) => item.key === key);

    if (prop?.valueSet) {
      return prop.valueSet.list.map((item) => {
        return { ...item, title: item.title, value: item.key };
      });
    }

    return [];
  };

  const handleSaveGroup = () => {
    let properties = [
      {
        propertyKey: 'generalContainerType',
        value: values.generalContainerType,
      },
      {
        propertyKey: 'generalBackgroundColor',
        value: values.generalBackgroundColor,
      },
      {
        propertyKey: 'customBackgroundImage',
        value: values.customBackgroundImage,
      },
    ];

    toast
      .promise(
        updateGroup({
          variables: {
            id: props.object.id,
            name: formik.values.name,
            description: description,
            values: properties,
          },
        }),
        {
          loading: 'Saving group...',
          success: () => msg.editGroupModal.updated,
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => {
        submit();
      });
  };

  const handleAddGroup = () => {
    const yCoords = dashboardData.dashboard.layouts[0].value.lg.map((item) => item.y);
    let id = null;
    addGroup({
      variables: {
        dashboardId: entityId,
        name: formik.values.name,
        description: description,
        values: Object.keys({
          ...values,
          generalColumnsCount: +formik.values.generalColumnsCount,
          generalRowsCount: +formik.values.generalRowsCount,
        }).map((key) => {
          return { propertyKey: key, value: values[key] };
        }),
      },
    })
      .then(({ data }) => {
        id = data.createObjectWithProperties.uuid;

        const newGroup = {
          x: 0,
          y: yCoords.length ? Math.max(...yCoords) + 1 : 0,
          w: +formik.values.generalColumnsCount,
          h: +formik.values.generalRowsCount,
          i: data.createObjectWithProperties.uuid,
          resizeHandles: ['se'],
        };
        const newLayout = {
          lg: [...dashboardData.dashboard.layouts[0].value.lg, newGroup],
          md: [...dashboardData.dashboard.layouts[0].value.md, newGroup],
          sm: [...dashboardData.dashboard.layouts[0].value.sm, newGroup],
          xs: [...dashboardData.dashboard.layouts[0].value.xs, newGroup],
          xxs: [...dashboardData.dashboard.layouts[0].value.xxs, newGroup],
        };

        return updateDashboardLayout({
          variables: {
            input: {
              id: dashboardData.dashboard.layouts[0].id,
              patch: {
                value: newLayout,
              },
            },
          },
        });
      })
      .then(() => {
        if (isReport()) {
          history(`/reports/${entityId}/${id}`);
        } else {
          history(`/boards/${entityId}/${id}`);
        }
        submit();
      });
  };

  const isFieldRequired = useIsFieldRequired(validationSchema);

  return (
    <>
      <CommonModal
        loading={groupPropsLoading}
        modalOpen={props.isOpen}
        handleClose={reject}
        title={isEdit() ? 'Edit group' : msg.addGroupModal.addGroup}
        forceTitle={true}
        contentStyles={{
          padding: '14px 16px 16px 14px',
        }}
        buttons={
          <>
            <Button data-test-cancel="close" color="inherit" onClick={reject}>
              {msg.addGroupModal.buttonCancel}
            </Button>
            <Button data-test="createEditGroup" color="primary" onClick={formik.handleSubmit}>
              {isLoading() ? <CircularProgress size={23} /> : isEdit() ? 'Save' : msg.addGroupModal.buttonAdd}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              required={isFieldRequired('name')}
              name="name"
              label={msg.addGroupModal.name}
              clearFieldIcon={true}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          {!isEdit() && (
            <>
              <Grid item>
                <CustomInput
                  required
                  type={'number'}
                  name="generalColumnsCount"
                  label={'Column count'}
                  clearFieldIcon={true}
                  value={formik.values.generalColumnsCount}
                  onChange={formik.handleChange}
                  error={formik.touched.generalColumnsCount && Boolean(formik.errors.generalColumnsCount)}
                  helperText={formik.touched.generalColumnsCount && formik.errors.generalColumnsCount}
                />
              </Grid>
              <Grid item>
                <CustomInput
                  required
                  type={'number'}
                  name="generalRowsCount"
                  label={'Row count'}
                  clearFieldIcon={true}
                  value={formik.values.generalRowsCount}
                  onChange={formik.handleChange}
                  error={formik.touched.generalRowsCount && Boolean(formik.errors.generalRowsCount)}
                  helperText={formik.touched.generalRowsCount && formik.errors.generalRowsCount}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <SelectColor
              name="generalBackgroundColor"
              label={'Background color'}
              value={values.generalBackgroundColor ?? ''}
              list={getValueSet('generalBackgroundColor')}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              disabled={isEdit()}
              name="generalContainerType"
              label={'Container type'}
              value={values.generalContainerType ?? ''}
              list={getValueSet('generalContainerType')}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <PictureItem
              cbDelete={() => {
                setImage(null);
                setValues({ customBackgroundImage: null });
              }}
              backgroundUID={values.customBackgroundImage}
              setImageId={(value) => {
                setImage(value);
                setValues({ customBackgroundImage: value });
              }}
            />
          </Grid>
          <Grid item>
            <CustomInput
              name="description"
              label={msg.addGroupModal.description}
              clearFieldIcon={true}
              value={description ?? ''}
              multiline={true}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(AddGroupModal);
