import SVG from 'react-inlinesvg';
import AdvancedButtonFallback from '../../assets/fallbacks/advanced-button.svg';
import ChartFallback from '../../assets/fallbacks/chart.svg';
import CollectionFallback from '../../assets/fallbacks/collection.svg';
import CommandButtonFallback from '../../assets/fallbacks/command-button.svg';
import ContainerFallback from '../../assets/fallbacks/container.svg';
import DatasetFallback from '../../assets/fallbacks/dataset.svg';
import DateTimeFallback from '../../assets/fallbacks/datetime.svg';
import DiagramFallback from '../../assets/fallbacks/diagram.svg';
import HistoryTableFallback from '../../assets/fallbacks/history-table.svg';
import MinimapFallback from '../../assets/fallbacks/minimap.svg';
import MonitorTableFallback from '../../assets/fallbacks/monitor-table.svg';
import ObjectFallback from '../../assets/fallbacks/object.svg';
import StaticTableFallback from '../../assets/fallbacks/static-table.svg';
import StatisticFallback from '../../assets/fallbacks/statistics.svg';
import TimerFallback from '../../assets/fallbacks/timer.svg';
import TitleFallback from '../../assets/fallbacks/title.svg';
import TrackingBox from '../../assets/fallbacks/tracking-box.svg';
import WidgetFallback from '../../assets/fallbacks/widget.svg';
import { getPropertyValueByKey } from '../../utils/getPropertyByKey';
import useMedia from '../../utils/useMedia';
import { WIDGETS_ENUM } from '../../utils/widgetTypes';

const CardImage = ({ object, type }) => {
  const { getImageById } = useMedia();
  const svgUrl = object?.schema?.mPicture;
  const containerType = getPropertyValueByKey(object.objectProperties, 'generalContainerType');
  const bgImage = getPropertyValueByKey(object.objectProperties, 'customBackgroundImage');
  const schemaType = object.schemaType;
  const schemaTags = object.schemaTags;

  const getImageFallback = () => {
    if (bgImage) {
      return getImageById(bgImage);
    }

    if (schemaTags.includes('statistics')) {
      return StatisticFallback;
    }

    if (schemaTags.includes('diagram')) {
      return DiagramFallback;
    }

    if (schemaTags.includes('monitor table')) {
      return MonitorTableFallback;
    }

    if (schemaTags.includes('monitor status')) {
      return MonitorTableFallback;
    }

    if (schemaTags.includes('static table')) {
      return StaticTableFallback;
    }

    if (schemaTags.includes(WIDGETS_ENUM.PROPERTY_HISTORY_TABLE)) {
      return HistoryTableFallback;
    }

    if (schemaTags.includes('tracking table')) {
      return StaticTableFallback;
    }

    if (schemaTags.includes('history table')) {
      return HistoryTableFallback;
    }

    if (schemaTags.includes('geotags table')) {
      return StaticTableFallback;
    }

    if (schemaTags.includes('datachart')) {
      return ChartFallback;
    }

    if (schemaTags.includes('color datachart')) {
      return ChartFallback;
    }

    if (schemaTags.includes('title')) {
      return TitleFallback;
    }

    if (schemaTags.includes('command button')) {
      return CommandButtonFallback;
    }

    if (schemaTags.includes('databox')) {
      return WidgetFallback;
    }

    if (schemaTags.includes('geo timer')) {
      return TrackingBox;
    }

    if (schemaTags.includes('datetime')) {
      return DateTimeFallback;
    }

    if (schemaTags.includes('timer')) {
      return TimerFallback;
    }

    if (schemaTags.includes('advanced button')) {
      return AdvancedButtonFallback;
    }

    switch (type) {
      case 'group':
        if (containerType === 'minimap') {
          return MinimapFallback;
        }
        return ContainerFallback;
    }

    if (type === 'object') {
      switch (schemaType) {
        case 'dataset':
          return DatasetFallback;
        default:
          return ObjectFallback;
      }
    }

    if (type === 'collection') {
      return CollectionFallback;
    }
  };

  return (
    <>
      {svgUrl && (
        <>
          <div
            style={{
              width: '100%',
              height: '240px',
              backgroundImage: `url(${getImageById(svgUrl)})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }}
          ></div>
        </>
      )}

      {!svgUrl && (
        <>
          <SVG src={getImageFallback()} width="100%" height="240px" />
        </>
      )}
    </>
  );
};

export default CardImage;
