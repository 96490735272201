import { gql } from '../../../__generated__';

const CHECK_PERMISSION = gql(/* GraphQL */ `
  mutation resolveObjectPermissions($input: ResolveObjectPermissionsInput!) {
    resolveObjectPermissions(input: $input) {
      accessRight {
        edit
      }
    }
  }
`);

export { CHECK_PERMISSION };
