import { Button, Checkbox, Collapse, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { dispatch as dispatchBus } from 'use-bus';
import * as yup from 'yup';
import CommonModal from '../../../components/CommonModal';
import { msg } from '../../../constants/messages';
import useIsFieldRequired from '../../../hooks/formik/useIsFieldRequired';
import { SETTINGS_STYLE_OPTIONS } from '../../../utils/constants/selectOptions';
import { getPropertyValueByKey } from '../../../utils/getPropertyByKey';
import { WIDGETS_ENUM } from '../../../utils/widgetTypes';
import CustomInput from '../../CustomInput';
import CustomSelect from '../../CustomSelect';
import CustomSwitch from '../../CustomSwitch';
import { TDefaultWidgetProps } from '../types';
import { useAddWidget } from '../useAddWidget';
import { FORM_DEFAULT_VALUES } from './constants';

const CreateGeotagsTable = (props: TDefaultWidgetProps & InstanceProps<{}>) => {
  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const { createWidgetFn, updateWidget, isLoading } = useAddWidget({
    group: props.group,
    widgetType: WIDGETS_ENUM.GEOTAGS_TABLE,
    cb: submit,
  });

  const isEdit = () => {
    return props?.widget?.id !== undefined;
  };

  const getPrevValues = () => {
    const defaultValues = {
      ...FORM_DEFAULT_VALUES,
    };

    if (props?.widget?.objectProperties) {
      for (const property in FORM_DEFAULT_VALUES) {
        defaultValues[property] = getPropertyValueByKey(props.widget.objectProperties, property);
      }
    }

    return defaultValues;
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .required('Name is required')
      .when([], {
        is: () => !isEdit(),
        then: (schema) => schema.required('Name is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
  });

  const formik = useFormik({
    initialValues: {
      description: isEdit() ? props.widget.description : '',
      name: props.name,
      properties: {
        ...getPrevValues(),
      },
    },
    validationSchema,
    onSubmit: ({ properties, name, description }) => {
      if (isEdit()) {
        updateWidget({
          values: properties,
          defaultValues: { ...getPrevValues() },
          id: props.widget.id,
          name,
          description,
          cb: submit,
        });
      } else {
        createWidgetFn({
          values: properties,
          name,
          description,
          cb: submit,
        });
      }
    },
  });

  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    formik
      .setFieldValue(e.target.name, checked)
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    if (isEdit()) {
      dispatchBus('@@board/SAVE_BOARD');
    }
  }, []);

  const isFieldRequired = useIsFieldRequired(validationSchema);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={isEdit() ? 'Edit geotags history table' : 'Add geotags history table'}
        handleClose={reject}
        loading={isLoading}
        buttons={
          <>
            <Button data-test="close-chart" color="inherit" onClick={reject}>
              {msg.addWidgetModal.buttonCancel}
            </Button>
            <Button
              disabled={isLoading}
              data-test="save-regular-chart"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {isEdit() ? 'Save' : 'Add'}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          {isEdit() && (
            <>
              <Grid item>
                <CustomInput
                  name="name"
                  label="Name"
                  clearFieldIcon={true}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.errors.name}
                  required={isFieldRequired('name')}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Appearance
            </Typography>
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsStyle"
              label="Style"
              list={SETTINGS_STYLE_OPTIONS}
              value={formik.values.properties.settingsStyle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="properties.settingsShowTitle"
              label="Title"
              value={formik.values.properties.settingsShowTitle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="properties.settingsShowOnWidgetControls"
              label="On-widget configuration"
              value={formik.values.properties.settingsShowOnWidgetControls}
              onChange={formik.handleChange}
            />
          </Grid>
          <Collapse in={formik.values.properties.settingsShowOnWidgetControls}>
            <FormControl sx={{ marginLeft: '24px' }} component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="properties.settingsWidgetControls.object"
                      checked={formik.values.properties.settingsWidgetControls.object}
                      onChange={onChangeCheckbox}
                    />
                  }
                  label="Object"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="properties.settingsWidgetControls.date"
                      checked={formik.values.properties.settingsWidgetControls.date}
                      onChange={onChangeCheckbox}
                    />
                  }
                  label="Date"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="properties.settingsWidgetControls.datePresets"
                      checked={formik.values.properties.settingsWidgetControls.datePresets}
                      onChange={onChangeCheckbox}
                    />
                  }
                  label="Date presets"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="properties.settingsWidgetControls.csv"
                      checked={formik.values.properties.settingsWidgetControls.csv}
                      onChange={onChangeCheckbox}
                    />
                  }
                  label="Download CSV"
                />
              </FormGroup>
            </FormControl>
          </Collapse>

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Description
            </Typography>
          </Grid>
          <Grid item>
            <CustomInput
              name="description"
              label={msg.addWidgetModal.description}
              clearFieldIcon={true}
              multiline={true}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(CreateGeotagsTable);
