import { ColorLens, LinkOff, Notes } from '@mui/icons-material';
import ExtensionIcon from '@mui/icons-material/Extension';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import LabelIcon from '../../icons/labelIcon';
import LinkIcon from '../../icons/LinkIcon';
import AccessSection from '../basic/AccessSection';
import DescriptionSection from '../basic/DescriptionSection';
import ServiceSection from '../basic/ServiceSection';

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: '15px',
    marginBottom: '10px',
  },
  itemToHideOrShow: {
    visibility: 'hidden',
    opacity: 1,
    transition: 'opacity 0.2s linear',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      opacity: 1,
    },
  },
  itemToHover: {
    '&:hover $itemToHideOrShow': {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.2s linear',
    },
  },
}));

const ColorChartGeneralTab = (props) => {
  const { item } = props;

  const classes = useStyles({
    listItem: {
      minHeight: '56px',
    },
  });

  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };
  // Chart 1
  const chart1Property = () => getPropertyByKey('chart1Property');
  const chart1Source = () => getPropertyByKey('chart1Source');
  const chart1ReadyTimeseries = () => getPropertyByKey('chart1UseReady-MadeTimeseries');
  const styleChart1Color = () => getPropertyByKey('styleChart1Color');
  const styleChart1Label = () => getPropertyByKey('styleChart1Label');

  return (
    <>
      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Chart 1</Typography>
        </ListSubheader>

        {/*Chart 1*/}

        {!chart1ReadyTimeseries().value && (
          <>
            <ListItem style={{ height: '48px' }}>
              <ListItemIcon>
                {chart1Source().value.value === null && <LinkOff />}
                {chart1Source().value.value !== null && <ExtensionIcon />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography noWrap title={chart1Source().value.title} variant="body1">
                    Object: {chart1Source().value.title}
                  </Typography>
                }
              />
            </ListItem>

            <ListItem style={{ height: '48px' }}>
              <ListItemIcon>
                <LabelIcon></LabelIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography noWrap variant="body1">
                    Property: {chart1Property().value.title}
                  </Typography>
                }
              />
            </ListItem>
          </>
        )}

        {chart1ReadyTimeseries().value && (
          <ListItem style={{ height: '48px' }}>
            <ListItemIcon>
              {chart1Source().value.value === null && <LinkOff />}
              {chart1Source().value.value !== null && <LinkIcon />}
            </ListItemIcon>
            <ListItemText
              noWrap
              title={chart1Source().value.title}
              primary={<Typography variant="body1">Dataset: {chart1Source().value.title}</Typography>}
            />
          </ListItem>
        )}

        <ListItem style={{ height: '48px' }}>
          <ListItemIcon>
            <ColorLens></ColorLens>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Color: {styleChart1Color().value}</Typography>} />
        </ListItem>

        <ListItem style={{ height: '48px', paddingRight: '96px' }}>
          <ListItemIcon>
            <Notes></Notes>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Label: {styleChart1Label().value || 'n/a'}</Typography>} />
        </ListItem>

        <ServiceSection classes={classes} item={item} />

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default ColorChartGeneralTab;
