import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { loader } from 'graphql.macro';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import { msg } from '../constants/messages';
import useRoute from '../hooks/useRoute';
import useCustomNavigate from './hooks/useCustomNavigate';
import ConfirmationModal from './modals/ConfirmationModal';

const DELETE_OBJECTS = loader('../graphql/DeleteObjectsMutation.graphql');
const DASHBOARD_QUERY = loader('../graphql/DashboardQuery.graphql');
const UPDATE_PROPERTIES_BY_IDS = loader('../graphql/UpdateObjectPropertiesByIdMutation.graphql');

const DeleteGroupModal = (props) => {
  const [deleteObjects] = useMutation(DELETE_OBJECTS);
  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);
  const { entityId, isReport, isBoard } = useRoute();

  const history = useCustomNavigate();

  const { data: dashboardData } = useQuery(DASHBOARD_QUERY, {
    variables: {
      dashboardId: entityId(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleSave = () => {
    const groups = dashboardData.dashboard.groups;
    const targetLayout = groups.find(({ group }) => group.id === props.groupId).group.layouts[0];

    const promises = Promise.all([
      updateProperties({
        variables: {
          input: {
            propertiesArray: [
              {
                propertyId: targetLayout.id,
                value: targetLayout.value.filter((item) => item.i !== props.widgetId),
              },
            ],
          },
        },
      }),
      deleteObjects({ variables: { objects: [{ id: props.widgetId }] } }),
    ]);

    submit();

    if (isReport()) {
      history(`/reports/${entityId()}/${props.groupId}`);
    }

    if (isBoard()) {
      history(`/boards/${entityId()}/${props.groupId}`);
    }

    toast
      .promise(promises, {
        loading: 'Deleting widget...',
        success: () => `Widget deleted`,
        error: (err) => `${err.toString()}`,
      })
      .then(() => {});
  };

  return (
    <ConfirmationModal
      modalOpen={props.isOpen}
      title={msg.deleteGroupModal.deleteGroup}
      handleClose={handleClose}
      buttons={
        <>
          <Button color="inherit" onClick={handleClose}>
            {msg.deleteWidgetModal.buttonCancel}
          </Button>
          <Button data-test-delete-widget color="primary" onClick={handleSave}>
            {msg.deleteWidgetModal.buttonDelete}
          </Button>
        </>
      }
    >
      {msg.formatString(msg.deleteWidgetModal.text, { widgetName: props.name })}
    </ConfirmationModal>
  );
};

export default create(DeleteGroupModal);
