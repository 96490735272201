import { gql } from '@apollo/client';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import { getObjectPropertiesHistory } from '../../../media-server';
import downloadBlob from '../../../utils/downloadBlob';
import PropertyInfo from '../../modals/PropertyInfo';
import useMoreMenu from '../../useMoreMenu';
import useHandleCopy from '../menu/handlers/useHandleCopy';
import HistoryLayout from './HistoryLayout';

const query = gql`
  query fetchHistoryListConnection(
    $filter: ObjectPropertiesHistoryFilter
    $first: Int = 10
    $orderBy: [ObjectPropertiesHistoriesOrderBy!] = RECORDED_AT_DESC
    $after: Cursor
  ) {
    objectPropertiesHistoriesConnection(first: $first, after: $after, orderBy: $orderBy, filter: $filter) {
      edges {
        node {
          property
          propertyId
          id
          value
          recordedAt
          userByBy {
            id
            mName
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  itemToHideOrShow: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'opacity 0.2s linear',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      opacity: 1,
    },
  },
  itemToHover: {
    '&:hover $itemToHideOrShow': {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.2s linear',
    },
  },
}));

const processItem = (item, object) => {
  const computeValue = (value) => {
    if (value === null) return 'n/a';

    if (typeof value === 'object') {
      return JSON.stringify(value);
    } else {
      return value;
    }
  };

  const by = (item, prefix = 'by ') => {
    const author = item.node?.userByBy?.mName;
    if (author) {
      return `${prefix}${author}`;
    }
    return '';
  };

  const humanReadable = (property, object) => {
    const sameProperty = object.objectProperties.find((objectProperty) => objectProperty.property === property);
    const description = sameProperty?.spec?.description;

    if (description) {
      return description;
    }

    return property;
  };

  return {
    secondary: `${format(new Date(item.node.recordedAt), 'MMM d, hh:mm:ss a')} ${by(item)}`,
    key: item.node.id,
    primary: `${humanReadable(item.node.property, object)}: ${computeValue(item.node.value)}`,
    humanReadable: humanReadable(item.node.property, object),
    author: by(item, ''),
    time: format(new Date(item.node.recordedAt), 'MMM d, hh:mm:ss a'),
    value: computeValue(item.node.value),
    raw: item.node,
  };
};

const prepareData = (data) => {
  if (data) {
    return data.objectPropertiesHistoriesConnection.edges;
  }
  return [];
};

const ComponentItem = ({ item }) => {
  const classes = useStyles();
  const { MoreMenu, openMoreMenu, closeMoreMenu } = useMoreMenu();
  const handleCopy = useHandleCopy();

  return (
    <>
      <MoreMenu
        items={[
          {
            icon: <InfoIcon />,
            title: 'Info',
            id: 'info',
            disabled: false,
            handleAction: () => {
              PropertyInfo({
                property: item,
              })
                .then()
                .catch(() => {});
            },
          },
          {
            icon: <FilterNoneIcon />,
            title: 'Copy value',
            id: 'copy',
            disabled: false,
            handleAction: () => {
              handleCopy({
                object: {
                  name: '',
                },
                message: 'Value copied successfully',
                text: item.value,
              });
            },
          },
        ]}
        handleMenuItemClick={() => {
          closeMoreMenu();
        }}
      />
      <ListItem
        key={item.key}
        classes={{
          container: classes.itemToHover,
        }}
        data-testid={String(item.key)}
      >
        <ListItemText
          primary={
            <Typography
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                WebkitLineClamp: 2,
              }}
            >
              {item.primary}
            </Typography>
          }
          secondary={item.secondary}
        />
        <ListItemSecondaryAction className={classes.itemToHideOrShow}>
          <IconButton edge="end" onClick={openMoreMenu} size="large">
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

const downloadHistory = (range, ids, shape) => {
  const data = {
    ids,
    from: format(range[0], 'yyyy-MM-dd 00:00'),
    to: format(range[1], 'yyyy-MM-dd 23:59'),
  };

  if (shape) {
    data.shape = shape;
  }

  return getObjectPropertiesHistory(data)
    .then((res) => {
      downloadBlob(res.data, `PixelBoard_PropertiesHistory_${data.from}-${data.to}.csv`, res.data.type);
      // modalComposition.modal.hide('HistoryDate');
    })
    .catch(() => {
      // state.value.error = true;
    })
    .finally(() => {
      // state.value.loading = false;
    });
};

const HistoryProperties = (props) => {
  return (
    <HistoryLayout
      query={query}
      id={props.id}
      downloadIds={[props.id]}
      object={props.object}
      item={<ComponentItem />}
      processItem={processItem}
      downloadHistory={downloadHistory}
      prepareData={prepareData}
    />
  );
};

export default HistoryProperties;
