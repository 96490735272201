import { SettingsStyleOptions } from '../../../utils/constants/selectOptions';

interface TRegularGeotagsTableState {
  settingsShowTitle: boolean;
  settingsShowOnWidgetControls: boolean;
  settingsStyle: SettingsStyleOptions;
  settingsWidgetControls: {
    object: boolean;
    date: boolean;
    csv: boolean;
    datePresets: boolean;
  };
}

export const FORM_DEFAULT_VALUES: TRegularGeotagsTableState = {
  settingsStyle: SettingsStyleOptions.lightondark,
  settingsShowOnWidgetControls: false,
  settingsShowTitle: true,
  settingsWidgetControls: {
    object: false,
    csv: false,
    date: false,
    datePresets: false,
  },
};
