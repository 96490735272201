/* eslint-disable @typescript-eslint/no-unsafe-return */
import { LazyQueryResultTuple, OperationVariables, useLazyQuery } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { format } from 'date-fns';
import { loader } from 'graphql.macro';
import { FC } from 'react';
import { computeRpcStatus } from '../../../utils/computeRpcStatus';
import isJsonString from '../../../utils/isJsonString';
import { ControlExecution, ControlExecutionsEdge } from '../../../__generated__/graphql';
import ControlsHistoryInfoModal from '../../modals/ControlsHistoryInfoModal';
import { ItemToHideOrShow, ItemToHover } from './HistoryControl.styled';
const GET_REPORTS = loader('../../../graphql/getReportsForControlExecution.graphql');

type HistoryControlsItemProps = {
  controlExecutionsEdge: ControlExecutionsEdge;
};

type PreFilledType = {
  icon: {
    name: OverridableComponent<SvgIconTypeMap> & {
      muiName: string;
    };
    color: 'inherit' | 'disabled' | 'action' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  };
};

const HistoryControlsItem: FC<HistoryControlsItemProps> = (props) => {
  const { node } = props.controlExecutionsEdge;

  const [getReportsForControlExecution]: LazyQueryResultTuple<
    { controlExecutions: ControlExecution[] },
    OperationVariables
  > = useLazyQuery(GET_REPORTS, {
    variables: {
      linkedControlId: node.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const listArguments = (params: any) => {
    let paramsInner = params;
    if (typeof params === 'string') {
      paramsInner = JSON.parse(params);
    }
    const paramsTransformed = [];
    const isArray = Array.isArray(paramsInner);
    if (isArray) {
      return paramsInner;
    } else {
      for (const prop in paramsInner) {
        paramsTransformed.push({
          argument: prop,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          isJson: isJsonString(paramsInner[prop]),
          value: paramsInner[prop],
        });
      }
    }
    return paramsTransformed;
  };
  const handleInfo = () => {
    const params = listArguments(node?.params);
    getReportsForControlExecution()
      .then((res) => {
        if (res.data.controlExecutions.length === 0) {
          ControlsHistoryInfoModal({
            property: {
              createdAt: node.createdAt,
            } as ControlExecution,
            title: node.name,
            params,
            hasReports: false,
          })
            .then(() => {})
            .catch(() => {});
        } else {
          const [property] = res.data.controlExecutions;
          ControlsHistoryInfoModal({
            property,
            title: node.name,
            params,
            hasReports: true,
          })
            .then(() => {})
            .catch(() => {});
        }
      })
      .catch(() => {});
  };
  const preFilled: PreFilledType = {
    icon: {
      name: null,
      color: null,
    },
  };

  computeRpcStatus(node, preFilled);
  return (
    <>
      <ItemToHover key={node.id} data-testid={String(node.id)}>
        <ListItem>
          <IconButton>{preFilled.icon.name && <preFilled.icon.name color={preFilled.icon.color} />}</IconButton>
          <ListItemText
            primary={<Typography noWrap>{node.name + '()'}</Typography>}
            secondary={
              `${format(new Date(node.createdAt as string | number | Date), 'MMM d, hh:mm:ss a')}` +
              ' by ' +
              node.caller.mName
            }
          />
          <ItemToHideOrShow className="item-to-hide">
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={handleInfo} size="large">
                <InfoIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ItemToHideOrShow>
        </ListItem>
      </ItemToHover>
    </>
  );
};

export default HistoryControlsItem;
