import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useEffect } from 'react';
import { Route, Routes, useMatch } from 'react-router-dom';
import SideListWrapper from '../../../components/common/SideListWrapper';
import ContentLayout from '../../../components/ContentLayout';
import SideCard from '../../../components/side-card/SideCard';
import Spinner from '../../../components/Spinner';
import CollectionsBoard from '../components/CollectionsBoard';
import SideListCollections from '../components/SideListCollections';
const COLLECTIONS_QUERY = loader('../api/CollectionsQuery.graphql');

const BoardsRoot = () => {
  const { loading, error, data } = useQuery(COLLECTIONS_QUERY);
  const matchRoot = useMatch('/collections');

  useEffect(() => {
    if (data) {
      const ids = data.collections.map((item) => item.id);
      if (ids.length > 0 && matchRoot) {
        // navigate(`/collections/${ids[0]}`);
      }
    }
  }, [matchRoot, data, loading]);

  return (
    <>
      {loading && !data && <Spinner />}
      {error && <>{error}</>}
      {!error && data && (
        <>
          <ContentLayout>
            <Routes>
              <Route
                path="/*"
                element={
                  <SideListWrapper>
                    <SideListCollections />
                  </SideListWrapper>
                }
              ></Route>
              <Route
                path=":collectionId/"
                element={
                  <SideListWrapper>
                    <SideCard />
                  </SideListWrapper>
                }
              ></Route>
            </Routes>
            <Routes>
              <Route path="/*" element={<CollectionsBoard data={data} />}></Route>
            </Routes>
          </ContentLayout>
        </>
      )}
    </>
  );
};

export default BoardsRoot;
