import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { msg } from '../../constants/messages';
import CommonModal from '../CommonModal';

import { gql, useLazyQuery } from '@apollo/client';
import { create } from 'react-modal-promise';
import { isMonitoringObject, isStatistics } from '../../utils/objectType';
import CustomSelect from '../CustomSelect';
import SchemasAutocomplete from '../forms/SchemasAutocomplete';

export const SELECTED_SCHEMA = gql`
  query selectedSchema($id: UUID!) {
    schema(id: $id) {
      type
      mTags
    }
  }
`;
const SelectSchema = (props) => {
  const [loading, setLoading] = useState(false);
  const [schemaId, setSchemaId] = useState(props.value || null);

  const [type, setType] = useState('device');

  const handleInputChange = (e) => {
    let { value, checked } = e.target;

    if (checked) value = checked;

    setSchemaId(value);
  };

  const objectTypes = [
    { value: 'device', title: 'Device' },
    { value: 'dataset', title: 'Dataset' },
    { value: 'monitor', title: 'Monitor objects' },
    { value: 'stat', title: 'Statistic objects' },
    { value: 'application', title: 'Application' },
  ];

  const handleChangeType = (e) => {
    setType(e.target.value);
  };

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleSave = async () => {
    setLoading(true);
    props.handleSave(props.id, schemaId).finally(() => {
      setLoading(false);
      submit();
    });
  };

  const [loadSchema] = useLazyQuery(SELECTED_SCHEMA, {
    onCompleted: (e) => {
      const type = e?.schema?.type?.toLowerCase() || 'device';
      const tags = e?.schema?.mTags || [];

      if (isStatistics(tags)) {
        setType('stat');
      } else if (isMonitoringObject(tags)) {
        setType('monitor');
      } else {
        setType(type);
      }
    },
    fetchPolicy: 'network-only',
    variables: {
      id: props.value,
    },
  });

  useEffect(() => {
    if (props.value) {
      loadSchema();
    }
  }, [props.value]);

  return (
    <CommonModal
      key="SelectSchema"
      modalOpen={props.isOpen}
      title="Select schema"
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>
            {msg.default.cancel}
          </Button>
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CustomSelect
            name="object-type"
            label="Schema type"
            value={type}
            list={objectTypes}
            onChange={handleChangeType}
          />
        </Grid>
        <Grid item>
          <SchemasAutocomplete type={type} name="schemaId" schemaId={schemaId} handleChange={handleInputChange} />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(SelectSchema);
