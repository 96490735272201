import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { loader } from 'graphql.macro';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import * as yup from 'yup';
import CommonModal from '../components/CommonModal';
import { msg } from '../constants/messages';
import useIsFieldRequired from '../hooks/formik/useIsFieldRequired';
import CustomSelect from './CustomSelect';
import ObjectsAutocomplete from './forms/ObjectsAutocomplete';
import SchemasAutocomplete from './forms/SchemasAutocomplete';
const LINK_OBJECTS = loader('../graphql/LinkObjects.graphql');
const LinkWidgetModal = (props) => {
  const [linkObjects] = useMutation(LINK_OBJECTS);

  const objectTypes = [
    { value: 'device', title: 'Device' },
    { value: 'dataset', title: 'Dataset' },
    { value: 'monitor', title: 'Monitor objects' },
    { value: 'stat', title: 'Statistic objects' },
    { value: 'application', title: 'Application' },
  ];

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const validationSchema = yup.object({
    schemaType: yup.string().trim().required('Schema Type is required'),
    schemaName: yup.string().trim().nullable().required('Schema Name is required'),
    objectId: yup.string().trim().required('Object Id is required'),
  });

  const formik = useFormik({
    initialValues: {
      schemaType: 'device',
      schemaName: '',
      objectId: '',
    },
    validationSchema,
    onSubmit: (valuesForm) => {
      const variables = {
        widgetId: props.widgetId,
        objectId: valuesForm.objectId,
      };

      if (props.type === 'collection') {
        variables.widgetId = valuesForm.objectId;
        variables.objectId = props.widgetId;
      }
      toast
        .promise(
          linkObjects({
            variables,
          }),
          {
            loading: 'Linking object...',
            success: () => `Object linked`,
            error: (err) => `${err.toString()}`,
          }
        )
        .then(() => {
          props.refetch(
            {
              objectId: props.widgetId,
            },
            {
              fetchPolicy: 'network-only',
            }
          );
          submit();
        });
    },
  });

  const isFieldRequired = useIsFieldRequired(validationSchema);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.linkWidgetModal.linkObject}
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose} color="inherit" data-test-cancel="cancelButton">
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={formik.handleSubmit} data-test-add="addButton" type="submit">
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomSelect
              name="schemaType"
              label="Schema type"
              value={formik.values.schemaType}
              list={objectTypes}
              onChange={formik.handleChange}
              error={formik.touched.schemaType && Boolean(formik.errors.schemaType)}
              helperText={formik.touched.schemaType && formik.errors.schemaType}
              required={isFieldRequired('schemaType')}
            />
          </Grid>
          <Grid item>
            <SchemasAutocomplete
              type={formik.values.schemaType}
              name="schemaName"
              handleChange={formik.handleChange}
              error={formik.touched.schemaName && Boolean(formik.errors.schemaName)}
              helperText={formik.touched.schemaName && formik.errors.schemaName}
              required={isFieldRequired('schemaName')}
            />
          </Grid>
          <Grid item>
            <ObjectsAutocomplete
              schemaId={formik.values.schemaName}
              name="objectId"
              handleChange={formik.handleChange}
              error={formik.touched.objectId && Boolean(formik.errors.objectId)}
              helperText={formik.touched.objectId && formik.errors.objectId}
              required={isFieldRequired('objectId')}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(LinkWidgetModal);
