import { configureStore } from '@reduxjs/toolkit';

import { contextReducer } from './contextSlice';
import { profileReducer } from './profileSlice';
import { settingsReducer } from './settingsSlice';
import { userReducer } from './userSlice';

export const store = configureStore({
  reducer: {
    context: contextReducer,
    user: userReducer,
    profile: profileReducer,
    settings: settingsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
