import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { loader } from 'graphql.macro';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import { useMatch } from 'react-router-dom';
import * as yup from 'yup';
import CommonModal from '../components/CommonModal';
import CustomInput from '../components/CustomInput';
import CustomSelect from '../components/CustomSelect';
import CustomSwitch from '../components/CustomSwitch';
import { msg } from '../constants/messages';
import { SCHEMAS_NAMES_QUERY } from '../graphql/queries';
import useIsFieldRequired from '../hooks/formik/useIsFieldRequired';
import CustomAutocomplete from './CustomAutocomplete';

const ADD_OBJECT_MUTATION = loader('../graphql/AddObjectMutation.graphql');

const validationSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
  schemaName: yup.string().trim().required('Schema name is required'),
  schemaType: yup.string().trim().required('Schema type is required'),
});

const AddNewObjectModal = (props) => {
  const routeMatch = useMatch('/:lang/:dashboardId/:groupId/:widgetId');

  const [addObject] = useMutation(ADD_OBJECT_MUTATION);

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const formik = useFormik({
    initialValues: {
      name: '',
      schemaName: '',
      schemaType: '',
      enabled: true,
    },
    validationSchema: validationSchema,
    onSubmit: (valuesForm, actions) => {
      toast
        .promise(
          addObject({
            variables: {
              name: valuesForm.name,
              description: valuesForm.description,
              schemaId: valuesForm.schemaName,
              enabled: valuesForm.enabled,
              widgetId: props.widgetId,
            },
          }),
          {
            loading: 'Creating new object...',
            success: () => `Object created`,
            error: (err) => `${err.toString()}`,
          }
        )
        .then((result) => {
          const dashboardId = routeMatch.params.dashboardId;
          const groupId = routeMatch.params.groupId;
          const widgetId = routeMatch.params.widgetId;
          const objectId = result.data.createObjectWithProperties.uuid;
          const lang = routeMatch.params.lang;

          console.table({ dashboardId, groupId, widgetId, objectId, lang });

          actions.resetForm();
          props.refetch(
            {
              objectId: props.widgetId,
            },
            {
              fetchPolicy: 'network-only',
            }
          );
          submit();
          // history(`/${lang}/${dashboardId}/${groupId}/${widgetId}/${objectId}/`);
        });
    },
  });

  const [getSchemaNames, { data: dataSchemaNames }] = useLazyQuery(SCHEMAS_NAMES_QUERY, {
    variables: { schemaType: formik.values.schemaType },
    fetchPolicy: 'network-only',
  });

  const listOfSchemaNames = useMemo(
    () =>
      dataSchemaNames?.schemata?.map((item) => {
        return { value: item.id, title: item.name, description: item.description };
      }) || [],
    [dataSchemaNames]
  );

  const description = useMemo(()=>listOfSchemaNames?.find((el)=>el.value === formik.values.schemaName)?.description,[listOfSchemaNames, formik.values.schemaName])

  useEffect(() => {
    if (formik.values.schemaType) getSchemaNames().then(() => {});
  }, [formik.values.schemaType]);

  const isFieldRequired = useIsFieldRequired(validationSchema);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.addNewObjectModal.addNewObject}
        forceTitle={true}
        contentStyles={{
          padding: '14px 16px 16px 14px',
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.addNewObjectModal.buttonCancel}
            </Button>
            <Button color="primary" type="submit" onClick={formik.handleSubmit}>
              {msg.addNewObjectModal.buttonAdd}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              name="name"
              label={msg.addNewObjectModal.name}
              clearFieldIcon={true}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              required={isFieldRequired('name')}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              name="schemaType"
              label={msg.addNewObjectModal.schemaType}
              list={[
                { value: 'DEVICE', title: 'Device' },
                { value: 'DATASET', title: 'Dataset' },
                { value: 'APPLICATION', title: 'Application' },
              ]}
              value={formik.values.schemaType}
              onChange={formik.handleChange}
              error={formik.touched.schemaType && Boolean(formik.errors.schemaType)}
              helperText={formik.touched.schemaType && formik.errors.schemaType}
              required={isFieldRequired('schemaType')}
            />
          </Grid>
          <Grid item>
            {(() => {
              const isDisabled = formik.values.schemaType === '';
              return (
                <CustomAutocomplete
                  name="schemaName"
                  label={msg.addNewObjectModal.schemaName}
                  list={listOfSchemaNames}
                  value={formik.values.schemaName}
                  onChange={formik.handleChange}
                  disabled={isDisabled}
                  error={isDisabled ? false : formik.touched.schemaName && Boolean(formik.errors.schemaName)}
                  helperText={isDisabled ? '' : formik.touched.schemaName && formik.errors.schemaName}
                  required={isFieldRequired('schemaName')}
                />
              );
            })()}
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="enabled"
              label={msg.addNewObjectModal.enabled}
              value={formik.values.enabled}
              onChange={formik.handleChange}
              required={isFieldRequired('enabled')}
            />
          </Grid>
          {description &&
            <>
              <Grid item>
                <Typography variant="subtitle2" color="primary">
                  Description
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{description}</Typography>
              </Grid>
            </>
          }
        </Grid>
      </CommonModal>
    </>
  );
};

AddNewObjectModal.propTypes = {
  widgetId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default create(AddNewObjectModal);
