import { createSvgIcon } from '@mui/material';

const ArrowLink = createSvgIcon(
  <path
    d="M17 12L12 17V14H8V10H12V7L17 12ZM3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19ZM5 19H19V5H5V19Z"
    fill="currentColor"
  />,
  'ArrowLink'
);

export default ArrowLink;
