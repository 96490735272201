const modes = [
  {
    value: 'sum',
    title: 'Sum',
  },
  {
    value: 'first',
    title: 'First value',
  },
  {
    value: 'last',
    title: 'Last value',
  },
  {
    value: 'average',
    title: 'Average',
  },
  {
    value: 'median',
    title: 'Median',
  },
  {
    value: 'min',
    title: 'Min',
  },
  {
    value: 'max',
    title: 'Max',
  },
];

// Do not show "sum" and "average" is property is of type "string"
const modesPropertyIsString = [
  {
    value: 'first',
    title: 'First value',
  },
  {
    value: 'last',
    title: 'Last value',
  },
];

const modesForNoAggregation = [
  {
    value: 'no fill',
    title: 'n/a no fill',
  },
  {
    value: 'forward fill',
    title: 'n/a fill previous',
  },
  {
    value: 'backward fill',
    title: 'n/a fill next',
  },
];

const stringTypeId = '00000000-0000-0000-0000-000000000001';

export { modes, modesPropertyIsString, stringTypeId, modesForNoAggregation };
