import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import CommonModal from '../../CommonModal';
import Buttons from './components/Buttons';
import ListItem from './components/ListItem';
import usePropertyInfoData from './hooks/usePropertyInfoData';
import arrayToObject from './utils/arrayToObject';

interface PropertyInfoProps {
  onResolve: () => void;
  isOpen: boolean;
  property: any;
}

const PropertyInfo: FC<PropertyInfoProps & InstanceProps<{}>> = ({ onResolve: handleClose, isOpen, property }) => {
  const { loading, data } = usePropertyInfoData(property);

  return (
    <CommonModal
      key="Index"
      modalOpen={isOpen}
      loading={loading}
      title={property.humanReadable}
      handleClose={handleClose}
      buttons={<Buttons text={JSON.stringify(arrayToObject(data))} handleClose={handleClose} />}
    >
      <Grid container direction="column" spacing={1} minHeight={100}>
        {!loading && data?.map((props, index) => <ListItem key={`listItem-${index}`} {...props} />)}
      </Grid>
    </CommonModal>
  );
};

export default create(PropertyInfo);
