import { gql, useLazyQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { highlightSelectedStyle } from '../../../constants/constants';
import isValidHttpUrl from '../../../utils/isValidUrl';
import useColors from '../../../utils/useColors';
import useCustomNavigate from '../../hooks/useCustomNavigate';
import ArrowLink from '../../icons/arrowLink';
import WidgetEditControls from '../../WidgetEditControls';

export const GET_OBJECT_BEFORE_OPEN = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      objectsToObjectsByObject2Id {
        object1 {
          id
        }
      }
    }
  }
`;

const W_Title = (props) => {
  const { objectProperties, selected } = props;

  const history = useCustomNavigate();

  const isEditMode = useSelector((state) => state.settings.isEditMode);

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;
  const size = getPropValue('settingsSize');
  const wide = getPropValue('settingsFullLine');
  const newLine = getPropValue('settingsNewLine');
  const value = getPropValue('valueText');
  const url = getPropValue('settingsUrl');
  const type = getPropValue('settingsLinkType');
  const uuid = getPropValue('settingsUuid');
  const { getColorBasedOnStyle } = useColors();
  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(getPropValue('settingsStyle'));

  const [load] = useLazyQuery(GET_OBJECT_BEFORE_OPEN, {
    variables: {
      objectId: null,
    },
  });

  //  const theme = useTheme();
  let total_px = 0;
  let font_px = 0;
  let iconLink = '24px';

  switch (size) {
    case 'small':
      total_px = '48';
      font_px = '20';
      break;
    case 'medium':
      total_px = '96';
      font_px = '45';
      iconLink = '40px';
      break;
    case 'large':
      total_px = '192';
      font_px = '90';
      break;
    default:
  } //switch

  const isClickable = () => {
    return url || uuid;
  };

  const handleLinkType = () => {
    if (isValidHttpUrl(url)) {
      window.open(url, '_blank');
    } else if (isValidHttpUrl(`https://${url}`)) {
      window.open(`https://${url}`, '_blank');
    }
  };

  const handleContainerType = () => {
    if (uuid) {
      load({
        variables: {
          objectId: uuid,
        },
      }).then(({ data }) => {
        const dashboardId = data.object.objectsToObjectsByObject2Id[0]?.object1.id;
        history(`/boards/${dashboardId}/${uuid}/`);
      });
    }
  };

  return (
    <>
      <div
        data-widget-title={props.object.name}
        style={{
          overflow: 'hidden',
          display: 'flex',
          flexBasis: wide ? '100%' : 'auto',
          width: '100%',
          position: 'relative',
          height: `100%`,
          backgroundColor: bgColor,
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'none',
          filter: selected ? highlightSelectedStyle : '',
          borderRadius: '2px',
          paddingRight: '8px',
          paddingLeft: '8px',
        }}
      >
        <Typography
          data-widget-title-text={value}
          style={{
            fontFamily: 'Roboto-Medium',
            fontSize: `${font_px}px`,
            color: fgColor,
            display: 'flex',
            alignItems: 'center',
            pointerEvents: 'auto',
            userSelect: 'none',
            cursor: url || uuid ? 'pointer' : 'auto',
          }}
          {...(isClickable()
            ? {
                onClick: () => {
                  if (!isEditMode) {
                    if (type === 'url') {
                      handleLinkType();
                    } else {
                      handleContainerType();
                    }
                  }
                },
              }
            : {})}
        >
          {value}

          {(uuid || url) && <ArrowLink style={{ marginLeft: '6px', width: iconLink, height: iconLink }} />}
        </Typography>
        <WidgetEditControls {...props} />
      </div>
      {newLine && (
        <div
          style={{
            flexBasis: '100%',
            height: '0px',
          }}
        />
      )}
    </>
  );
};

export default W_Title;
