import { gql, useQuery } from '@apollo/client';
import { msg } from '../../../constants/messages';
import { version } from '../../../version';
import { IListItem } from '../modules/ListItem';

const GET_VERSION_QUERY = gql`
  query getVersion {
    getVersion {
      short
      long
    }
  }
`;
const GET_TIMEZONE_QUERY = gql`
  query getTimezone {
    getTimezone
  }
`;

const useAboutModalData = () => {
  const { loading, error, data } = useQuery(GET_VERSION_QUERY);
  const { data: timezoneData } = useQuery(GET_TIMEZONE_QUERY);

  const dataStructure: IListItem[] = [
    { name: 'Application', value: '', type: 'title' },
    { name: msg.aboutModal.pixelCoreVer, value: data?.getVersion?.short ?? '', type: 'text' },
    { name: msg.aboutModal.pixelFrontVer, value: version, type: 'text' },
    { name: msg.aboutModal.pixelCoreTimeZone, value: timezoneData?.getTimezone ?? '', type: 'text' },
    { name: 'About us', value: '', type: 'title' },
    { name: 'PIXEL NETWORKS LIMITED', value: 'https://pixel-networks.com', type: 'link' },
  ];

  return {
    loading,
    error,
    data: dataStructure,
  };
};

export default useAboutModalData;
