import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { msg } from '../../../constants/messages';
import IdentifierIcon from '../../icons/IdentifierIcon';
import useHandleCopy from '../menu/handlers/useHandleCopy';

const ServiceSection = ({ classes, item }) => {
  const handleCopy = useHandleCopy();

  return (
    <>
      <ListSubheader color="primary" className={classes.listSubheader}>
        <Typography variant="subtitle2">{msg.sideCard.serviceInfo}</Typography>
      </ListSubheader>

      <ListItem>
        <ListItemIcon>
          <SubtitlesIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography variant="body1">{item.schema.name}</Typography>} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <IdentifierIcon />
        </ListItemIcon>
        <ListItemText
          onClick={() => {
            handleCopy({ object: item });
          }}
          primary={<Typography variant="body1">{item.id}</Typography>}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <AlternateEmailIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography variant="body1">{item.name}</Typography>} />
      </ListItem>
    </>
  );
};

export default ServiceSection;
