import { useApolloClient } from '@apollo/client';
import ForwardIcon from '@mui/icons-material/Forward';
import { Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { highlightSelectedStyle } from '../../constants/constants';
import { msg } from '../../constants/messages';
import { GET_DATA_SUBSCRIPTION, RPC_CALL_MUTATION } from '../../graphql/queries';
import useColors from '../../utils/useColors';
import ConfirmRpcExecution from '../modals/ConfirmRpcExecution';
import WidgetEditControls from '../WidgetEditControls';

const W_CommandButton = (props) => {
  const { id, objectProperties, selected } = props;

  const theme = useTheme();

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;

  const client = useApolloClient();

  const title = getPropValue('settingsTitle');
  const size = getPropValue('settingsSize');

  const style = getPropValue('settingsStyle');

  const { getColorBasedOnStyle } = useColors();

  const textInitial = getPropValue('valueText') ?? msg.misc.na;

  const [text, setText] = useState(textInitial);
  const [, setAction] = useState(null);

  const valueCurrentColor = getPropValue('valueCurrentColor');

  const [colors, setColors] = useState(getColorBasedOnStyle(style, valueCurrentColor));

  const mode = getPropValue('settingsFormat') || 'icon_value';

  const isEditMode = useSelector((state) => state.settings.isEditMode);

  let arrowColor = colors.bg;

  if (style === 'lightontransparent') arrowColor = theme.palette.blue;
  else if (style === 'darkontransparent') arrowColor = theme.palette.white;

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });

    const subscription = observer.subscribe(({ data }) => {
      // change number on widget
      if (data.Objects.relatedNode?.key === 'valueText') {
        setText(data.Objects.relatedNode?.value);
      } else if (data.Objects.relatedNode?.key === 'valueAction') {
        setAction(data.Objects.relatedNode?.value);
      } else if (data.Objects.relatedNode?.key === 'settingsStyle') {
        setColors(getColorBasedOnStyle(data.Objects.relatedNode?.value));
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  const handleCall = () => {
    ConfirmRpcExecution({
      handleSave: () => {
        toast
          .promise(
            client.mutate({
              mutation: RPC_CALL_MUTATION,
              variables: {
                widgetId: id,
                auth_token: localStorage.getItem('authToken'),
              },
            }),
            {
              loading: 'Calling RPC...',
              success: () => 'RPC called',
              error: (err) => `${err.toString()}`,
            }
          )
          .then(() => {});
      },
      text: 'Execute action?',
    })
      .then()
      .catch(() => {});
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'relative',
        backgroundColor: colors.bg,
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: isEditMode ? 'none' : 'auto',
        filter: selected ? highlightSelectedStyle : '',
        borderRadius: '2px',
        overflow: 'hidden',
        height: '100%',
        paddingRight: '8px',
        paddingLeft: '8px',
      }}
    >
      {mode !== 'value' && (
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            maxWidth: '100%',
            alignItems: !(mode === 'icon_value') ? 'center' : 'flex-end',
          }}
        >
          {size === 'small' && (
            <Tooltip
              title={title ?? ''}
              disableTouchListener
              {...(title ? { disableHoverListener: false } : { disableHoverListener: true })}
            >
              <Fab
                size="large"
                aria-label="command"
                style={{
                  width: '45px',
                  height: '45px',
                  backgroundColor: colors.fg,
                }}
                onClick={handleCall}
              >
                <ForwardIcon
                  style={{
                    color: arrowColor,
                    width: '35px',
                    height: '35px',
                  }}
                />
              </Fab>
            </Tooltip>
          )}

          {size === 'medium' && (
            <Fab
              size="large"
              aria-label="command"
              style={{
                width: '90px',
                height: '90px',
                backgroundColor: colors.fg,
              }}
              onClick={handleCall}
            >
              <ForwardIcon
                style={{
                  color: arrowColor,
                  width: '70px',
                  height: '70px',
                }}
              />
            </Fab>
          )}
        </div>
      )}
      {mode !== 'icon' && (
        <div
          style={{
            display: 'flex',
            maxWidth: '100%',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <Typography
            noWrap={true}
            style={{
              fontSize: size === 'medium' ? '45px' : '20px',
              fontWeight: size === 'tiny' ? '400' : '500',
              color: colors.fg,
            }}
          >
            {text}
          </Typography>
        </div>
      )}
      <WidgetEditControls {...props} />
    </div>
  );
};

export default W_CommandButton;
