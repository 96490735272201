import { useMutation, useQuery } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { create } from 'react-modal-promise';
import * as yup from 'yup';
import DatePickerSingle from '../../../components/common/DatePickerSingle';
import DateRangePicker from '../../../components/common/DateRangePicker';
import TimeRangePicker from '../../../components/common/TimeRangePicker';
import CommonModal from '../../../components/CommonModal';
import CustomSelect from '../../../components/CustomSelect';
import RpcSubscribeWrapper from '../../../components/side-card/static-table/RpcSubscribeWrapper';
import { msg } from '../../../constants/messages';
import { isEmpty } from '../../../utils/isEmpty';
import { CREATE_EXECUTION } from '../api/CreateExecution';
import { GET_REPORT } from '../api/GetReport';
import { UPDATE_PROPERTIES } from '../api/UpdateProperties';
import { HHmm, YYYYMMdd } from './isValidDate';

const TYPE_CUSTOM = 'custom';
// const TYPE_CUSTOM_RANGE = "custom_range";
const TYPE_CUSTOM_SINGLE = 'custom_single';
const TYPE_FULL_DAY = 'full_day';

const FULL_DAY_ITEM = {
  title: 'Full day',
  value: TYPE_FULL_DAY,
};

const CUSTOM_ITEM = {
  title: 'Custom date range',
  value: TYPE_CUSTOM,
};

const CUSTOM_ITEM_DEFAULT = {
  title: 'Custom',
  value: TYPE_CUSTOM,
};

const CUSTOM_SINGLE_ITEM = {
  title: 'Custom single date',
  value: TYPE_CUSTOM_SINGLE,
};

const UpdateReport = (props) => {
  const [loading] = useState(false);
  const [updateProperties, { loading: saveLoading }] = useMutation(UPDATE_PROPERTIES);

  const [createExecution] = useMutation(CREATE_EXECUTION);

  const { loading: reportLoading, data } = useQuery(GET_REPORT, {
    variables: {
      objId: props.reportId,
    },
    fetchPolicy: 'network-only',
  });

  const getPropertyByKey = (key) => {
    if (!data) return;
    return data.object.objectProperties.find((item) => item.key === key);
  };

  const [intervalSettingsDateIntervals, setDateIntervals] = useState([]);
  const [isShowCustomDate, setIsShowCustomDate] = useState(false);
  const [isShowCustomSingleDate, setIsShowCustomSingleDate] = useState(false);

  const [intervalSettingsTimeIntervals, setTimeIntervals] = useState([]);
  const [isShowCustomTime, setIsShowCustomTime] = useState(false);

  const submit = () => props.onResolve();

  const handleClose = () => submit();

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: 'UpdateReport',
            objectId: props.reportId,
            params: {},
          },
        },
      },
    });
  };

  const validationSchema = yup.object({
    time: yup.array().of(yup.string().required('Time is required')),
    date: yup.array().of(yup.string().required('Date is required')),
  });

  const formik = useFormik({
    initialValues: {
      time: [],
      date: [],
      selectedIntervalDate: { title: '', value: '' },
      selectedIntervalTime: { title: '', value: '' },
    },
    validationSchema,
  });

  const handleUpdateParameters = async () => {
    const payload = [
      {
        propertyKey: 'parametersDateRange',
        value: formik.values.date,
      },
      {
        propertyKey: 'parametersTimePeriod',
        value: formik.values.time,
      },
    ];

    await updateProperties({
      variables: {
        input: {
          detailedObject: [{ objectId: props.reportId, keyedProperties: payload }],
        },
      },
    });
    return await rpcHandler();
  };

  useEffect(() => {
    let dateIntervalAcc = [];
    let timeIntervalAcc = [];

    if (data) {
      const intervalSettingsCustomDateRange = getPropertyByKey('intervalSettingsCustomDateRange').value;
      const intervalSettingsCustomSingleDate = getPropertyByKey('intervalSettingsCustomSingleDate').value;
      const intervalSettingsCustomTimeRange = getPropertyByKey('intervalSettingsCustomTimeRange').value;

      dateIntervalAcc = getPropertyByKey('intervalSettingsDateIntervals').value.map((item) => ({
        ...item,
        value: item.value.toString(),
      }));

      if (intervalSettingsCustomDateRange || intervalSettingsCustomSingleDate) {
        if (intervalSettingsCustomDateRange) {
          dateIntervalAcc = [...dateIntervalAcc, CUSTOM_ITEM];
        }

        if (intervalSettingsCustomSingleDate) {
          dateIntervalAcc = [...dateIntervalAcc, CUSTOM_SINGLE_ITEM];
        }
      }

      setDateIntervals(dateIntervalAcc);

      if (intervalSettingsCustomTimeRange) {
        timeIntervalAcc = [
          ...(getPropertyByKey('intervalSettingsTimeIntervals')?.value?.map((item) => ({
            ...item,
            value: item.value.toString(),
          })) || []),
          FULL_DAY_ITEM,
          CUSTOM_ITEM_DEFAULT,
        ];
        setTimeIntervals(timeIntervalAcc);
      } else {
        timeIntervalAcc = [
          ...(getPropertyByKey('intervalSettingsTimeIntervals')?.value?.map((item) => ({
            ...item,
            value: item.value.toString(),
          })) || []),
          FULL_DAY_ITEM,
        ];
        setTimeIntervals(timeIntervalAcc);
      }

      const parametersDateRange = getPropertyByKey('parametersDateRange')?.value || [];
      const parametersTimePeriod = getPropertyByKey('parametersTimePeriod')?.value || [];

      if (!parametersDateRange.length) {
      } else {
        const prevValue = dateIntervalAcc.find((item) => item.value === parametersDateRange.join(','));

        if (!prevValue) {
          if (intervalSettingsCustomDateRange || intervalSettingsCustomSingleDate) {
            if (parametersDateRange[0] === parametersDateRange[1]) {
              formik.setFieldValue('selectedIntervalDate', CUSTOM_SINGLE_ITEM);
              setIsShowCustomSingleDate(true);
            } else {
              formik.setFieldValue('selectedIntervalDate', CUSTOM_ITEM);
              setIsShowCustomDate(true);
            }
            formik.setFieldValue('date', parametersDateRange);
          }
        } else {
          setIsShowCustomDate(false);
          formik.setFieldValue('selectedIntervalDate', prevValue);
          formik.setFieldValue('date', prevValue.value.split(','));
        }
      }

      if (!parametersTimePeriod.length) {
        formik.setFieldValue('selectedIntervalTime', FULL_DAY_ITEM);
        formik.setFieldValue('time', ['00:00', '23:59']);
      } else {
        const isFullDay = isEqual(parametersTimePeriod, ['00:00', '23:59']);
        const prevValue = timeIntervalAcc.find((item) => item.value === parametersTimePeriod.join(','));

        if (!prevValue) {
          if (intervalSettingsCustomTimeRange || isFullDay) {
            formik.setFieldValue('selectedIntervalTime', isFullDay ? FULL_DAY_ITEM : CUSTOM_ITEM_DEFAULT);
            setIsShowCustomTime(!isFullDay);
            formik.setFieldValue('time', parametersTimePeriod);
          }
        } else {
          formik.setFieldValue('selectedIntervalTime', prevValue);
          formik.setFieldValue('time', prevValue.value.split(','));
        }
      }
    }
  }, [data]);

  const DatePickerSingleHelperText = isEmpty(formik.errors) ? '' : formik.errors.date;
  const TimeRangePickerHelperText = isEmpty(formik.errors) ? '' : formik.errors.time;

  return (
    <>
      <CommonModal
        key="DatePicker"
        modalOpen={props.isOpen}
        loading={loading || reportLoading || saveLoading}
        title="Generate report"
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.default.cancel}
            </Button>
            {!Boolean(formik.values.date.length) && <Button disabled={true}>Generate</Button>}
            {Boolean(formik.values.date.length) && (
              <RpcSubscribeWrapper
                rpcName={'UpdateReport'}
                objectId={props.reportId}
                object={null}
                disabled={!isEmpty(formik.errors)}
                handler={handleUpdateParameters}
                title={'Generate'}
                successCb={() => {
                  handleClose();
                }}
              >
                <LoadingButton disabled={true} color="primary"></LoadingButton>
              </RpcSubscribeWrapper>
            )}
          </>
        }
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Date
            </Typography>
          </Grid>

          <Grid item>
            <CustomSelect
              name="date"
              disabled={!intervalSettingsDateIntervals.length}
              label="Date intervals"
              list={intervalSettingsDateIntervals}
              autoKey={true}
              onChange={({ target: { value } }) => {
                if (value === TYPE_CUSTOM) {
                  setIsShowCustomDate(true);
                  setIsShowCustomSingleDate(false);
                  formik.setFieldValue('date', getPropertyByKey('parametersDateRange')?.value || []);
                } else if (value === TYPE_CUSTOM_SINGLE) {
                  setIsShowCustomDate(false);
                  setIsShowCustomSingleDate(true);
                  formik.setFieldValue('date', getPropertyByKey('parametersDateRange')?.value || []);
                } else {
                  setIsShowCustomDate(false);
                  setIsShowCustomSingleDate(false);
                  formik.setFieldValue('date', value.split(','));
                }

                formik.setFieldValue('selectedIntervalDate', {
                  ...formik.values.selectedIntervalDate,
                  value,
                });
              }}
              value={formik.values.selectedIntervalDate}
            />
          </Grid>
          {isShowCustomDate && (
            <Grid item>
              <DateRangePicker
                helperText={DatePickerSingleHelperText}
                selected={formik.values.date}
                onChange={(e) => {
                  if (e && e[0] === YYYYMMdd) {
                    formik.setFieldError('date[0]', 'Start Date is required');
                  } else if (e && e[1] === YYYYMMdd) {
                    formik.setFieldError('date[1]', 'End Date is required');
                  } else {
                    formik.setFieldValue('date', e);
                  }
                }}
              />
            </Grid>
          )}

          {isShowCustomSingleDate && (
            <Grid item>
              <DatePickerSingle
                helperText={DatePickerSingleHelperText}
                selected={formik.values.date}
                onChange={(e) => {
                  if (e[0] === YYYYMMdd) {
                    formik.setFieldError('date[0]', 'Date is required');
                  } else {
                    formik.setFieldValue('date', e);
                  }
                }}
              />
            </Grid>
          )}

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Time
            </Typography>
          </Grid>

          <Grid item>
            <CustomSelect
              name="time"
              label="Time intervals"
              list={intervalSettingsTimeIntervals}
              autoKey={true}
              onChange={({ target: { value } }) => {
                if (value === TYPE_CUSTOM) {
                  formik.setFieldValue('time', getPropertyByKey('parametersTimePeriod')?.value || ['00:00', '23:59']);
                  setIsShowCustomTime(true);
                } else if (value === TYPE_FULL_DAY) {
                  setIsShowCustomTime(false);
                  formik.setFieldValue('time', ['00:00', '23:59']);
                } else {
                  formik.setFieldValue('time', value.split(','));
                  setIsShowCustomTime(false);
                }
                formik.setFieldValue('selectedIntervalTime', {
                  ...formik.values.selectedIntervalTime,
                  value,
                });
              }}
              value={formik.values.selectedIntervalTime}
            />
          </Grid>
          {isShowCustomTime && (
            <>
              <Grid item sx={{ marginTop: '10px' }}>
                <Typography variant="body2" color="gray1">
                  System timezone: UTC
                </Typography>
              </Grid>
              <Grid item>
                <TimeRangePicker
                  helperText={TimeRangePickerHelperText}
                  selected={formik.values.time}
                  onChange={(e) => {
                    if (e && e[0] === HHmm) {
                      formik.setFieldError('time[0]', 'Start Time is required');
                    } else if (e && e[1] === HHmm) {
                      formik.setFieldError('time[1]', 'End Time is required');
                    } else {
                      formik.setFieldValue('time', e);
                    }
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(UpdateReport);
