import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { create } from 'react-modal-promise';
import { msg } from '../../constants/messages';
import CommonModal from '../CommonModal';
import useAboutModalData from './hooks/useAboutModalData';
import IconWindow from './modules/IconWindow';
import ListItem from './modules/ListItem';

type AboutModalProps = {
  onResolve: () => void;
  isOpen: boolean;
};

const AboutModal = ({ onResolve: handleClose, isOpen }: AboutModalProps) => {
  const { loading, error, data } = useAboutModalData();

  return (
    <CommonModal
      modalOpen={isOpen}
      loading={loading}
      title={msg.aboutModal.aboutProgram}
      forceTitle={true}
      handleClose={handleClose}
      buttons={
        <Button data-test-about="close" onClick={handleClose}>
          {msg.aboutModal.buttonOK}
        </Button>
      }
    >
      <Grid container direction="column" spacing={2} minHeight={100}>
        {!loading && (
          <>
            <IconWindow />
            {data?.map((props, index) => (
              <ListItem key={`listItem-${index}`} {...props} />
            ))}
          </>
        )}
        {error && <div>Version query error!</div>}
      </Grid>
    </CommonModal>
  );
};

export default create(AboutModal);
