import ClearIcon from '@mui/icons-material/Clear';
import { LinearProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { castToType } from '../utils/misc.js';

const CustomAutocomplete = (props) => {
  const useStyles = makeStyles({
    option: {
      height: '48px',
    },
  });

  const classes = useStyles();

  const handleInputChangeCasted = (rawValue, type) => {
    const e = {
      target: {
        name: props.name,
        value: castToType(rawValue?.value || null, type),
        rawValue,
      },
    };
    props.onChange(e);
  };

  return (
    <>
      <Autocomplete
        data-test-input={props.label}
        autoComplete
        filterOptions={props.filterOptions}
        classes={classes}
        clearIcon={props.clearFieldIcon ? <ClearIcon /> : null}
        ListboxProps={{
          style: {
            maxHeight: '320px',
          },
        }}
        value={
          props.value
            ? {
                value: props.value,
                title: props.list.find((obj) => obj.value === props.value)?.title,
              }
            : null
        }
        disabled={Boolean(props.disabled)}
        onChange={(e, value) => handleInputChangeCasted(value, props.propType)}
        onInputChange={props.onInputChange}
        fullWidth={true}
        groupBy={props.groupBy}
        clearOnBlur={false}
        options={props.list}
        isOptionEqualToValue={(option, value) => value.value === option.value}
        getOptionLabel={(option) => option.title || ''}
        getOptionDisabled={(option) => option.disabled}
        noOptionsText={props.noOptionsText}
        onClose={props.onClose}
        renderInput={(params) => (
          <div style={{ position: 'relative' }}>
            <TextField
              {...params}
              error={props.error}
              disabled={Boolean(props.disabled)}
              helperText={props.helperText}
              label={props.label}
              variant="standard"
              required={props.required}
            />
            {props.loading && (
              <LinearProgress
                style={{
                  width: '100%',
                  position: 'absolute',
                  bottom: 0,
                  height: 2,
                }}
              />
            )}
          </div>
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.title, inputValue, {
            insideWords: false,
            requireMatchAll: true,
          });
          const parts = parse(option.title, matches);

          return (
            <li {...props} key={option.value} style={{ textAlign: 'left' }}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
    </>
  );
};

export default CustomAutocomplete;
