import { COLOR_SCHEMAS } from '../../../constants/constants';

export default function getColorOfRow(index, colors) {
  const isOdd = () => index % 2;

  if (isOdd()) {
    return '';
  }

  const theme = colors[0];

  switch (theme) {
    case COLOR_SCHEMAS.DARK_ON_LIGHT:
      return '#F1F1F1';
    default:
      return 'rgba(255, 255, 255, 0.15)';
  }
}
