import { gql } from '../../../__generated__';

const REPORTS_LIST = gql(/* GraphQL */ `
  query getReports {
    reports: objects(filter: { schemaTags: { contains: ["application", "board", "report"] } }, orderBy: NAME_ASC) {
      id
      name
    }
  }
`);

const REPORTS_QUERY_WITHOUT_COLLECTIONS = gql(/* GraphQL */ `
  query getReportsWithoutCollections {
    reports: objects(
      filter: {
        schemaTags: { contains: ["application", "board", "report"] }
        objectsToObjectsByObject1IdConnection: {
          every: { object2: { not: { schemaTags: { contains: ["collection"] } } } }
        }
      }
      orderBy: NAME_ASC
    ) {
      id
      name
    }
  }
`);

export { REPORTS_LIST, REPORTS_QUERY_WITHOUT_COLLECTIONS };
