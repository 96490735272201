import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';

// <CustomSwitch name="compactMode" label={msg.addDashboardModal.compactMode} value={values.compactMode} propType="bool" onChange={handleInputChange} />
// propType = bool|number|string (string - default). forces type casting of returned value

const CustomSwitch = (props) => {
  const handleInputChangeCasted = (event) => {
    let { name, checked } = event.target;
    const e = {
      target: { name: name, value: checked, checked: checked },
    };
    props.onChange(e);
  };

  return (
    <>
      <InputLabel htmlFor={`${props.name}-label`} required={props.required}>
        {props.label}
      </InputLabel>
      <Switch
        name={props.name}
        id={`${props.name}-label`}
        value={props.value === 'true' ? 'on' : ''}
        checked={Boolean(props.value)}
        disabled={Boolean(props.disabled)}
        onChange={(e) => {
          handleInputChangeCasted(e, props.propType);
        }}
        color="primary"
      />
    </>
  );
};

export default CustomSwitch;
