import {
  SettingsFormatStaticTable,
  SettingsSizeOptions,
  SettingsStyleOptions,
  SmoothTypeEnum,
  TypeOptionsEnum,
} from '../../../utils/constants/selectOptions';
import { ScaleOptionsEnum } from '../regular-chart/constants';

interface TRegularTrackingTableState {
  settingsShowTitle: boolean;
  settingsShowSubtitle: boolean;
  settingsShowRefreshButtons: boolean;
  settingsStyle: SettingsStyleOptions;
  settingsChartSize: SettingsSizeOptions;
  chartChartType: TypeOptionsEnum;
  chartLineWidth: 2 | 4 | 6 | 8;
  chartSmoothType: SmoothTypeEnum;
  settingsYaxisScale?: ScaleOptionsEnum;
  settingsMaximum: number;
  settingsMinimum: number;
  settingsFormat: SettingsFormatStaticTable;
  settingsWidgetControls: {
    datePresets: boolean;
    propertySelect: boolean;
    intervalRange: boolean;
    aggregationFunctionSelect: boolean;
    geotagSelect: boolean;
    monitorGroup: boolean;
    csvButton: boolean;
  };
}

export const FORM_DEFAULT_VALUES: TRegularTrackingTableState = {
  settingsStyle: SettingsStyleOptions.lightondark,
  settingsFormat: SettingsFormatStaticTable.table,
  settingsShowTitle: true,
  settingsShowSubtitle: false,
  settingsShowRefreshButtons: false,
  settingsChartSize: SettingsSizeOptions.medium,
  chartChartType: TypeOptionsEnum.line,
  chartSmoothType: SmoothTypeEnum.smooth,
  settingsYaxisScale: ScaleOptionsEnum.auto,
  settingsMaximum: 0,
  settingsMinimum: 100,
  chartLineWidth: 2,
  settingsWidgetControls: {
    datePresets: true,
    propertySelect: true,
    intervalRange: true,
    aggregationFunctionSelect: true,
    geotagSelect: true,
    monitorGroup: true,
    csvButton: true,
  },
};
