import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useReducer, useState } from 'react';
import CommonModal from '../components/CommonModal';
import CustomInput from '../components/CustomInput';
import FormField from '../components/FormField';
import { msg } from '../constants/messages';

import { useFormik } from 'formik';
import { loader } from 'graphql.macro';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import { useParams } from 'react-router-dom';
import { dispatch as dispatchBus } from 'use-bus';
import * as yup from 'yup';
import { WIDGET_QUERY } from '../graphql/queries';
import useFormikRequiredProps from '../hooks/formik/useFormikRequiredProps';
import useRoute from '../hooks/useRoute';
import groupNames from '../utils/groupNames';
import { widgetSizes } from '../utils/widgetSizes';
import { getWidgetType } from '../utils/widgetType';

const UPDATE_GROUP_MUTATION = loader('../graphql/UpdateGroupMutation.graphql');
const UPDATE_PROPERTY = loader('../graphql/UpdatePropertyMutation.graphql');
const DASHBOARD_QUERY = loader('../graphql/DashboardQuery.graphql');

const getGroupNames = (widgetType) => {
  if (widgetType === 'title') {
    return [{ groupName: { equalTo: 'Settings' } }, { groupName: { equalTo: 'Value' } }];
  } else if (widgetType === 'history table' || widgetType === 'monitor table') {
    return [{ groupName: { equalTo: 'Settings' } }, { groupName: { equalTo: 'Columns' } }];
  } else {
    return [{ groupName: { equalTo: 'Settings' } }];
  }
};

const validationSchema = yup.object({
  widgetType: yup.string(),
  name: yup.string().required('Name is required'),
  description: yup.string(),
});

const initialValues = {
  widgetType: '',
  name: '',
  description: '',
};

const EditWidgetModal = (props) => {
  const [updatePropertyLayout] = useMutation(UPDATE_PROPERTY);
  const [updateGroup] = useMutation(UPDATE_GROUP_MUTATION);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ name, description }) => {
      handleUpdateWidget({
        widgetId: props.object.id,
        values: Object.keys(values).map((key) => {
          return { propertyKey: key, value: values[key] };
        }),
        name,
        description,
      });
    },
  });

  const widgetType = getWidgetType(props.object.schemaTags);

  const [initialSize, setInitialSize] = useState('');

  const [fields, setFields] = useState([]);

  const { getParamsByRoute } = useRoute();
  const { groupId } = getParamsByRoute();
  const params = useParams();

  const entityId = params.dashboardId || params.reportId;

  const { data: dashboardData } = useQuery(DASHBOARD_QUERY, {
    variables: {
      dashboardId: entityId,
    },
  });

  const { loading: widgetPropsLoading } = useQuery(WIDGET_QUERY, {
    variables: {
      objId: props.object.id,
      groupNames: getGroupNames(widgetType),
    },
    onCompleted: (data) => {
      let localValues = {};
      data.object.objectProperties.forEach((prop) => {
        localValues[prop.key] = prop.value;
      });

      setDefaultValues(localValues);

      // fields description and types for components {valueSet, key, description, type.name}
      const fields = data.object.objectProperties
        .map((prop) => {
          return {
            propId: prop.id,
            key: prop.key,
            description: prop.spec?.description,
            type: prop.spec?.type,
            valueSet: prop.spec?.valueSet,
            groupName: prop.groupName,
            valueRange: prop.spec?.valueRange,
            hidden: prop.spec?.hidden,
          };
        })
        .filter((item) => groupNames[widgetType].find((groupName) => groupName === item.groupName) && !item.hidden);

      setFields(fields); // description/component types for each field
      setValues(localValues); // loaded values for each field
      formik.setFieldValue('widgetType', data.object.schemaName);
      formik.setFieldValue('name', data.object.name);
      setInitialSize(localValues.settingsSize);
      formik.setFieldValue('description', data.object.description);

      dispatchBus('@@board/SAVE_BOARD');
    },
    fetchPolicy: 'network-only',
  });

  const targetGroup = dashboardData?.dashboard.groups.find(({ group }) => group.id === groupId);
  const targetGroupLayout = targetGroup?.group.layouts[0];

  const getSizeOfWidget = () => {
    return widgetSizes(values)[widgetType](targetGroup.group.type[0].value);
  };

  const [defaultValues, setDefaultValues] = useState({});

  const handleUpdateWidget = () => {
    const properties = [];

    for (let prop in values) {
      if (values[prop] !== defaultValues[prop]) {
        properties.push({
          propertyKey: prop,
          value: values[prop],
        });
      }
    }

    toast
      .promise(
        updateGroup({
          variables: {
            id: props.object.id,
            name: formik.values.name,
            description: formik.values.description,
            values: properties,
          },
        }),
        {
          loading: 'Updating widget...',
          success: () => msg.editWidgetModal.updated,
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => {
        if (initialSize !== values.settingsSize) {
          return updatePropertyLayout({
            variables: {
              input: {
                id: targetGroupLayout.id,
                patch: {
                  value: [
                    ...targetGroupLayout.value.filter((i) => i.i !== props.object.id),
                    {
                      ...targetGroupLayout.value.find((i) => i.i === props.object.id),
                      ...getSizeOfWidget(),
                    },
                  ],
                },
              },
            },
          });
        } else {
          submit();
        }
      })
      .then(() => {
        dispatchBus('@@board/RESET_DASHBOARD_LOCAL_STATE');
        submit();
      });
  };

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (checked) value = checked;
    setValues({ [name]: value });
  };

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const formikRequiredProps = useFormikRequiredProps(formik, validationSchema);

  return (
    <>
      <CommonModal
        loading={widgetPropsLoading}
        modalOpen={props.isOpen}
        title={msg.editWidgetModal.editWidget}
        handleClose={reject}
        buttons={
          <>
            <Button data-test="cancelEditWidget" color="inherit" onClick={reject}>
              {msg.editWidgetModal.buttonCancel}
            </Button>
            <Button
              data-test="editWidget"
              color="primary"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {msg.editWidgetModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput {...formikRequiredProps('widgetType')} label={msg.editWidgetModal.widgetType} disabled />
          </Grid>
          <Grid item>
            <CustomInput {...formikRequiredProps('name')} label={msg.editWidgetModal.name} />
          </Grid>
          {fields.map((field) => (
            <FormField
              key={field.key}
              values={values}
              field={field}
              handleInputChange={handleInputChange}
              widgetId={props.object.id}
              propId={field.propId}
            />
          ))}
          <Grid item>
            <CustomInput {...formikRequiredProps('description')} label={msg.editWidgetModal.description} multiline />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditWidgetModal);
