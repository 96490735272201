import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  objectHoveredId: null | string;
} = {
  objectHoveredId: null,
};

export const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setContext: (state, action: PayloadAction<{ objectHoveredId: null | string }>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setContext } = contextSlice.actions;

export const contextReducer = contextSlice.reducer;
