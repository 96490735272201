import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers';
import { compareAsc, format, isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { HHmm, onChangeDatePicker } from '../../modules/reports/components/isValidDate';
import ClockOutline from '../icons/clockOutline';

const defaultStartDate = new Date();

defaultStartDate.setHours(0);
defaultStartDate.setMinutes(0);
defaultStartDate.setSeconds(0);

const defaultEndDate = new Date();

defaultEndDate.setHours(23);
defaultEndDate.setMinutes(59);
defaultEndDate.setSeconds(0);

const TimeRangePicker = (props) => {
  const { helperText } = props;
  const [startDateHelperText, endDateHelperText] = helperText?.value || helperText || ['', ''];
  const theme = useTheme();

  const formatStart = props.formatStart || HHmm;
  const formatEnd = props.formatEnd || HHmm;

  const [openStart] = useState(false);

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const handleSave = () => {
    if (!startDate && !endDate) {
      props.onChange([HHmm, HHmm]);
    } else if (isValid(startDate) && isValid(endDate)) {
      props.onChange([format(startDate, formatStart), format(endDate, formatEnd)]);
    } else if (isValid(startDate) && !isValid(endDate)) {
      props.onChange([format(startDate, formatStart), HHmm]);
    } else if (!isValid(startDate) && isValid(endDate)) {
      props.onChange([HHmm, format(endDate, formatEnd)]);
    }
  };

  useEffect(() => {
    handleSave();
  }, []);

  useEffect(() => {
    if (props?.selected?.length === 2) {
      const dateStart = new Date();
      const dateEnd = new Date();
      const startTime = props.selected[0].split(':');
      const endTime = props.selected[1].split(':');

      dateStart.setHours(startTime[0]);
      dateStart.setMinutes(startTime[1]);
      dateStart.setSeconds(0);
      setStartDate(dateStart);

      dateEnd.setHours(endTime[0]);
      dateEnd.setMinutes(endTime[1]);
      dateEnd.setSeconds(0);

      setEndDate(dateEnd);
    }
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      if (compareAsc(startDate, endDate) === 1) {
        setEndDate(null);
      }
    }

    handleSave();
  }, [startDate, endDate]);
  return (
    <>
      <Grid item>
        <div style={{ display: 'flex' }}>
          <div style={{ padding: '16px 16px 16px 0px' }}>
            <ClockOutline style={{ color: theme.palette.gray1 }} />
          </div>
          <TimePicker
            ampm={false}
            clearable
            disableOpenPicker
            open={openStart}
            slotProps={{
              textField: {
                helperText: startDateHelperText,
              },
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                InputProps={{
                  ...props.inputProps,
                  readOnly: true,
                }}
                data-test-timepicker="start"
                style={{ width: '100%' }}
                variant="standard"
                label="From"
                helperText={startDateHelperText}
              />
            )}
            disableFuture={true}
            inputFormat={HHmm}
            value={startDate}
            onChange={(date) => {
              onChangeDatePicker(date, setStartDate);
            }}
          />
        </div>
      </Grid>
      <Grid item>
        <div style={{ display: 'flex' }}>
          <div style={{ padding: '16px 16px 16px 0px' }}>
            <ClockOutline style={{ color: theme.palette.gray1 }} />
          </div>
          <TimePicker
            ampm={false}
            clearable
            disableFuture={true}
            disableOpenPicker
            slotProps={{
              textField: {
                helperText: endDateHelperText,
              },
            }}
            renderInput={(props) => (
              <TextField
                data-test-timepicker="end"
                style={{ width: '100%' }}
                {...props}
                InputProps={{
                  ...props.inputProps,
                  readOnly: true,
                }}
                variant="standard"
                label="To"
                helperText={endDateHelperText}
              />
            )}
            inputFormat={HHmm}
            variant="inline"
            value={endDate}
            onChange={(date) => {
              onChangeDatePicker(date, setEndDate);
            }}
          />
        </div>
      </Grid>
    </>
  );
};

export default TimeRangePicker;
