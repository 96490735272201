import { gql, useLazyQuery } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { dispatch as dispatchBus } from 'use-bus';
import * as yup from 'yup';
import CommonModal from '../../../components/CommonModal';
import { OBJECT_TYPES } from '../../../constants/constants';
import { msg } from '../../../constants/messages';
import useFormikRequiredProps from '../../../hooks/formik/useFormikRequiredProps';
import { SETTINGS_STYLE_OPTIONS } from '../../../utils/constants/selectOptions';
import { getPropertyValueByKey } from '../../../utils/getPropertyByKey';
import { isMonitoringObject, isStatistics } from '../../../utils/objectType';
import { WIDGETS_ENUM } from '../../../utils/widgetTypes';
import CustomInput from '../../CustomInput';
import CustomSelect from '../../CustomSelect';
import CustomSwitch from '../../CustomSwitch';
import SchemasAutocomplete from '../../forms/SchemasAutocomplete';
import { TDefaultWidgetProps } from '../types';
import { useAddWidget } from '../useAddWidget';
import { FORM_DEFAULT_VALUES } from './constants';

export const SELECTED_SCHEMA = gql`
  query selectedSchema($id: UUID!) {
    schema(id: $id) {
      type
      mTags
    }
  }
`;

const CreateTrackingTable = (props: TDefaultWidgetProps & InstanceProps<{}>) => {
  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const { createWidgetFn, updateWidget, isLoading } = useAddWidget({
    group: props.group,
    widgetType: WIDGETS_ENUM.TRACKING_TABLE,
    cb: submit,
  });

  const isEdit = Boolean(props?.widget?.id);

  const getPrevValues = () => {
    const defaultValues = {
      ...FORM_DEFAULT_VALUES,
    };

    if (props?.widget?.objectProperties) {
      for (const property in FORM_DEFAULT_VALUES) {
        defaultValues[property] = getPropertyValueByKey(props.widget.objectProperties, property);
      }
    }

    return defaultValues;
  };

  const validationSchema = yup.object({
    name: yup.string().trim().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      schemaType: 'monitor',
      description: isEdit ? props.widget.description : '',
      name: props.name,
      properties: {
        ...getPrevValues(),
      },
    },
    validationSchema,
    onSubmit: ({ properties, name, description }) => {
      if (isEdit) {
        updateWidget({
          values: properties,
          defaultValues: { ...getPrevValues() },
          id: props.widget.id,
          name,
          description,
          cb: submit,
        });
      } else {
        createWidgetFn({
          values: properties,
          name,
          description,
          cb: submit,
        });
      }
    },
  });

  const [loadSchema] = useLazyQuery(SELECTED_SCHEMA, {
    onCompleted: (e) => {
      const type = e?.schema?.type?.toLowerCase() || 'device';
      const tags = e?.schema?.mTags || [];

      if (isStatistics(tags)) {
        formik
          .setValues({ ...formik.values, schemaType: 'stat' })
          .then(() => {})
          .catch(() => {});
      } else if (isMonitoringObject(tags)) {
        formik
          .setValues({ ...formik.values, schemaType: 'monitor' })
          .then(() => {})
          .catch(() => {});
      } else {
        formik
          .setValues({ ...formik.values, schemaType: type })
          .then(() => {})
          .catch(() => {});
      }
    },
    fetchPolicy: 'network-only',
    variables: {
      id: formik.values.properties.parametersSchema,
    },
  });

  useEffect(() => {
    if (formik.values.schemaType) {
      loadSchema()
        .then(() => {})
        .catch(() => {});
    }
  }, [formik.values.properties.parametersSchema]);

  useEffect(() => {
    if (isEdit) {
      dispatchBus('@@board/SAVE_BOARD');
    }
  }, []);

  const formikRequiredProps = useFormikRequiredProps(formik, validationSchema);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={isEdit ? 'Edit tracking history table' : 'Add tracking history table'}
        handleClose={reject}
        loading={isLoading}
        buttons={
          <>
            <Button data-test="close-chart" color="inherit" onClick={reject}>
              {msg.addWidgetModal.buttonCancel}
            </Button>
            <Button
              disabled={isLoading}
              data-test="save-regular-chart"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          {isEdit && (
            <>
              <Grid item>
                <CustomInput label="Name" clearFieldIcon={true} {...formikRequiredProps('name')} />
              </Grid>
            </>
          )}
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Content
            </Typography>
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="properties.settingsShowTitle"
              label="Title"
              value={formik.values.properties.settingsShowTitle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              name="schemaType"
              label="Schema type"
              value={formik.values.schemaType}
              list={OBJECT_TYPES}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <SchemasAutocomplete
              schemaId={formik.values.properties.parametersSchema}
              type={formik.values.schemaType}
              name="properties.parametersSchema"
              handleChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Appearance
            </Typography>
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsStyle"
              label="Style"
              list={SETTINGS_STYLE_OPTIONS}
              value={formik.values.properties.settingsStyle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomInput
              name="description"
              label={msg.addWidgetModal.description}
              clearFieldIcon={true}
              multiline={true}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(CreateTrackingTable);
