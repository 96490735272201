import { useQuery } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SortIcon from '@mui/icons-material/Sort';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { loader } from 'graphql.macro';
import { useState } from 'react';
import MainToolbar from './MainToolbar';
import CustomListItem from './side-list/CustomListItem';
import useMoreMenu from './useMoreMenu';

const DASHBOARDS_QUERY = loader('../graphql/DashboardsQuery.graphql');

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: '40px', // to maintain distance   icon <---> text
  },

  listLink: {
    color: theme.palette.black,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.black,
    },
  },

  item: {
    width: '412px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  fixedHeader: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer - 1,
    marginTop: '0px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
    borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
    backgroundColor: theme.palette.background.default,
    width: 'inherit', // to fit fixedHeader into <Drawer/> (on desktops)
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  listWrapper: {
    marginTop: '60px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const FixedHeader = (props) => {
  const { dashboardId, sortType, setSortType, itemsChecked, setItemsChecked } = props;
  const classes = useStyles();

  const { MoreMenu: HeaderMenu, openMoreMenu: openHeaderMenu, closeMoreMenu: closeHeaderMenu } = useMoreMenu();

  const { MoreMenu: HeaderGroupMenu, openMoreMenu: openHeaderGroupMenu } = useMoreMenu();
  const { MoreMenu: FilterMenu, closeMoreMenu: closeFilterMenu } = useMoreMenu();
  const { MoreMenu: SortMenu, openMoreMenu: openSortMenu, closeMoreMenu: closeSortMenu } = useMoreMenu();

  const handleHeaderMenuItemClick = (item) => {
    props.handleMenuActionClick({
      action: item.action,
      dashboardId: dashboardId,
      itemsChecked: itemsChecked,
    });
    closeHeaderMenu();
  };

  // header filter menu

  const handleFilterMenuItemClick = () => {
    closeFilterMenu();
  };

  // header sort menu

  const handleSortMenuItemClick = (item) => {
    setSortType(item);
    closeSortMenu();
  };

  const { data } = useQuery(DASHBOARDS_QUERY);

  const dashboards = data?.dashboards || [];

  return (
    <>
      {dashboards.length === 0 && (
        <div className={classes.fixedHeader}>
          <Button
            color="primary"
            style={{ textTransform: 'none' }}
            endIcon={<ArrowDropDownIcon color="primary" />}
            onClick={openHeaderMenu}
          >
            <Typography variant="h6">No dashboards</Typography>
          </Button>
        </div>
      )}

      {itemsChecked.length === 0 && (
        <div className={classes.fixedHeader}>
          <MainToolbar />
          <div style={{ display: 'flex', paddingTop: '16px' }}>
            <Button
              data-dashboards-list-menu={props.dashboardName}
              color="primary"
              style={{ textTransform: 'none' }}
              endIcon={<ArrowDropDownIcon color="primary" />}
              onClick={openHeaderMenu}
            >
              <Typography noWrap variant="h6" style={{}} title={props.dashboardName}>
                {props.dashboardName}
              </Typography>
            </Button>
            <div style={{ flexGrow: 1 }} />
            {/*<IconButton onClick={openFilterMenu} size="large" title="Filter">*/}
            {/*  <TuneIcon color="primary" />*/}
            {/*</IconButton>*/}
            <IconButton onClick={openSortMenu} size="large" title="Sort">
              <SortIcon color="primary" />
            </IconButton>
          </div>
        </div>
      )}

      {itemsChecked.length > 0 && (
        <div className={classes.fixedHeader}>
          <MainToolbar />
          <div style={{ display: 'flex', paddingTop: '16px' }}>
            <Button
              color="primary"
              style={{ textTransform: 'none' }}
              startIcon={<ArrowBackIosIcon color="primary" />}
              onClick={() => {
                setItemsChecked([]);
              }}
            >
              <Typography variant="h6" style={{}}>
                Selected: {itemsChecked.length}
              </Typography>
            </Button>
            <div style={{ flexGrow: 1 }} />
            <IconButton style={{ display: 'none' }} size="large">
              <StarBorderIcon color="primary" />
            </IconButton>
            <IconButton onClick={openHeaderGroupMenu} size="large">
              <MoreVertIcon color="primary" />
            </IconButton>
            <IconButton onClick={openSortMenu} size="large">
              <SortIcon color="primary" />
            </IconButton>
          </div>
        </div>
      )}

      <HeaderMenu
        items={props.headerMenu}
        handleMenuItemClick={() => handleHeaderMenuItemClick({ action: 'add_new_group' })}
      />

      <HeaderGroupMenu
        items={props.headerGroupMenu}
        handleMenuItemClick={() =>
          handleHeaderMenuItemClick({
            action: 'delete_groups',
            items: [1, 2, 3],
          })
        }
      />

      <FilterMenu
        checkable={true}
        items={[
          { title: 'No filter', id: 'no_filter', disabled: true },
          { title: 'Disable', id: 'disable', disabled: true },
        ]}
        handleMenuItemClick={handleFilterMenuItemClick}
      />

      <SortMenu
        checkable={true}
        items={[
          {
            title: 'Name',
            id: 'name',
            checked: sortType === 'name',
            disabled: false,
          },
          {
            title: 'Status',
            id: 'enabled',
            checked: sortType === 'enabled',
            disabled: false,
          },
        ]}
        handleMenuItemClick={handleSortMenuItemClick}
      />
    </>
  );
};

const SideList = (props) => {
  const { onlyHeader, getList } = props;
  const classes = useStyles();
  const [sortType, setSortType] = useState('name');

  return (
    <>
      <div className={classes.item} style={{}}>
        {props.loading && <LinearProgress />}
        <FixedHeader
          {...props}
          dashboardName={props.title}
          sortType={sortType}
          setSortType={setSortType}
          itemsChecked={props.itemsChecked}
          setItemsChecked={props.setItemsChecked}
        />

        {!onlyHeader && (
          <List className={classes.listWrapper} style={{ marginTop: '119px' }}>
            {getList(sortType).map((item, index) => (
              <CustomListItem
                key={index}
                index={index}
                item={item}
                itemsChecked={props.itemsChecked}
                setItemsChecked={props.setItemsChecked}
                {...props}
              />
            ))}
          </List>
        )}
      </div>
    </>
  );
};
export default SideList;
