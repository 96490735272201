import { gql, useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { create } from 'react-modal-promise';
import * as yup from 'yup';
import CommonModal from '../../../../components/CommonModal';
import { msg } from '../../../../constants/messages';
import useIsFieldRequired from '../../../../hooks/formik/useIsFieldRequired';
import CustomInput from '../../../CustomInput';
import CustomSelectColor from '../../../CustomSelectColor';

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const LevelChartModal = (props) => {
  const { isEdit, settingsLevel, index } = props;

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const [updateOneProperty, { loading }] = useMutation(UPDATE_PROPERTY);

  const validationSchema = yup.object({
    label: yup.string().trim().required('Label is required'),
    value: yup.number().required('Value is required'),
  });

  const formik = useFormik({
    initialValues: {
      label: '',
      color: 'orange',
      value: '',
    },
    validationSchema: validationSchema,
    onSubmit: (valuesForm) => {
      const levelId = settingsLevel?.id;
      if (!levelId) {
        return;
      }

      if (isEdit) {
        const oldValues = cloneDeep(settingsLevel?.value);
        oldValues.splice(index, 1, valuesForm);

        updateOneProperty({
          variables: {
            input: {
              id: levelId,
              patch: {
                value: oldValues,
              },
            },
          },
        }).then(() => {
          submit();
        });
      } else {
        let prev = cloneDeep(settingsLevel?.value || []);
        prev.push(valuesForm);

        updateOneProperty({
          variables: {
            input: {
              id: levelId,
              patch: {
                value: prev,
              },
            },
          },
        }).then(() => {
          submit();
        });
      }
    },
  });

  useEffect(() => {
    if (isEdit) {
      const target = cloneDeep(settingsLevel?.value[index]);

      if (target) {
        formik.setValues(target);
      }
    }
  }, [isEdit]);

  const isFieldRequired = useIsFieldRequired(validationSchema);

  return (
    <>
      <CommonModal
        loading={loading}
        modalOpen={props.isOpen}
        title={isEdit ? 'Edit level' : 'Add level'}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={formik.handleSubmit}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <CustomInput
              type="number"
              name="value"
              label="Value"
              clearFieldIcon={true}
              value={formik.values.value}
              onChange={formik.handleChange}
              error={formik.touched.value && Boolean(formik.errors.value)}
              helperText={formik.touched.value && formik.errors.value}
              required={isFieldRequired('value')}
            />
          </Grid>
          <Grid item>
            <CustomInput
              name="label"
              label="Label"
              clearFieldIcon={true}
              value={formik.values.label}
              onChange={formik.handleChange}
              error={formik.touched.label && Boolean(formik.errors.label)}
              helperText={formik.touched.label && formik.errors.label}
              required={isFieldRequired('label')}
            />
          </Grid>
          <Grid item>
            <CustomSelectColor
              name="color"
              colors={['red', 'blue', 'green', 'yellow', 'orange']}
              label="Color"
              value={formik.values.color}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(LevelChartModal);
