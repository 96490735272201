import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { loader } from 'graphql.macro';
import { useEffect, useReducer, useState } from 'react';
import { create } from 'react-modal-promise';
import * as yup from 'yup';
import CommonModal from '../components/CommonModal';
import CustomInput from '../components/CustomInput';
import FormField from '../components/FormField';
import { msg } from '../constants/messages';
import { WIDGETS_PROPS_QUERY } from '../graphql/queries';
import useRoute from '../hooks/useRoute';
import groupNames from '../utils/groupNames';
import { widgetSizes } from '../utils/widgetSizes';
import { WIDGETS_ENUM, WIDGETS_FOR_BOARD, WIDGETS_FOR_REPORT } from '../utils/widgetTypes';
import useCustomNavigate from './hooks/useCustomNavigate';

const CREATE_WIDGET = loader('../graphql/CreateWidgetMutation.graphql');
const UPDATE_PROPERTY = loader('../graphql/UpdatePropertyMutation.graphql');
const DASHBOARD_QUERY = loader('../graphql/DashboardQuery.graphql');

const AddWidgetModal = (props) => {
  let defaultValues = {};

  const [fields, setFields] = useState([]);
  const { isBoard, isReport, entityId } = useRoute();

  const [widgetType, setWidgetType] = useState(isReport() ? WIDGETS_ENUM.TITLE : WIDGETS_ENUM.DATABOX);
  const [name, setName] = useState(props?.name || '');
  const [description, setDescription] = useState('');
  const [createWidget, { loading: isAddingWidget }] = useMutation(CREATE_WIDGET);
  const [updatePropertyLayout, { loading: isUpdatingLayout }] = useMutation(UPDATE_PROPERTY);
  const { data: dashboardData, loading: dashboardLoading } = useQuery(DASHBOARD_QUERY, {
    variables: {
      dashboardId: entityId(),
    },
    fetchPolicy: 'network-only',
  });

  const [, setWidgetList] = useState([]);

  const [loadProps, { loading: widgetPropsLoading }] = useLazyQuery(WIDGETS_PROPS_QUERY, {
    variables: { widgetType },
    onCompleted: (data) => {
      setFields(data.schemata[0].schemaProperties);

      data.schemata[0].schemaProperties.forEach((prop) => {
        defaultValues[prop.key] = prop.defaultValue;
      });

      setValues(defaultValues);
      const typeName = [...WIDGETS_FOR_REPORT, ...WIDGETS_FOR_BOARD].find((item) => item.value === widgetType)?.title;

      formik.setValues({
        name: `${typeName} #${data.schemata[0].objectsCount + 1}`,
      });

      if (props?.name) {
        formik.setValues({
          name: props.name,
        });
      }
    },
    fetchPolicy: 'network-only',
  });

  const getWidgetsList = () => {
    if (isBoard()) {
      return WIDGETS_FOR_BOARD;
    }

    if (isReport()) {
      return WIDGETS_FOR_REPORT.filter((item) => {
        if (!isMinimap()) {
          return !item.isMinimapOnly;
        } else {
          return item;
        }
      });
    }
  };

  const targetGroup = dashboardData?.dashboard.groups.find(({ group }) => group.id === props.groupId);
  const isMinimap = () => targetGroup?.group.type[0]?.value === 'minimap';

  const targetGroupLayout = targetGroup?.group?.layouts[0];

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  const navigate = useCustomNavigate();

  useEffect(() => {
    if (props?.widgetType) {
      setWidgetType(props.widgetType);
    }

    if (props?.name) {
      setName(props.name);
    }
  }, []);

  useEffect(() => {
    loadProps({ variables: { widgetType } }).then(() => {});
  }, [widgetType]);

  useEffect(() => {
    if (dashboardData) {
      setWidgetList(getWidgetsList());
    }
  }, [dashboardData]);

  const validationSchema = yup.object({
    name: yup.string().trim().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name,
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      createWidget({
        variables: {
          groupId: props.groupId,
          values: Object.keys(values).map((key) => {
            return { propertyKey: key, value: values[key] };
          }),
          widgetType: widgetType,
          name: data.name,
          description: description,
        },
      })
        .then(({ data }) => {
          const newLayout = reCalculateLayoutGroup(data.createObjectWithProperties.uuid);
          return Promise.all([
            updatePropertyLayout({
              variables: {
                input: {
                  id: targetGroupLayout.id,
                  patch: {
                    value: newLayout,
                  },
                },
              },
            }),
            Promise.resolve(data.createObjectWithProperties.uuid),
          ]);
        })
        .then(([_, id]) => {
          if (isReport()) {
            navigate(`/reports/${entityId()}/${props.groupId}/${id}`);
          }

          if (isBoard()) {
            navigate(`/boards/${entityId()}/${props.groupId}/${id}`);
          }
          submit();
        });
    },
  });

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const getSizeOfWidget = () => {
    return widgetSizes(values)[widgetType](targetGroup.group.type[0].value);
  };

  const computePositionForNewWidget = (id) => {
    const widget = {
      i: id,
      x: 0,
      y: 0,
      ...getSizeOfWidget(),
    };

    if (!targetGroupLayout?.value?.length) return widget;

    return {
      ...widget,
      y: 0,
      x: 0,
    };
  };

  const reCalculateLayoutGroup = (newWidgetId) => {
    const newWidget = computePositionForNewWidget(newWidgetId);

    if (!targetGroupLayout?.value?.length) {
      return [newWidget];
    } else {
      return [...targetGroupLayout.value, newWidget];
    }
  };

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  return (
    <>
      <CommonModal
        loading={widgetPropsLoading || dashboardLoading || isAddingWidget}
        modalOpen={props.isOpen}
        title={msg.addWidgetModal.addWidget}
        handleClose={reject}
        buttons={
          <>
            <Button color="inherit" onClick={reject}>
              {msg.addWidgetModal.buttonCancel}
            </Button>
            <Button data-test-add-widget color="primary" disabled={isAddingWidget} onClick={formik.handleSubmit}>
              {isAddingWidget || isUpdatingLayout ? <CircularProgress size={23} /> : msg.addWidgetModal.buttonAdd}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          {fields
            .filter((item) => groupNames[widgetType].find((groupName) => groupName === item.groupName) && !item.hidden)
            .map((field) => {
              if (widgetType === WIDGETS_ENUM.TITLE) {
                if (values.settingsLinkType) {
                  if (values.settingsLinkType === 'url') {
                    if (field.key !== 'settingsUuid') {
                      return (
                        <FormField
                          key={field.key}
                          values={values}
                          field={field}
                          handleInputChange={handleInputChange}
                        />
                      );
                    }
                  } else {
                    if (field.key !== 'settingsUrl') {
                      return (
                        <FormField
                          key={field.key}
                          values={values}
                          field={field}
                          handleInputChange={handleInputChange}
                        />
                      );
                    }
                  }
                } else {
                  return (
                    <FormField key={field.key} values={values} field={field} handleInputChange={handleInputChange} />
                  );
                }
              } else if (widgetType === WIDGETS_ENUM.DATABOX) {
                let fieldLocal = field;
                if (!isMinimap() && field.key === 'settingsSize') {
                  fieldLocal = {
                    ...field,
                    valueSet: {
                      ...field.valueSet,
                      list: field?.valueSet?.list.filter((item) => item.key !== 'tiny'),
                    },
                  };
                }
                return (
                  <FormField
                    key={fieldLocal.key}
                    values={values}
                    field={fieldLocal}
                    handleInputChange={handleInputChange}
                  />
                );
              } else {
                return (
                  <FormField key={field.key} values={values} field={field} handleInputChange={handleInputChange} />
                );
              }
            })}

          <Grid item>
            <CustomInput
              name="description"
              label={msg.addWidgetModal.description}
              clearFieldIcon={true}
              value={description ?? ''}
              multiline={true}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(AddWidgetModal);
