import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useInterval } from 'usehooks-ts';
import CloudClockOutline from '../icons/cloudClockOutline';

const TimeCore = () => {
  const timeZone = useSelector((state) => state.settings.timezone);
  const serverTimeZone = timeZone?.getTimezone ?? 'UTC';
  const date = utcToZonedTime(new Date(), serverTimeZone);
  const [time, setTime] = useState('00:00');

  useInterval(() => {
    setTime(format(date, 'HH:mm'));
  }, 1000);

  return (
    <>
      <Paper
        elevation={6}
        sx={{
          borderRadius: 20,
          height: 40,
        }}
      >
        <Box
          title="Server time"
          sx={{
            fontWeight: 'bold',
            height: 40,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 20,
            gap: '8px',
            flexShrink: 0,
            padding: '0 12px',
          }}
        >
          <CloudClockOutline />
          {time} ({serverTimeZone})
        </Box>
      </Paper>
    </>
  );
};

export default TimeCore;
