import { useCallback } from 'react';

// TODO: #773
const useIsFieldRequired = (validationSchema: any) => {
  return useCallback(
    (fieldName: string) => {
      return !!validationSchema?.fields?.[fieldName]?.exclusiveTests?.required;
    },
    [validationSchema]
  );
};

export default useIsFieldRequired;
