import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, memo } from 'react';

export interface IListItem {
  name?: string;
  value?: string;
}

const ListItem: FC<IListItem> = ({ name, value }) => (
  <Grid item>
    <Typography variant="body1">
      {name}: {value || 'n/a'}
    </Typography>
  </Grid>
);

export default memo(ListItem);
